import { Tooltip } from 'primereact/tooltip';
import { useHistory } from 'react-router-dom';
import { LandingPageUrl } from '../../views/Landing';
import { useTaxonomyFilter } from '../../_services/taxonomy-filter-context';
import { getRecentTopicFilters, removeTopicFilter } from '../../_services/user-activity/topic-filter-history';
import { TopicFilter } from '../../_models/topic-filter';
import { useState } from 'react';

export function RecentTopicFilters() {

    const taxonomy = useTaxonomyFilter();
    const router = useHistory();
    const [recentTopics, setRecentTopics] = useState(getRecentTopicFilters());

    const setFilter = (filter: TopicFilter) => {
        taxonomy.setFilter({
            entity: filter.entity?.id || 'all',
            role: filter.role?.id || 'all',
            term: filter.term
        })
        router.push(LandingPageUrl)
    }

    return (
        <>
            <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
            {recentTopics.map((s, i) =>
                <div key={i} className="mb-3 d-flex justify-content-between align-items-center flex-wrap">
                    <button aria-label="Apply filters" onClick={() => setFilter(s)} className="btn btn-secondary text-primary btn-block rounded-0 text-left normalize">
                        <span className="float-left"><i className="mr-2 text-muted fal fa-search"></i>{s.term}</span>
                        <small className="float-right mt-1 text-gray">Searched {s.dateSearched.toLocaleDateString()}</small>
                        <div className="clearfix"></div>
                        <small>({s.entity?.name || 'All'} - {s.role?.name || 'All'})</small><br />
                    </button>
                    <div className="text-primary cursor-pointer" data-pr-tooltip="Remove from my activity"
                        onClick={() =>{
                            removeTopicFilter(i);
                            setRecentTopics(getRecentTopicFilters())
                        }}
                    >
                        <i className="fal fa-times"></i>
                    </div>
                </div>
            )}
        </>
    );
}