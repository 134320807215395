import { Tooltip } from 'primereact/tooltip';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchLevels } from '../../_models/search/search-levels';
import search from '../../_services/search';
import { getRecentSearches, removeSearch } from '../../_services/user-activity/search-history';

export function RecentSearches() {

    const [recentSearches, setRecentSearches] = useState(getRecentSearches());
    const router = useHistory();

    return (
        <>
            <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
            {recentSearches.map((s, i) =>
                <div key={i} className="mb-2 mr-2 btn-primary-lighten d-inline-flex justify-content-end align-items-center rounded-pill clearable">
                    <span className="action-search" onClick={() => search(s, SearchLevels.recommendedResources, router, [])}>
                        <i className="mr-1 text-muted fal fa-search"></i> {s}
                    </span>

                    <span className="action-clear"
                        onClick={() => {
                            removeSearch(i);
                            setRecentSearches(getRecentSearches());
                        }}
                        data-pr-tooltip="Remove from my activity">
                        <i className="fal fa-times"></i>
                    </span>
                </div>
            )}
        </>
    );
}