import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import search from '../../_services/search';
import SearchBar from '../SearchBar/SearchBar';
import { SearchLevel } from './SearchLevel';

export default function SearchPanelCondensed() {

    const [level, setLevel] = useState<string>('recommended');

    const router = useHistory();

    return (
        <>
            <div id="anchor-search"></div>
            <div className="bg-white p-4 shadow-sm rounded w-100 align-self-end">
                <div className="mb-4" >
                    <SearchBar search={(term) => search(term, level, router, [])} searchOnComplete={true} />
                </div>
                <div>
                    <SearchLevel level={level} setLevel={setLevel} />
                </div>
            </div>
        </>
    )
}
