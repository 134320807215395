export interface Subscription<T> {
    callback: (value: T) => void;
    unsubscribe: () => void;
}

export class Observable<T> {

    private observers: Subscription<T>[] = [];

    constructor(private value: T) { }

    private emit(): void {
        for (const observer of this.observers) {
            observer.callback(this.value);
        }
    }

    next(newValue: T): void {
        this.value = newValue;
        this.emit();
    }

    subscribe(callback: (value: T) => void): Subscription<T> {
        const subscription: Subscription<T> = {
            callback: callback,
            unsubscribe: () => {
                const index = this.observers.indexOf(subscription);
                if (index >= 0) {
                    this.observers.splice(index, 1);
                }
            }
        }

        this.observers.push(subscription);

        subscription.callback(this.value);

        return subscription;
    }

    current(): T {
        return this.value;
    }
}