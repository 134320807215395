import { api } from '../api/api';
import { TopicsResponse } from '../api/topics';
import { useApiResource } from '../use-api-resource';
import { ApiResource } from './api-resource';

const adminSuggestedTopics = new ApiResource<TopicsResponse>(() => api.get.getAdminTopics());

export function clearAdminSuggestedTopics() {
    return adminSuggestedTopics.clearCache();
}

export async function getAdminSuggestedTopics() {
    return await adminSuggestedTopics.get();
}

export function useAdminSuggestedTopics() {
    return useApiResource(getAdminSuggestedTopics);
}