import { WordPressResult } from '../../_models/search/wordpress-result';
import { htmlToText } from '../../_services/html-to-text';
import { SearchResult } from '../_Search/SearchResult';

export function Resource(props: {resource: WordPressResult}) {
    return <SearchResult result={{
        content: {
            html: props.resource.excerpt
        },
        title: {
            text: htmlToText(props.resource.title),
            html: props.resource.title
        },
        type: 'wordpress',
        url: {
            actual: props.resource.link,
            display: props.resource.link,
            title: props.resource.link
        },
        date: props.resource.modifiedGmt,
        relevance: 1
    }} />;
}