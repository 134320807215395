import { AdminEntity } from './../../../_models/admin/admin-entity';
import { ApiCall } from '@lcms/api-definition';
import endpoints from '../api-endpoint-definition.json';
import { AdminRole } from '../../../_models/admin/admin-role';

export type TaxonomyFilterResponse = { entities: AdminEntity[]; roles: AdminRole[] };

const definition = new ApiCall<TaxonomyFilterResponse>().get('resourceFilter', {
    route: endpoints.adminTaxonomy
})
export default definition;

