import React from 'react';
import { NodeData } from '../Table/TaxonomyTable';
import { EntityRoleEditForm } from './EntityRoleEditForm';
import { EntityEditForm } from './EntityEditForm';
import { Toast } from 'primereact/toast';

interface EditModalProps {
    toast: React.RefObject<Toast>;
    node: NodeData;
    onHide: () => void;
    onSave: () => void;
}

export function EditModal(props: EditModalProps) {
    if (props.node.type === 'entity') {
        return <EntityEditForm
            entity={props.node.entity}
            onHide={props.onHide}
            onSave={props.onSave}
            toast={props.toast}
        />
    } else {
        return <EntityRoleEditForm
            entity={props.node.entity}
            entityRole={props.node.relationship}
            role={props.node.role}
            onHide={props.onHide}
            onSave={props.onSave}
            toast={props.toast}
        />
    }

}



