import { Tooltip } from 'primereact/tooltip';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { RecentActivityPageUrl } from '../../views/RecentActivity';
import { TrendingSearchesPageUrl } from '../../views/TrendingSearches';
import { AutoCompleteEntry } from './AutoCompleteEntry';
import { AutoCompleteTerm } from './AutoCompleteTerm';

export interface AutoCompleteProps {
    onSelect: (term: string) => void;
    remove: (id: number) => void;
    show: boolean;
    filter?: string;
    terms: AutoCompleteTerm[];
}

export function AutoComplete(props: PropsWithChildren<AutoCompleteProps>) {
    const matches = props.terms.filter(term => !props.filter || term.term.toLowerCase().indexOf(props.filter.toLowerCase()) === 0);
    return (
        <div style={{ position: 'relative' }} className={!props.show || !matches.length ? 'd-none' : ''}>
            <div style={{ position: 'absolute', left: 0, right: 0, zIndex: 9999 }}>
                <div className="bg-white rounded p-3 shadow-sm" >
                    <div className="auto-complete-terms">
                        <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />

                        {matches.map(term => (
                            <AutoCompleteEntry
                                key={`${term.term}${term.isTrending ? '-trending' : ''}${term.canRemove ? '-history' : ''}`}
                                onSelect={props.onSelect} term={term} filter={props.filter}
                                remove={props.remove}
                            />
                        ))}
                    </div>
                    <div className="auto-complete-footer">
                        <div className="d-flex justify-content-between">
                            <Link to={TrendingSearchesPageUrl}>View Trending Searches</Link>
                            <Link to={RecentActivityPageUrl}>Manage Your Activity</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

