import React, { PropsWithChildren } from 'react';
import { LandingSubPageSidebar } from '../_components/Sidebar/LandingSubPageSidebar';
import { SubPageSidebarResponsive } from '../_components/Sidebar/SubPageSidebarResponsive';
import { Header } from '../_components/_RecentActivity/Header';
import { UserActivities } from '../_components/_RecentActivity/UserActivities';
import LandingLayout from '../_layouts/LandingLayout';
import { scrollToTop } from '../_services/scroll-to-top';
import { useOnComponentMount } from '../_services/use-on-component-mount';

export const RecentActivityPageUrl = '/recent-activity';

export default function RecentActivityPage(props: PropsWithChildren<any>) {

    useOnComponentMount(() => {
        scrollToTop();
    })

    return (
        <LandingLayout>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-3 sb-main">
                    <LandingSubPageSidebar />
                </div>
                <SubPageSidebarResponsive pageTitle="Your Activity" />
                <div className="col-sm-12 col-md-9 topics-main">
                    <Header />
                    <UserActivities />
                </div>
            </div>
        </LandingLayout>
    );
}

