import LCMSLogo from '../../_components/FooterLogo';
// reactstrap components
import {
    Container,
    Row,
    Col
} from 'reactstrap';



/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


export function Footer() {
    // const userContext = useAuthenticatedUser();

    // if (!userContext.user) {
    //     return null;
    // }
    return (
        <>
            <footer className="footer has-cards">
                <Container>
                    <hr />
                    <Row className=" align-items-center justify-content-md-between">
                        <Col md="6">
                            <div className=" copyright">
                                <span className="pr-1">&copy; {new Date().getFullYear()}{''}</span>
                                <a
                                    href="https://lcms.org"
                                    target="_blank" rel="noreferrer"
                                >
                                    The Lutheran Church&mdash;Missouri Synod.
                  </a> All Rights Reserved.
                </div>
                        </Col>
                        <Col md="6">
                            <div className="float-right">
                            <LCMSLogo />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}