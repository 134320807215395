import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import endpoints from '../api-endpoint-definition.json';

export type TitleResponse = { title?: string }

const definition = new ApiCall<TitleResponse>().post('getPageTitle', {
    route: endpoints.adminTrendingResourcesTitle,
    params: {
        url: {
            type: ParameterTypes.String
        }
    }
})
export default definition;