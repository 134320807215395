import { Skeleton } from 'primereact/skeleton';
import { useTaxonomyFilter } from '../../../_services/taxonomy-filter-context';
import { RoleSelect } from './RoleSelect';
import { EntitySelect } from './EntitySelect';

export function SuggestedTopicsFilterControls() {

    const taxonomyFilter = useTaxonomyFilter();
    const schema = taxonomyFilter.filterSchema;

    if (schema.loading) {
        return (
            <TaxonomyFilterControlsSkeleton />
        );
    }

    const entities = schema.entities;
    const roles = taxonomyFilter.filter.entity ? schema.roles(taxonomyFilter.filter.entity) : [];

    const names = taxonomyFilter.getFilterNames();

    return (
        <>
            <div className="sb-title pt-2">
                <i className='fal fa-file-search'></i> I'm looking for resources for:
            </div>
            <div className="px-2">
                <div className="radio-orange">
                    {entities.map((e) =>
                        <div key={e.value}>
                            <EntitySelect {...e} />
                        </div>
                    )}
                </div>
            </div>


            {roles?.length && names.entity !== 'View All' ? 
            (roles.length > 1 ? (
                <div>
                    <hr />
                    <div className="role-btn-group">
                        {roles.map(role => <RoleSelect key={role.value} {...role} />)}
                    </div>
                </div>
            ) : null) :
                <>
                    <hr />
                    <div className="sb-note-view-all">
                        <h4 className="mb-0">View All</h4>
                        <small>Showing all top-level topics</small>
                    </div>
                </>
            }
        </>
    );
}
function TaxonomyFilterControlsSkeleton() {
    return (
        <>
            <div className="row mb-2">
                <div className="col">
                    <Skeleton shape="rectangle" height="3em" />

                </div>
                <div className="col">
                    <Skeleton shape="rectangle" height="3em" />
                </div>

            </div>
            <div className="row">
                <div className="col">
                    <Skeleton shape="rectangle" height="3em" />
                </div>
                <div className="col">
                    <Skeleton shape="rectangle" height="3em" />
                </div>
                <div className="col">
                    <Skeleton shape="rectangle" height="3em" />
                </div>
            </div>
        </>
    );
}
