import { Toast } from 'primereact/toast';
import React, { useCallback } from 'react';
import { api } from '../../_services/api/api';

export function ClearWordPressCacheButton(props: { toast: React.RefObject<Toast> }) {
    const clear = useCallback(() => {
        api.post.clearWordpressCache({})
        .then(result => {
            if (result?.success) {
                props.toast.current?.show({
                    content: 'Cache has been updated',
                    severity: 'success'
                })
            } else {
                props.toast.current?.show({
                    content: 'Unable to update cache',
                    severity: 'error'
                })
            }
        })
    }, [props.toast])

    return (
        <button type="button" className="btn btn-outline-warning mb-2"
            onClick={clear}
        >
            <i className="far fa-cloud-download mr-2"></i>
            Update Data from Wordpress
            </button>
    )
}