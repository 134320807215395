import { Api } from '@lcms/api-definition'
import getFeaturedTopics from './featured-topics';
import getResourceTypes from './resource-types';
import getTopicFilter from './topic-filter';
import getTrendingSearchTerms from './trending-search-terms';
import getTrendingResources from './trending-resources';
import contributeResource from './contribute-resource';
import requestResource from './request-resource';
import search from './search';
import getTopicResources from './topic-resources';
import getTopics from './topics';
import trackResource from './track-resource';
import adminTrendingResources from './admin/trending-resources';
import adminTrendingSearchTerms from './admin/trending-search-terms';
import adminTrendingTopicFilters from './admin/trending-topic-filters';
import setTrendingResourceVisibility from './admin/trending-resource-visibility';
import setTrendingSearchTermVisibility from './admin/trending-search-term-visibility';
import setTrendingTopicFilterVisibility from './admin/trending-topic-filter-visibility';
import getWordpressTopics from './admin/wordpress-topics';
import getAdminSuggestedTopics from './admin/suggested-topics';
import getRecentResources from './recent-resources';
import saveTopic from './admin/save-topic';
import restoreSuggestedTopic from './admin/restore-suggested-resource';
import deleteSuggestedTopic from './admin/delete-suggested-resource';
import blockTrendingResource from './admin/block-trending-resource';
import blockTrendingSearchTerm from './admin/block-trending-search-term';
import blockTrendingTopicFilter from './admin/block-trending-topic-filter';
import setTrendingResourceSticky from './admin/trending-resource-sticky';
import setTrendingSearchTermSticky from './admin/trending-search-term-sticky';
import setTrendingTopicFilterSticky from './admin/trending-topic-filter-sticky';
import getAdminTaxonomy from './admin/taxonomy-filters';
import setEntityVisibility from './admin/entity-visibility';
import setRoleVisibility from './admin/role-visibility';
import saveEntity from './admin/save-entity';
import saveEntityRole from './admin/save-entity-role';
import moveTopics from './admin/move-topics';
import addTrendingResource from './admin/add-trending-resource';
import addTrendingSearchTerm from './admin/add-trending-search-term';
import getPageTitle from './admin/get-page-title';
import clearWordpressCache from './admin/clear-wordpress-cache';

export const ApiKey = 'LCMS-MDFL'

async function get(url: string) {
    const response = await fetch(url, {
        headers: {
            'X-api-key': ApiKey
        }
    }).then(response => {
        if (response.headers.get('x-unauthorized') === 'true') {
            window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname + window.location.search);
        }
        return response;
    });
    return await response.json();
}

async function post(url: string, data: any) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-api-key': ApiKey
        },
        body: JSON.stringify(data)
    }).then(response => {
        if (response.headers.get('x-unauthorized') === 'true') {
            window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname + window.location.search);;
        }
        return response;
    });
    return await response.json();
}

export const api = new Api(get, post)
    .use(getFeaturedTopics)
    .use(getResourceTypes)
    .use(getTopicFilter)
    .use(getTrendingSearchTerms)
    .use(getTrendingResources)
    .use(contributeResource)
    .use(requestResource)
    .use(getTopicResources)
    .use(getTopics)
    .use(getRecentResources)
    .use(search)
    .use(trackResource)
    .use(adminTrendingResources)
    .use(adminTrendingSearchTerms)
    .use(adminTrendingTopicFilters)
    .use(setTrendingResourceVisibility)
    .use(setTrendingSearchTermVisibility)
    .use(setTrendingTopicFilterVisibility)
    .use(getWordpressTopics)
    .use(saveTopic)
    .use(getAdminSuggestedTopics)
    .use(restoreSuggestedTopic)
    .use(deleteSuggestedTopic)
    .use(blockTrendingResource)
    .use(blockTrendingSearchTerm)
    .use(blockTrendingTopicFilter)
    .use(setTrendingResourceSticky)
    .use(setTrendingSearchTermSticky)
    .use(setTrendingTopicFilterSticky)
    .use(getAdminTaxonomy)
    .use(setEntityVisibility)
    .use(setRoleVisibility)
    .use(saveEntity)
    .use(saveEntityRole)
    .use(moveTopics)
    .use(addTrendingResource)
    .use(addTrendingSearchTerm)
    .use(getPageTitle)
    .use(clearWordpressCache)
;