import { AboutPageUrlParams } from './../../views/About';
import { useParams } from 'react-router-dom';

export function getActiveIndex(tab?: string) {
    let activeIndex = 0;
    switch (tab?.toLowerCase()) {
        case 'how-to-use':
            activeIndex = 1;
            break;

    }

    return activeIndex;
}

export function useActiveIndex() {
    const { tab } = useParams<AboutPageUrlParams>();
    return getActiveIndex(tab);
}