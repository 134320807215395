import React, { useCallback, useRef, useState } from 'react';
import { SuggestedResource, SuggestedResourceFormModel } from '../../../../_models/taxonomy/suggested-resource';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { FormGroup, FormGroupModel } from '../../../__form/FormGroup';
import { defaultDropdownValueOf, defaultFormValueOf } from '../../../__form/default-form-value';
import { DropdownFormGroup, DropdownFormGroupModel, noMap } from '../../../__form/DropdownFormGroup';
import { clearFeaturedTopics, getFeaturedTopics } from '../../../../_services/api-resources/featured-topics';
import { getWordpressTopics } from '../../../../_services/api-resources/wordpress-topics';
import { getAdminSuggestedTopics, clearAdminSuggestedTopics } from '../../../../_services/api-resources/suggested-topics';
import { Taxonomy } from './Taxonomy';
import { api } from '../../../../_services/api/api';
import { CancelButton } from '../../CancelButton';
import { HideTopicButton } from './HideTopicButton';
import { clearAdminTaxonomy } from '../../../../_services/api-resources/admin-taxonomy';
import { Tooltip } from 'primereact/tooltip';

function mapTopic(topic: SuggestedResource, excludeId?: string, prefix = ''): { label: string; value: string; subLabel: string; }[] {
    if (excludeId && topic.id === excludeId) return [];
    return [
        {
            label: prefix + topic.title,
            value: topic.id,
            subLabel: !topic.parent ? `(${topic.entity?.name || 'All'} / ${topic.role?.name || 'All'})` : `(${topic.parent.title})`
        },
        ...topic.children.map(t => mapTopic(t, excludeId, '- ' + prefix)).reduce((c, n) => c.concat(n), [])
    ]
}

function mapFeaturedTopic(topic: string) {
    return {
        label: topic,
        value: topic
    }
}

interface EditModalProps {
    resource: SuggestedResourceFormModel;
    onHide: () => void;
    onSave: () => void;
    toast: React.RefObject<Toast>;
}
export function EditModal(props: EditModalProps) {
    const [title, setTitle] = useState<FormGroupModel>(defaultFormValueOf(props.resource.title || ''));
    const [description, setDescription] = useState<FormGroupModel>(defaultFormValueOf(props.resource.description || ''));
    const [link, setLink] = useState<FormGroupModel>(defaultFormValueOf(props.resource.link || ''));
    const [keyWords, setKeyWords] = useState<FormGroupModel>(defaultFormValueOf(props.resource.keywords || ''));
    const [featured, setFeatured] = useState<DropdownFormGroupModel>(defaultDropdownValueOf(props.resource.featured || ''));
    const [entity, setEntity] = useState<DropdownFormGroupModel>(defaultDropdownValueOf(props.resource.entity?.id || ''));
    const [role, setRole] = useState<DropdownFormGroupModel>(defaultDropdownValueOf(props.resource.role?.id || ''));
    const [taxonomyId, setTaxonomyId] = useState<DropdownFormGroupModel>(defaultDropdownValueOf(props.resource.wordpressTopicId || ''));
    const [parent, setParent] = useState<DropdownFormGroupModel>(defaultDropdownValueOf(props.resource.parent?.id || ''));
    const [internalId, setInternalId] = useState<FormGroupModel>(defaultFormValueOf(props.resource.internalIdentifier || ''));

    const [isSubmitting, setIsSubmitting] = useState(false);

    const loadParentDropdown = useCallback(() => {
        return getAdminSuggestedTopics()
            .then(results => results?.map(t => mapTopic(t, props.resource.id)).reduce((c, n) => c.concat(n), []) || []
            );
    }, [props.resource.id]);

    const toast = useRef<Toast>(null)

    const formIsValid = title.isValid &&
        description.isValid &&
        link.isValid &&
        keyWords.isValid &&
        featured.isValid &&
        taxonomyId.isValid &&
        internalId.isValid &&
        (!parent.selected?.value ||
            parent.isValid ||
            !props.resource.canChangeParent
        ) &&
        (parent.selected?.value || (
            entity.isValid &&
            role.isValid
        ));

    const formIsDirty = title.isDirty ||
        description.isDirty ||
        link.isDirty ||
        keyWords.isDirty ||
        featured.isDirty ||
        taxonomyId.isDirty ||
        parent.isDirty ||
        entity.isDirty ||
        role.isDirty ||
        internalId.isDirty
        ;

    const save = (silent: boolean) => async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        if (formIsValid) {
            setIsSubmitting(true);
            return await api.post.saveSuggestedTopic({
                description: description.value || undefined,
                entityId: entity.selected?.value || 'all',
                roleId: role.selected?.value || 'all',
                title: title.value,
                featured: featured.selected?.value || undefined,
                id: props.resource.id || undefined,
                keywords: keyWords.value || undefined,
                link: link.value || undefined,
                parentId: parent.selected?.value || undefined,
                wordpressId: taxonomyId.selected?.value || undefined,
                internalIdentifier: internalId.value || undefined
            }).then(result => {
                if (result?.success) {
                    clearAdminSuggestedTopics();
                    clearFeaturedTopics();
                    clearAdminTaxonomy();
                    if (!silent) {
                        props.onSave();
                    }
                } else {
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Unable to save topic',
                    })
                }
                setIsSubmitting(false);
                return result?.success || false;

            });
        } else {
            return false;
        }
    };

    const onSubmit = save(false);
    const headerIconClass = 'h2 mb-0 mr-2 px-1 ' + (props.resource.id ? 'fal fa-file-edit' : 'fal fa-file-plus');

    return (<>
        <Dialog onHide={props.onHide} visible blockScroll={true} closable={false} draggable={false}
            header={<>
                <div className="d-flex justify-content-between align-items-center">
                    <i className={headerIconClass}></i>
                    <h5 className="text-uppercase m-0">
                        {props.resource.id
                            ? <><b>Edit</b> {props.resource.title}</>
                            : props.resource.parent
                                ? <><b>Add</b> Suggested Topic </>
                                : <><b>Add</b> Root Topic</>
                        }
                        {formIsDirty ? <span data-pr-tooltip='Unsaved'> *</span> : null}
                    </h5>
                </div>
            </>}
            style={{ width: '80vh' }}
            footer={<div className="d-flex justify-content-between text-uppercase align-items-end">
                <div>
                    <div>
                        <button aria-label="Contribute Content" className="btn btn-primary"
                            onClick={() => onSubmit()}
                            disabled={!formIsValid || isSubmitting}>
                            {isSubmitting ? <i className="fal fa-spinner-third fa-spin"></i> : 'Save Topic'}
                        </button>
                    </div>
                </div>
                <div>
                    <HideTopicButton
                        hasChanges={formIsDirty}
                        onSave={props.onSave}
                        resource={props.resource}
                        toast={props.toast}
                        disabled={formIsDirty && !formIsValid}
                        saveChanges={save(true)}
                    />
                </div>
                <div>
                    <CancelButton onAccept={props.onHide} autoAccept={!formIsDirty} />
                </div>
            </div>}
        >
            <form onSubmit={onSubmit} className="modal-form">
                {props.resource.canChangeParent ?
                    <DropdownFormGroup
                        load={loadParentDropdown}
                        map={noMap}
                        name='parent'
                        value={parent.selected} onChange={setParent}
                        label='PARENT TOPIC'
                        filter showClear
                        className="w-100"
                        valueTemplate={(option: any, props: any) => {
                            if (option) {
                                return (
                                    <div>
                                        <div>{option.label}</div>
                                        <div><small>{option.subLabel}</small></div>
                                    </div>
                                );
                            }

                            return (
                                <span>
                                    {props.placeholder}
                                </span>
                            );
                        }}
                        itemTemplate={(option: any) => {
                            return (
                                <div>
                                    <div>{option.label}</div>
                                    <div><small>{option.subLabel}</small></div>
                                </div>
                            );
                        }} /> : null}


                <FormGroup name='title'
                    value={title.value} onChange={setTitle}
                    label='TITLE' fieldName='Topic title'
                    required maxLength={75}
                    showCharacterCount  />
                <FormGroup name='description'
                    value={description.value} onChange={setDescription}
                    label='DESCRIPTION' fieldName='Topic Description'
                    maxLength={150} showCharacterCount />
                    
                <FormGroup name='id'
                    value={internalId.value} onChange={setInternalId}
                    label='NODE ID' fieldName='Node Id' required
                    maxLength={50} />
                <DropdownFormGroup
                    load={getWordpressTopics}
                    map={noMap}
                    name='taxonomy-id'
                    value={taxonomyId.selected} onChange={setTaxonomyId}
                    label='WORDPRESS TOPIC'
                    required filter
                    className="w-100" />

                {!parent.selected?.value ?
                    <Taxonomy
                        entity={entity}
                        onEntityChange={setEntity}
                        role={role}
                        onRoleChange={setRole} /> : null}

                <FormGroup name='link'
                    value={link.value} onChange={setLink}
                    label='SINGLE LINK' fieldName='Link'
                    maxLength={255} showCharacterCount />

                <FormGroup name='key-words'
                    value={keyWords.value} onChange={setKeyWords}
                    label='GOOGLE SEARCH KEY WORDS' fieldName='Key words'
                    subLabel='These key words will be used to search across partner resources'
                    maxLength={255} showCharacterCount />

                <DropdownFormGroup
                    load={getFeaturedTopics}
                    map={mapFeaturedTopic}
                    name='featured'
                    value={featured.selected} onChange={setFeatured}
                    label='PROMOTE AS'
                    editable maxLength={100}
                    className="w-100" />

            </form>

        </Dialog>
    </>);
}
