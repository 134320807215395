import { useModal } from '../../_services/use-modal';
import { HowIsMyDataUsedDialog } from '../HowIsMyDataUsedDialog/HowIsMyDataUsedDialog';

export function Header() {
    const { visible: modalIsVisible, showModal, hideModal } = useModal();

    return (
        <>
            <div id="anchor-top"></div>
            <div className="bg-white shadow-sm p-2 border-left border-info mb-3">
                <div className="d-flex justify-content-start align-items-center">
                    <div>
                        <h6 className="text-dark medium mb-0">Using your data to build a better experience...</h6>
                        <p className="mb-0">
                            <button aria-label="Open modal to learn how your activity is used" className="btn btn-link normalize p-0 m-0"
                                type="button" onClick={showModal}>Learn how your activity is used, accessed, and stored</button>
                        </p>
                    </div>

                </div>

                {modalIsVisible ? <HowIsMyDataUsedDialog onHide={hideModal} /> : null}

            </div>
        </>
    );
}