import React, { useState } from 'react';
import { Toast } from 'primereact/toast';
import { api } from '../../../../_services/api/api';
import { confirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog';
import { confirmPopup } from 'primereact/confirmpopup';
import { DialogProps } from 'primereact/dialog';
import { clearAdminSuggestedTopics } from '../../../../_services/api-resources/suggested-topics';
import { clearFeaturedTopics } from '../../../../_services/api-resources/featured-topics';
import { SuggestedResourceFormModel } from '../../../../_models/taxonomy/suggested-resource';

interface HideTopicButtonProps {
    resource: SuggestedResourceFormModel;
    onSave: () => void;
    toast: React.RefObject<Toast>;
    hasChanges: boolean;
    disabled?: boolean;
    saveChanges: () => Promise<boolean>;
}

export function HideTopicButton(props: HideTopicButtonProps) {
    const [isDeleting, setIsDeleting] = useState(false);

    const hide = () => confirmDialog({
        message: <dl>
            <dt className="mt-3">Are you sure you want to hide this topic?</dt>
        </dl>,
        icon: 'fal fa-question-circle text-primary',
        header: 'Hide Topic',
        accept: () => {
            setIsDeleting(true);
            api.post.deleteSuggestedTopic({
                id: props.resource.id || ''
            }).then(result => {
                if (result?.success) {
                    clearAdminSuggestedTopics();
                    clearFeaturedTopics();
                    props.onSave();
                } else {
                    props.toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Unable to delete topic',
                    })
                }
                setIsDeleting(false);
            })
        },
        blockScroll: true,
        draggable: false
    } as ConfirmDialogProps & DialogProps);

    const save = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'If you proceed your changes will be saved.  Proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                if (await props.saveChanges()) {
                    await hide();
                }
            }
        });
    }

    const onHideClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.hasChanges) {
            save(event);
        } else {
            hide();
        }
    };

    if (props.resource.activeChildCount > 0 || !props.resource.id) {
        return null;
    }

    return <button type="button" className="btn btn-outline-white text-danger"
        onClick={onHideClick} disabled={isDeleting}
    >
        {isDeleting ?
            <i className="fad fa-spin fa-spinner"></i>
            : <><i className="fas fa-eye-slash mr-2"></i> Hide Topic</>}

    </button>
}
