import React, { ComponentType } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { UserWithRoles } from '@lcms/identity-client';
import { User } from '../../_models/user';
import { useAuthenticatedUser } from '../user-context';

export interface GuardedRouteProps extends RouteProps {
    component?: ComponentType;
    authorized: (user: User, roles: UserWithRoles) => boolean;
}

export default function GuardedRoute(props: GuardedRouteProps) {

    const { user } = useAuthenticatedUser();
    const { component: Component, authorized, children, ...rest } = props;
    const location = useLocation();

    function render(childProps: any): React.ReactNode {
        return user && authorized(user, new UserWithRoles(user))
            ? (Component ? <Component {...childProps} /> : children)
            : <Redirect to={{ pathname: '/login', search: '?redirect=' + encodeURIComponent(location.pathname) }} />;
    }

    return <Route {...rest} render={render} />
};
