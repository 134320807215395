import { useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { SuggestedTopicsTable } from './Table/SuggestedTopicsTable';
import { useSuggestedTopics } from './use-suggested-topics';
import { Dictionary } from '@lcms/helpers';

export function SuggestedTopics(props: { toast: React.RefObject<Toast> }) {
    const topicRequest = useSuggestedTopics();
    const [expandedKeys, setExpandedKeys] = useState<Dictionary<boolean>>({});

    if (topicRequest.state === 'error') {
        return <Error />
    }

    if (topicRequest.state !== 'done') {
        return <Loading />
    }

    return <SuggestedTopicsTable 
        toast={props.toast} 
        topics={topicRequest.topics} 
        reload={topicRequest.reload} 
        expandedKeys={expandedKeys}
        onExpandToggle={setExpandedKeys}
    />
}


function Loading() {
    return (
        <Skeleton width='100%' height='20em' />
    )
}

function Error() {
    return <span className="text-danger">ERROR</span>
}
