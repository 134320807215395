import { useState } from 'react';
import { Cookie } from '../_services/cookies';
import { useOnComponentMount } from '../_services/use-on-component-mount';

const SiteNoticeCookie = new Cookie<boolean>('show-notice', 30)

export function SiteNotice() {

    const [hidden, setHidden] = useState(true);

    useOnComponentMount(() => {
        const lastNotice = SiteNoticeCookie.get();
        if (lastNotice === null) {
            setHidden(false);
        } else {
            setHidden(lastNotice);
        }
    })

    const onHide = () => {
        setHidden(true);
        SiteNoticeCookie.set(true);
    }

    const className = 'site-notice-wrapper p-3' + (hidden ? ' d-none' : '');
    const secondClass = 'push-page-element' + (hidden ? ' d-none' : '');
    return (
        <>
            <div className={className}>
                <div className="d-flex justify-content-between">
                    <div className="site-notice">
                        We're building this resource together as a Synod.
                        It is intentionally incomplete so we can build it based on feedback
                        and contributions from the whole church. Come back often. Offer
                        feedback and resources. This site will get smarter every time it's used.
                    </div>
                    <div>
                        <button type="button" aria-label="Close Dialog" className="btn btn-sm btn-link text-light close-site-notice"
                            onClick={onHide}
                        ><i className="fal fa-times"></i>
                        </button>
                    </div>
                </div>

            </div>
            <div className={secondClass}></div>
        </>
    );
}