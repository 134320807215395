import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { useTaxonomyFilter } from '../../../_services/taxonomy-filter-context';
import { Breadcrumbs } from './Breadcrumbs';
import { EmptyResultSet } from '../../EmptyResultSet/EmptyResultSet';
import { SuggestedTopic } from './SuggestedTopic';

export function SuggestedTopics() {
    const { suggested, filter, getFilterNames, setFilter } = useTaxonomyFilter();

    if (suggested.loading) {
        return (
            <div className="container">
                <div className="bg-purple p-3 text-white w-50 rounded shadow-sm">
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="pl-2">Loading...</span>
                </div>                
                {[0, 1, 2, 3, 4, 5].map(r =>
                    <div className="mb-3" key={r}>
                        <Skeleton height={'8rem'}></Skeleton>
                    </div>
                )}
            </div>

        );
    }


    if (!suggested.resources && (filter.entity && filter.role)) {
        return (
            <div>
                An error occured loading the resources
            </div>
        );
    }

    const viewSubset = suggested.subResource?.length;
    const toDisplay = viewSubset ? suggested.subResource : suggested.resources;
    if (!toDisplay?.length) {
        return (
            <EmptyResultSet />
        )
    }

    return (
        <>
            {viewSubset ? <Breadcrumbs
                filter={filter}
                filterNames={getFilterNames()}
                visibleTopics={suggested.subResource}
                viewSubResources={suggested.viewSubResources}
                setFilter={setFilter}
            /> : null}
            {toDisplay?.map((taxonomy, index) => <SuggestedTopic key={index} {...taxonomy} />)}

        </>

    );
}
