import React, { useState } from 'react';
import { Header } from './Header';
import { TrendingResources } from './TrendingResources';

export function TrendingResourcesPanel() {
    const [filter, setFilter] = useState('');
    return (
        <>
            <Header onFilter={setFilter} />
            <TrendingResources filter={filter} />
        </>
    );
}
