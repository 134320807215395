import React, { useState } from 'react';
import { RecentSearches } from './RecentSearches';
import { TabView, TabPanel } from 'primereact/tabview';
import { RecentResources } from './RecentResources';
import { RecentTopicFilters } from './RecentTopicFilters';


export function UserActivities() {

    const [activeIndex, setActiveIndex] = useState<number>(0);

    return (
        <div className="bg-white rounded shadow-sm p-3">
            <h4 className="page-heading">
                <i className="fal fa-shapes px-2"></i> Your Activity
            </h4>
            <hr />
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Site Searches" headerClassName="text-uppercase">
                    <RecentSearches />
                </TabPanel>
                <TabPanel header="Topic Searches" headerClassName="text-uppercase">
                    <RecentTopicFilters />
                </TabPanel>
                <TabPanel header="Visited Resources" headerClassName="text-uppercase">
                    <RecentResources />
                </TabPanel>
            </TabView>
        </div>

    );
}
