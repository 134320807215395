import { api } from '../api/api';
import { TrendingSearchTermsResponse } from '../api/trending-search-terms';
import { useApiResource } from '../use-api-resource';
import { ApiResource } from './api-resource';

const trendingSearches = new ApiResource<TrendingSearchTermsResponse>(() => api.get.trendingSearchTerms());

export function clearTrendingSearches() {
    trendingSearches.clearCache();
}

export async function getTrendingSearches() {
    return await trendingSearches.get();
}

export function useTrendingSearches() {
    return useApiResource(getTrendingSearches);
}