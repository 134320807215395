import { PropsWithChildren } from 'react';
import { FeaturedTopic as FeaturedTopicModel } from '../../_models/featured-topic';
import { ToggleButton } from 'primereact/togglebutton';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { SuggestedTopicsPageUrl } from '../../views/Landing';

export interface FeaturedTopicProps {
    topic: FeaturedTopicModel;
    addTopic: (topic: string) => void;
    removeTopic: (topic: string) => void;
    hideOnMobile?: boolean;
}

export function FeaturedTopic(props: PropsWithChildren<FeaturedTopicProps>) {
    const location = useLocation();
    const router = useHistory();
    const baseClass = 'btn-sm rounded-pill tb-success ';
    const onChange = (selected: boolean) => {
        if (location.pathname.toLowerCase() === SuggestedTopicsPageUrl) {
            changeIt();
        } else {
            router.push(SuggestedTopicsPageUrl);
            changeIt();
        }

        function changeIt() {
            if (selected) {
                props.addTopic(props.topic.title)
            } else {
                props.removeTopic(props.topic.title)
            }
        }
    }

    return (
        <ToggleButton checked={props.topic.selected}
            onChange={(e) => onChange(e.value)}
            onLabel={props.topic.title}
            offLabel={props.topic.title}
            iconPos="right"
            className={baseClass + (props.hideOnMobile ? 'd-sp-none' : '') + (props.hideOnMobile && props.topic.selected ? 'd-flex' : ' ')}
            onIcon="fal fa-times" offIcon="fal fa-circle"
            tooltip={props.topic.selected ? 'remove filter' : 'apply filter'} />
    );
}
