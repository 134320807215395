import { Link, useLocation } from 'react-router-dom';
import { RecentActivityPageUrl } from '../../../views/RecentActivity';
import { useModal } from '../../../_services/use-modal';
import { HowIsMyDataUsedDialog } from '../../HowIsMyDataUsedDialog/HowIsMyDataUsedDialog';

export function UserActivitySection() {

    const location = useLocation();
    const { visible: modalIsVisible, showModal, hideModal } = useModal();


    const active = location.pathname.toLowerCase() === RecentActivityPageUrl;
    const baseClass = 'btn btn-sm btn-block rounded-0 sb-btn ';
    return (
        <div className="mb-5">
            <h3 className="sb-bold-title"><i className="fad fa-shapes mr-2"></i>Your Activity</h3>
            {active ?
                null
                :<>
                <div className="sb-alert d-flex justify-content-between align-items-center mb-3 mt--2">
                    <div>
                        <small className="text-dark">
                            Using your data to build a better experience...
                            <button aria-label="Open dialog to learn how your data is being used" className="mt-1 float-right btn btn-sm btn-warning-lighten rounded-pill normalize"
                                type="button" onClick={showModal}>
                                Learn how
                            </button>
                        </small>
                    </div>
                </div>
                {modalIsVisible ? <HowIsMyDataUsedDialog onHide={hideModal} /> : null}
                </>
            }
            <Link to={RecentActivityPageUrl} className={baseClass + (active ? 'btn-outline-warning current' : 'btn-secondary')}>
                View Your Recent Activity
            </Link>
        </div>
    );
}