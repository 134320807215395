import React from 'react';
import { Link } from 'react-router-dom';
import { SuggestedTopicsPageUrl } from '../../views/Landing';
import { SidebarResponsive, SidebarResponsiveProps } from './SidebarResponsive';

export function SubPageSidebarResponsive(props: SidebarResponsiveProps) {
    return <SidebarResponsive
        {...props}
        prependMenuItems={(<>
            <div className="mb-5">
                <h3 className="sb-bold-title"><i className="fad fa-inventory mr-2"></i> Suggested Topics</h3>
                <Link to={SuggestedTopicsPageUrl}>
                    <div className="btn btn-sm btn-block rounded-0 sb-btn btn-secondary cursor-pointer">Suggested Topics</div>
                </Link>
            </div>
            {props.prependMenuItems}
        </>)}
    />
}