import { Tooltip } from 'primereact/tooltip';
import { useState } from 'react';
import { api } from '../../_services/api/api';
import { removeResource, getRecentResources, saveResource } from '../../_services/user-activity/resource-history';

export function RecentResources() {

    const [recentResources, setRecentResources] = useState(getRecentResources());

    const onClick = async (title: string, link: string) => {
        saveResource(title, link);
        await api.post.trackResource({
            link,
            title
        });
    };
    return (
        <>
            <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
            {recentResources.map((s, i) =>
                <div key={s.link} className="mb-3 d-flex justify-content-between align-items-center flex-wrap">
                    <a className="btn btn-secondary text-dark btn-block rounded-0 text-left normalize"
                        href={s.link} target="_blank" rel="noreferrer"
                        onClick={() => onClick(s.title, s.link)}
                    >
                        <span className="float-left"><i className="text-muted far fa-external-link-alt mr-2"></i>{s.title}</span>
                        <small className="float-right mt-1 text-gray"> Last visited {s.dateVisited.toLocaleDateString()}</small>
                        <div className="clearfix"></div>
                        <small className="s-url ml-0">{s.link}</small>

                    </a>
                    <div className="text-center">
                        <a className="text-warning cursor-pointer mb-2" data-pr-tooltip="Report an issue"
                            href={`mailto:mdfl@lcms.org?body=You are reporting an issue with the following external resource ${s.link}.%0D%0A%0D%0APlease provide more detail below to allow us to contact the owner of the external resource and resolve the issue.%0D%0A----Example reasons... the resource link is broken, the resource appears compromised or suspicious.----&subject=MDFL Issue with External Resource`}
                        ><i className="fal fa-comment-exclamation fa-flip-horizontal"></i></a>
                        <div className="text-primary cursor-pointer  mb-2" data-pr-tooltip="Remove from my activity"
                            onClick={() => {
                                removeResource(i);
                                setRecentResources(getRecentResources())
                            }}
                        >
                            <i className="fal fa-times"></i>
                        </div>
                    </div>

                </div>
            )}
        </>
    );
}