import { Dictionary } from '@lcms/helpers';
import { Column } from 'primereact/column';
import TreeNode from 'primereact/components/treenode/TreeNode';
import { Toast } from 'primereact/toast';
import { TreeTable } from 'primereact/treetable';
import React, { useCallback, useEffect, useState } from 'react';
import { AdminEntity } from '../../../../_models/admin/admin-entity';
import { AdminEntityRole } from '../../../../_models/admin/admin-entity-role';
import { AdminRole } from '../../../../_models/admin/admin-role';
import { EditModal } from '../Edit/EditModal';
import { Controls } from './Controls/Controls';
import { Title } from './Title';

export interface TaxonomyTableTableProps {
    entities: AdminEntity[];
    roles: AdminRole[]
    reload: () => void;
    toast: React.RefObject<Toast>;
    expandedKeys: Dictionary<boolean>;
    onExpandToggle: (values: Dictionary<boolean>) => void;
}

export interface EntityNodeData {
    type: 'entity';
    entity: AdminEntity;
}

export interface RoleNodeData {
    type: 'role';
    entity: AdminEntity;
    role: AdminRole;
    relationship: AdminEntityRole;
}

export type NodeData = (EntityNodeData | RoleNodeData) & {
    effectivelyActive: boolean;
    id: string;
    active: boolean;
    name: string;
    default: boolean;
    isRoot: boolean;
}

function mapToTreeNode(entity: AdminEntity, roles: AdminRole[]): TreeNode {
    return {
        key: entity.id,
        data: {
            id: entity.id,
            active: entity.isActive,
            default: entity.isDefault,
            isRoot: true,
            name: entity.name,
            type: 'entity',
            entity: entity,
            effectivelyActive: entity.isActive
        } as EntityNodeData,
        children: entity.roles.map(r => {
            const role = roles.find(role => r.role.id === role.id)
            return {
                key: r.id,
                data: {
                    active: r.isActive,
                    default: r.isDefault,
                    id: r.id,
                    isRoot: false,
                    name: r.role.name,
                    role: role,
                    type: 'role',
                    relationship: r,
                    entity: entity,
                    effectivelyActive: entity.isActive && r.isActive && r.role.isActive
                } as RoleNodeData,
                children: []
            }
        })
    }
}

export function TaxonomyTable(props: TaxonomyTableTableProps) {

    const [data, setData] = useState<TreeNode[]>([]);
    const [filter, setFilter] = useState<string | null>(null);
    const [nodeToEdit, setNodeToEdit] = useState<NodeData | null>(null);

    const { entities, roles, onExpandToggle } = props;
    useEffect(() => {
        setData(entities.map(e => mapToTreeNode(e, roles)));
        const expanded: Dictionary<boolean> = {};
        entities.map(e => {
            expanded[e.id] = true;
            return null;
        })
        onExpandToggle(expanded)
    }, [entities, roles, onExpandToggle]);

    const openModal = useCallback((node: NodeData) => {
        setNodeToEdit(node);
    }, []);

    const rowClassName = (row: TreeNode) => {
        return {
            'top-row': (row.data.isRoot),
            'row-deleted': !row.data.effectivelyActive,
            'leaf': !row.children.length,
            'leaf-with-subs': row.children.length && (!row.data.isRoot)
        };
    };

    return (
        <div className="respond">
            <TreeTable value={data} globalFilter={filter}
                expandedKeys={props.expandedKeys}
                onToggle={e => { props.onExpandToggle(e.value) }}
                sortField='title'
                paginator rows={10}
                rowClassName={rowClassName}
                header={<div className="d-flex justify-content-between align-items-end">
                    <div>
                        <button type="button" className="btn btn-sm btn-warning"
                            onClick={() => { setNodeToEdit({
                                type: 'entity',
                                active: true,
                                default: false,
                                effectivelyActive: true,
                                entity: {
                                    id: '',
                                    isActive: true,
                                    isDefault: false,
                                    name: '',
                                    roles: []
                                },
                                id: '',
                                isRoot: true,
                                name: '',
                            })}}
                        ><i className="fas fa-plus mr-2"></i>Add Entity</button>
                    </div>
                    <div className="input-group w-50">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fad fa-search"></i>
                            </span>
                        </div>
                        <input type="search" className="form-control" onChange={(e) => setFilter(e.target.value)} placeholder="Search" />
                    </div>
                </div>}>
                <Column body={Controls(openModal, props.reload)} style={{ textAlign: 'center', width: '8em' }} headerClassName="sm-invisible" bodyClassName="sm-invisible d-flex justify-content-start action-div" expander />
                <Column body={Title(openModal, props.reload)} header="Entity / Role" field="title" sortable expander className="row-title"></Column>
            </TreeTable>
            {nodeToEdit ? <EditModal onHide={() => setNodeToEdit(null)} 
                node={nodeToEdit}
                onSave={() => {
                    props.toast.current?.show({
                        severity: 'success',
                        detail: 'Saved!'
                    });
                    props.reload();
                }}
                toast={props.toast}
            /> : null}
        </div>
    );
}
