import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { AdminTrendingResource } from '../../../_models/admin/admin-trending-resource';
import { SortType } from '../../../_models/admin/sort-type';
import endpoints from '../api-endpoint-definition.json';

export type TrendingResourcesResponse = AdminTrendingResource[]

const definition = new ApiCall<TrendingResourcesResponse>().post('trendingResourcesAdmin', { 
    route: endpoints.adminTrendingResources,
    params: {
        sort: {
            type: '' as SortType
        },
        filter: {
            type: ParameterTypes.String,
            optional: true
        },
        active: {
            type: ParameterTypes.Boolean,
            optional: true
        }
    }
})
export default definition;