import { useTaxonomyFilter } from '../../_services/taxonomy-filter-context';
import { Tooltip } from 'primereact/tooltip';

export function TopicTitles(props: { show: boolean; onHide: () => void; }) {
    const { suggested } = useTaxonomyFilter();

    if (!props.show) {
        return null;
    }

    const toDisplay = suggested.subResource || suggested.resources;
    return (
        <>
            {toDisplay?.length ? (
                <div className="d-toc-none">
                    <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                    <div className="sticky-top">
                        <div className="px-2 toc">
                            <dl>
                                <dt className="d-flex justify-content-between align-items-top border-bottom mb-2">
                                    <span className="toc-title">Available Topics</span>
                                    <button type="button" className="mt--1 btn btn-sm btn-block btn-icon-only rounded-circle" data-pr-tooltip="Hide Top Level Topics" data-pr-position="left"
                                        onClick={props.onHide}
                                    ><i className="far fa-times toc-icon"></i></button>
                                </dt>
                                {toDisplay.map((r, i) =>
                                    <dd key={i} className="cursor-pointer" onClick={() => {
                                        document.getElementById(r.id)?.scrollIntoView();
                                        return false;
                                    }}>{r.title}</dd>
                                )}
                            </dl>
                        </div>
                    </div>
                </div>) : null}
        </>
    );
}
