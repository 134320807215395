import { Role, User } from '../../../_models/user';

export const adminRole = {
    code: 'admin',
    description: 'User with administrative access',
    name: 'Administrator'
};

export function isAdmin(user: User | undefined, roles: { hasRole: (role: Role) => boolean }): boolean {
    return !!user && roles.hasRole(adminRole);
}