import { compare } from '@lcms/helpers';
import endpoints from '../api-endpoint-definition.json';
import { SuggestedResource } from '../../../_models/taxonomy/suggested-resource';
import { ApiCall } from '@lcms/api-definition';

export type AdminTopicsResponse = SuggestedResource[] | null;

function addChildren(resource: SuggestedResource) {
    resource.children?.sort(compare(x => x.title));
    resource.children.map(child => {
        child.parent = resource;
        addChildren(child);
        return child;
    });
}

const definition = new ApiCall<AdminTopicsResponse>().get('getAdminTopics', {
    route: endpoints.adminSuggestedTopics,
    process: topics => {
        for (const topic of topics || []) {
            addChildren(topic);
        }
    }
})
export default definition;

