import { Tooltip } from 'primereact/tooltip';
import { EditButton } from './Controls/EditButton';
import { AddRoleButton } from './Controls/AddRoleButton';
import { RestoreButton } from './Controls/RestoreButton';
import { DeleteButton } from './Controls/DeleteButton';
import { NodeData } from './TaxonomyTable';

export function Title(showModal: (node: NodeData) => void, onUpdate: () => void) {
    return (props: { data: NodeData }) =>
        props.data.active ?
            <>
                <div className="sm-visible">
                    <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                    {props.data.type === 'entity' ? <>
                        {props.data.active ? <>
                            <EditButton node={props.data} showModal={showModal} />
                            <AddRoleButton node={props.data} showModal={showModal} />
                        </> : <>
                            <RestoreButton node={props.data} onRestore={onUpdate} />
                        </>}
                    </> : <>
                        {props.data.active ? <>
                            <EditButton node={props.data} showModal={showModal} />
                            <DeleteButton node={props.data} onDelete={onUpdate} />
                        </> : <>
                            <RestoreButton node={props.data} onRestore={onUpdate} />
                        </>}
                    </>}

                </div>
                <button type="button"
                    onClick={() => showModal(props.data)}
                    className='btn btn-sm btn-link m-0 p-0 btn-normal text-left'>
                    {props.data.effectivelyActive ?
                        props.data.name :
                        <span className="line-through topic-admin-title">
                            {props.data.name}
                        </span>}
                    {
                        props.data.type === 'role' ?
                            <small> ({props.data.relationship.topicCount} topics)</small> : null
                    }
                    <div className="d-flex justify-content-between align-items-center sub-div">
                        {props.data.type === 'entity' ? <small>Entity</small> : <small>Role</small>}
                        {props.data.default ? <small className="text-success"><i className="fal fa-check mr-1"></i>Default</small> : null}
                    </div>
                </button>
            </> :
            <>
                <div className="sm-visible">
                    <RestoreButton node={props.data} onRestore={onUpdate} />
                </div>
                <span className="topic-cell">
                    <span className="line-through topic-admin-title">{props.data.name}</span>
                    {props.data.type === 'role' ?
                            <small> ({props.data.relationship.topicCount} topics)</small> : null
                    }
                </span>
            </>
        ;
}
