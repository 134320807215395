import { ApiResource } from '../../_services/use-api-resource';

export interface AsyncResourceProps<T> {
    resource: ApiResource<T>;
    render: (props: T) => JSX.Element;
    error?: () => JSX.Element;
    loading?: JSX.Element;
    errorText?: string;
}

export function AsyncResource<T>(props: AsyncResourceProps<T>) {
    const { resource, render, error } = props;

    if (resource.loading) {
        return props.loading ? props.loading : <Loading />;
    }

    if (resource.hadError || !resource.resource) {
        return (error ? error() : <Error {...props} />)
    }

    return render(resource.resource) ;
}

function Loading() {
    return (
        <div className="text-center w-100">
            <i className="fal fa-spinner-third fa-spin"></i>
        </div>
    );
}

function Error(props: { errorText?: string }) {
    const { errorText } = props;

    return (
        <span className="alert-edge">
           <i className="fad fa-exclamation-triangle mr-2"></i> {errorText ? errorText : 'Unable to retrieve resource'}
        </span>
    );
}