import React, { PropsWithChildren } from 'react';
import { ReviewTrends } from '../_components/_Admin/ReviewTrends';
import { Settings } from '../_components/_Admin/Settings';
import MainLayout from '../_layouts/MainLayout';
import { scrollToTop } from '../_services/scroll-to-top';
import { useOnComponentMount } from '../_services/use-on-component-mount';

export const AdminPageUrl = '/admin'

export default function AdminPage(props: PropsWithChildren<any>) {

    useOnComponentMount(() => {
        scrollToTop(true);
        document.body.classList.add('admin-active');

        return () => {
            document.body.classList.remove('admin-active');
            setTimeout(() => {
                (window as any).Beacon('close');
            }, 5)
        }
    });


    return (
        <div className="is-admin">
            <MainLayout>
                <ReviewTrends />
                <Settings />
            </MainLayout>
        </div>
    )
}


