import { WordPressTopicsResponse } from '../api/admin/wordpress-topics';
import { api } from '../api/api';
import { useApiResource } from '../use-api-resource';
import { ApiResource } from './api-resource';

const wordpressTopicsResource = new ApiResource<WordPressTopicsResponse>(() => api.get.wordPressTopics());

export async function getWordpressTopics() {
    return await wordpressTopicsResource.get();
}

export function useWordpressTopics() {
    return useApiResource(getWordpressTopics);
}