import { useState } from 'react';
import { SortType } from '../../../_models/admin/sort-type';
import { Header } from './Header';
import { TrendingTopicFilters } from './TrendingTopicFilters';

export function TrendingTopicPanel() {
    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState<SortType>('UsageDesc');
    const [visibleFilter, setVisibleFilter] = useState<boolean | undefined>(undefined);
    return (
        <>
            <Header onFilter={setFilter} sort={sort} onSortChange={setSort}
                visibleFilter={visibleFilter}
                visibleFilterChange={setVisibleFilter}
            />
            <TrendingTopicFilters filter={filter} sort={sort} visible={visibleFilter} />
        </>
    );
}
