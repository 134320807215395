import { SuggestedResource } from '../../../../_models/taxonomy/suggested-resource';
import { NodeData } from './SuggestedTopicsTable';

export function FeaturedTag(showModal: (resource: SuggestedResource) => void) {
    return (props: { data: NodeData }) => {
        if (!props.data.featured) {
            return null;
        }
        if (!props.data.active) {
            return <span className="featured-section"><i className="fad fa-sparkles mr-2"></i> <span className="line-through">{props.data.featured}</span></span>
        }
        return <button type="button" className='btn btn-sm btn-link'
            onClick={() => showModal(props.data.resource)}
        >
            <i className="fad fa-sparkles mr-2"></i> {props.data.featured}
        </button>;
    };
}
