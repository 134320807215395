import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { LandingPageUrl } from './views/Landing';
import { RecentActivityPageUrl } from './views/RecentActivity';
import { TrendingSearchesPageUrl } from './views/TrendingSearches';
import { TrendingResourcesPageUrl } from './views/TrendingResources';
import { AboutPageUrlRoute } from './views/About';
import { SearchPageUrl } from './views/Search';
import { LoginPageUrl } from './views/Login';
import { AdminPageUrl } from './views/Admin';

import TaxonomyFilterProvider from './_components/__providers/TaxonomyFilterProvider';
import RandomBackroundProvider from './_components/__providers/RandomBackgroundProvider';
import GuardedRoute from './_services/auth/guarded-route';
import { isAdmin } from './_services/auth/roles/admin-role';
import UserProvider from './_components/__providers/UserProvider';
import config from './environment.json';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { Script } from './_components/Script';
import { Skeleton } from 'primereact/skeleton';
import { RecentlyAddedPageUrl } from './views/RecentlyAdded';
import { AdminTaxonomyTreePageUrl } from './views/AdminTaxonomyTree';
import { LogoutPageUrl } from './views/Logout';

const Landing = lazy(() => import('./views/Landing'));
const RecentActivityPage = lazy(() => import('./views/RecentActivity'));
const TrendingSearchesPage = lazy(() => import('./views/TrendingSearches'));
const TrendingResourcesPage = lazy(() => import('./views/TrendingResources'));
const AboutPage = lazy(() => import('./views/About'));
const SearchPage = lazy(() => import('./views/Search'));
const LoginPage = lazy(() => import('./views/Login'));
const AdminPage = lazy(() => import('./views/Admin'));
const AdminTaxonomyTreePage = lazy(() => import('./views/AdminTaxonomyTree'));
const RecentlyAddedPage = lazy(() => import('./views/RecentlyAdded'));
const LogoutPage = lazy(() => import('./views/Logout'));

function App() {
  return (<>
    <RandomBackroundProvider>
      <UserProvider>
        <TaxonomyFilterProvider>
          <BrowserRouter>
            <Switch>
              <Route path={LandingPageUrl} exact render={props =>
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <Landing {...props} />
                </Suspense>
              }
              />
              <Route path={RecentActivityPageUrl} exact render={props =>
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <RecentActivityPage {...props} />
                </Suspense>
              } />
              <Route path={TrendingSearchesPageUrl} exact render={props =>
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <TrendingSearchesPage {...props} />
                </Suspense>
              } />
              <Route path={TrendingResourcesPageUrl} exact render={props =>
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <TrendingResourcesPage {...props} />
                </Suspense>
              } />
              <Route path={AboutPageUrlRoute} exact render={props =>
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <AboutPage {...props} />
                </Suspense>
              } />
              <Route path={SearchPageUrl} exact render={() =>
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <SearchPage />
                </Suspense>
              } />
              <Route path={LoginPageUrl} exact render={() =>
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <LoginPage />
                </Suspense>
              } />
              <Route path={RecentlyAddedPageUrl} exact render={() =>
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <RecentlyAddedPage />
                </Suspense>
              } />
              <Route path={LogoutPageUrl} exact render={() =>
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <LogoutPage />
                </Suspense>
              } />
              <GuardedRoute path={AdminPageUrl} authorized={isAdmin} >
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <AdminPage />
                </Suspense>
              </GuardedRoute>
              <GuardedRoute path={AdminTaxonomyTreePageUrl} authorized={isAdmin} >
                <Suspense fallback={<Skeleton height="50em" width="100%" />}>
                  <AdminTaxonomyTreePage />
                </Suspense>
              </GuardedRoute>
              <Redirect to={LandingPageUrl} />
            </Switch>
          </BrowserRouter>
        </TaxonomyFilterProvider>
      </UserProvider>
    </RandomBackroundProvider>
    <div dangerouslySetInnerHTML={{
      __html: `<!--Environment - ${config.environment} -->`
    }}></div>

    {!!config.google?.tagManager && (
      <Script
        type="text/javascript"
        code={`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${config.google.tagManager}');
      `}
      />
    )}

    {/* MS Clarity */}
    {!!config.clarityId && (
      <Script
        type="text/javascript"
        code={`
      (function(c,l,a,r,i,t,y){
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
        t=l.createElement(r);t.async=1;t.defer=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${config.clarityId}");
      `}
      />
    )}


    {/* Help Scout */}
    <Script
      type="text/javascript"
      code={`
      (function (e, t, n) { 
        function a() { 
          var e=t.getElementsByTagName("script")[0], 
              n=t.createElement("script"); 
          n.type="text/javascript", 
          n.async=1, 
          n.defer=1, 
          n.src="https://beacon-v2.helpscout.net", 
          e.parentNode.insertBefore(n, e) 
        }
        if (e.Beacon = n = function (t, n, a) { 
            e.Beacon.readyQueue.push({ 
              method: t, 
              options: n, 
              data: a 
            }) 
          }, n.readyQueue = [], "complete" === t.readyState) return a(); 
        e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1) 
      })(window, document, window.Beacon || function () {}); 
      `}
    />

    <Script type="text/javascript"
      code={`
      window.Beacon('init', '${config.helpscoutId}') 
    `}
    />
  </>);
}

export default App;
