import React, { useState } from 'react';
import { Toast } from 'primereact/toast';
import { api } from '../../../../_services/api/api';
import { confirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog';
import { confirmPopup } from 'primereact/confirmpopup';
import { AdminEntity } from '../../../../_models/admin/admin-entity';
import { clearAdminTaxonomy } from '../../../../_services/api-resources/admin-taxonomy';
import { DialogProps } from 'primereact/dialog';

interface HideEntityButtonProps {
    entity: AdminEntity;
    onHide: () => void;
    toast: React.RefObject<Toast>;
    hasChanges: boolean;
    disabled?: boolean;
    saveChanges: () => Promise<boolean>;
}

export function HideEntityButton(props: HideEntityButtonProps) {
    const [isDeleting, setIsDeleting] = useState(false);

    const hide = () => confirmDialog({
        message: (
            <dl>
                <dt className="mt-3">Are you sure you want to hide this entity?</dt>
            </dl>
        ),
        header: 'Hide Entity',
        icon: 'fal fa-question-circle text-primary',
        accept: () => {
            setIsDeleting(true);
            api.post.setEntityVisibility({
                id: props.entity.id,
                visibility: false
            }).then(result => {
                if (result?.success) {
                    clearAdminTaxonomy();
                    props.onHide();
                } else {
                    props.toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Unable to hide entity',
                    })
                }
                setIsDeleting(false);
            })
        },
        blockScroll: true,
        draggable: false
    } as ConfirmDialogProps & DialogProps);

    const save = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'If you proceed your changes will be saved.  Proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                if (await props.saveChanges()) {
                    await hide();
                }
            }
        });
    }

    const onHideClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.hasChanges) {
            save(event);
        } else {
            hide();
        }
    };

    if (!props.entity.id) {
        return null;
    }

    return <button type="button" className="btn btn-outline-white text-danger"
        onClick={onHideClick} disabled={props.disabled || isDeleting}
    >
        {isDeleting ?
            <i className="fad fa-spin fa-spinner"></i>
            : <><i className="fas fa-eye-slash mr-2"></i> Hide Entity</>}

    </button>;
}
