import { compare } from '@lcms/helpers';
import { Dictionary } from '@lcms/super-fetch/lib/module/generics';
import { useState } from 'react';
import { LabelValue } from '../../_models/label-value';
import { api } from '../../_services/api/api';
import { useOnComponentMount } from '../../_services/use-on-component-mount';
import endpoints from './../../_services/api/api-endpoint-definition.json';


function objectToLabelValue(heading: string, object: Object & Dictionary<string>): LabelValue[] {
    return Object.keys(object).map(key => {
        return {
            label: heading + ': ' + key,
            value: object[key]
        };
    })
}

export function Settings() {
    const [settings, setSettings] = useState<LabelValue[]>([]);

    useOnComponentMount(() => {
        api.makeGetCall(endpoints.adminConfigApp)
            .then(async current => {
                return {
                    settings: objectToLabelValue('Application', current),
                }
            })
            .then(async current => {
                return {
                    settings: current.settings.concat(objectToLabelValue('Account DB', await api.makeGetCall(endpoints.adminConfigAuthDatabase))),
                }
            })
            .then(async current => {
                return {
                    settings: current.settings.concat(objectToLabelValue('Auth Ticket', await api.makeGetCall(endpoints.adminConfigAuth))),
                }
            })
            .then(async current => {
                return {
                    settings: current.settings.concat(objectToLabelValue('CORS', await api.makeGetCall(endpoints.adminConfigCors))),
                }
            })
            .then(async current => {
                return {
                    settings: current.settings.concat(objectToLabelValue('MDFL DB', await api.makeGetCall(endpoints.adminConfigDatabase))),
                }
            })
            .then(async current => {
                return {
                    settings: current.settings.concat(objectToLabelValue('Email Service', await api.makeGetCall(endpoints.adminConfigEmail))),
                }
            }).then(async current => {
                const allSettings = current.settings.concat(objectToLabelValue('Inbox', await api.makeGetCall(endpoints.adminConfigInbox)));
                setSettings(allSettings);
            })
    });

    return (
        <div className="bg-white rounded shadow-sm p-3">
            {settings?.sort(compare(a => a.label)).map((setting, i) =>
                <div className="row" key={i}>
                    <div className="col-12 col-md-3">
                        {setting.label}
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="pl-2 pl-md-0">
                            {setting.value}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )


}