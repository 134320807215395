import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import { useCallback } from 'react';
import { SortType } from '../../../_models/admin/sort-type';
import { api } from '../../../_services/api/api';
import { useApiResource } from '../../../_services/use-api-resource';
import { AsyncResource } from '../../AsyncResource/AsyncResource';
import { TrendingTopicFilter } from './TrendingTopicFilter';

export function TrendingTopicFilters(props: { filter: string; sort: SortType; visible?: boolean; }) {

    const { sort, filter, visible } = props;

    const load = useCallback(() =>
        api.post.trendingTopicFiltersAdmin({
            sort: sort,
            filter: filter,
            active: visible
        })
        , [sort, filter, visible])

    const trending = useApiResource(load);

    return (
        <>
            <AsyncResource
                resource={trending}
                errorText="Unable to load trending topics"
                render={(searches) => searches === null ?
                    <div>No Trending Resources to display</div>
                    : <div>
                        <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                        {searches.map(filter => (
                            <TrendingTopicFilter key={filter.id} filter={filter} reload={trending.reload}/>
                        ))}
                    </div>}
                loading={
                    <div>
                        <div className="bg-purple p-3 text-white w-50 rounded shadow-sm">
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            <span className="pl-2">Loading...</span>
                        </div>
                        {Array.from({ length: 10 }).map((r, i) => <Skeleton key={i} className="mb-2" width="100%" height="3em" borderRadius="5" />)}
                    </div>
                }
            />
        </>
    )
}

