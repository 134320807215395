import React, { PropsWithChildren } from 'react';
import { LandingSubPageSidebar } from '../_components/Sidebar/LandingSubPageSidebar';
import { SubPageSidebarResponsive } from '../_components/Sidebar/SubPageSidebarResponsive';
import { TrendingSearchesPanel } from '../_components/_TrendingSearches/TrendingSearchesPanel';
import LandingLayout from '../_layouts/LandingLayout';
import { scrollToTop } from '../_services/scroll-to-top';
import { useOnComponentMount } from '../_services/use-on-component-mount';

export const TrendingSearchesPageUrl = '/trending-searches';

export default function TrendingSearchesPage(props: PropsWithChildren<any>) {

    useOnComponentMount(() => {
        scrollToTop();
    })

    return (
        <LandingLayout>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-3 sb-main">
                    <LandingSubPageSidebar />
                </div>
                <SubPageSidebarResponsive pageTitle="Trending Searches" />
                <div className="col-sm-12 col-md-9 topics-main">
                    <TrendingSearchesPanel />
                </div>
            </div>
        </LandingLayout>
    );
}

