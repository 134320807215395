import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import React, { useState } from 'react';
import { clearTrendingResource } from '../../../_services/api-resources/trending-resources';
import { api } from '../../../_services/api/api';
import { defaultFormValueOf } from '../../__form/default-form-value';
import { FormGroup, FormGroupModel } from '../../__form/FormGroup';
import { CancelButton } from '../CancelButton';
import { Tooltip } from 'primereact/tooltip';
interface AddResourceDialogProps {
    onHide: () => void;
    onSave: () => void;
    toast: React.RefObject<Toast>
}

export function AddResourceDialog(props: AddResourceDialogProps) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [url, setUrl] = useState<FormGroupModel>(defaultFormValueOf(''));
    const [title, setTitle] = useState<FormGroupModel>(defaultFormValueOf(''));
    const [fetchedTitle, setFetchedTitle] = useState('');
    const [isFetchingTitle, setIsFetchingTitle] = useState(false);

    const onUrlChage = () => {
        setIsFetchingTitle(true);
        api.post.getPageTitle({
            url: url.value
        }).then(result => {
            setFetchedTitle(result?.title || '')
            setIsFetchingTitle(false);
        })
    };

    const formIsValid = url.isValid && title.isValid;
    const formIsDirty = url.isDirty || title.isDirty;

    const save = (silent: boolean) => async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        if (formIsValid) {
            setIsSubmitting(true);
            return await api.post.addTrendingResource({
                url: url.value,
                title: title.value
            }).then(result => {
                if (result?.success) {
                    clearTrendingResource();
                    if (!silent) {
                        props.onSave();
                    }

                } else {
                    props.toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Unable to save',
                    });
                }
                setIsSubmitting(false);
                return result?.success || false;
            });
        } else {
            return false;
        }
    };

    const onSubmit = save(false);

    const headerIconClass = 'h2 mb-0 mr-2 px-1 fal fa-file-plus';

    return (
        <Dialog visible onHide={props.onHide} blockScroll={true} closable={false} draggable={false}
            header={<>
                <div className="d-flex justify-content-between align-items-center">
                    <i className={headerIconClass}></i>
                    <h5 className="text-uppercase m-0">
                        Add Trending Resource
                    {formIsDirty ? <span data-pr-tooltip='Unsaved'> *</span> : null}
                    </h5>
                </div>
            </>}
            style={{ width: '80vh' }}
            footer={<div className="d-flex justify-content-between text-uppercase align-items-end">
                <div>
                    <button aria-label="Contribute Content" className="btn btn-primary" disabled={!formIsValid || isSubmitting}
                        type="button" onClick={() => onSubmit()}
                    >
                        {isSubmitting ? <i className="fal fa-spinner-third fa-spin"></i> : 'Save Resource'}
                    </button>
                </div>
                <div>
                    <CancelButton onAccept={props.onHide} autoAccept={!formIsDirty} />
                </div>
            </div>}
        >
            <div className="modal-form">
                <FormGroup name='url'
                    value={url.value} onChange={setUrl}
                    label='URL' fieldName='Resource url'
                    required maxLength={255}
                    showCharacterCount
                    onBlur={onUrlChage}
                />
                <div className="mb-4">
                    <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                    <small data-pr-tooltip="Enter https:// into the url above and we will try to grab the title for you">Autofetch Title <i className="fal fa-question-circle mr-2"></i> </small><br />

                    {isFetchingTitle ?
                        <Skeleton width='100%' height='1.5em' />
                        : <>
                            <small className="p-1 bg-secondary border-rounded mb-4">{fetchedTitle}</small>
                            {fetchedTitle ? <button
                                className="btn btn-outline-succes btn-sm" type="button"
                                onClick={() => {
                                    navigator.clipboard.writeText(fetchedTitle);
                                    props.toast.current?.show({
                                        content: 'Copied!',
                                        severity: 'info',
                                        closable: false
                                    })
                                }}
                            >
                                Copy
                    </button> : null}
                        </>}
                </div>
                <FormGroup name='title'
                    value={title.value} onChange={setTitle}
                    label='TITLE' fieldName='Resource title'
                    required maxLength={255}
                    disabled={isFetchingTitle}
                    showCharacterCount />

            </div>
        </Dialog>
    )
}