import endpoints from '../api-endpoint-definition.json';
import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { ApiResponse } from './api-response';

const definition = new ApiCall<ApiResponse>().post('moveTopics', {
    route: endpoints.adminTaxonomyRoleMoveTopics,
    params: {
        fromId: {
            type: ParameterTypes.String
        },
        toId: {
            type: ParameterTypes.String
        }
    }
})
export default definition;

