import { Dialog } from 'primereact/dialog';
import { ModalProps } from '../../_models/modal-props';

export function SubmitError(props: ModalProps) {
    return (
        <Dialog draggable={false} blockScroll={true} visible style={{ width: '50vw' }} onHide={props.onHide}
            header={
                <div className="text-uppercase">
                    <i className="fas fa-file-plus h4 mb-0 mr-2 px-1"></i> Contribute Content
                </div>
            }
            footer={
                <button aria-label="Close dialog" className="btn btn-primary text-uppercase" type="button"
                    onClick={props.onHide}>
                    Close
                </button>
            }
        >
            <div className="d-flex justify-content-start align-items-center">
                <i className="fad fa-exclamation-circle text-danger fa-3x mr-4"></i>
                <span className="text-danger"><b>Something is not right</b><br /> We were unable to process your request.  Please try again later.</span>
            </div>
        </Dialog>

    );
}
