import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';
import search from '../../_services/search';
import { SearchLevel } from './SearchLevel';
import { useSearchParams } from '../../_services/search-params';

export function SearchPanel() {
    const router = useHistory();
    const [level, setLevel] = useState<string>('recommended');
    const searchParams = useSearchParams();

    const termChange = (term: string) => {
        search(term, level, router, [])
    }

    const levelChange = (newLevel: string) => {
        if (level !== newLevel) {
            setLevel(newLevel);
            if (searchParams.term) {
                search(searchParams.term, newLevel, router, [])
            }
        }
    }

    return (
        <>
        <div id="anchor-search"></div>
        <div className="bg-white p-2 shadow rounded" id="search-panel">
            <div className="row">
                <div className="col d-flex align-items-center">
                    <div className="w-100">
                        <SearchBar searchOnComplete={true} search={termChange} />
                    </div>
                </div>
                <div className="col">
                    <b className="text-uppercase">
                        Search Across:
                    </b>
                    <SearchLevel level={level} setLevel={levelChange} />
                </div>
            </div>
        </div>
        </>
    )
}
