import { useRandomBackground } from '../_services/random-background-context';

export default function RandomBackground() {
    const className = useRandomBackground();

    return (
        <>
            <div className={'background-image ' + className}></div>
            <div className="opaque"></div>
        </>
    );
}