import React, { useContext } from 'react';
import { User } from '../_models/user';

export interface UserContextPayload {
    user?: User;
    logout: () => void;
    login: (user: User) => void;
}

const UserContext = React.createContext<UserContextPayload>({
    logout: () => { },
    login: () => { }
});

export default UserContext;

export function useAuthenticatedUser() {
    return useContext(UserContext);
}