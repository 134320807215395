import { useEffect, useState } from 'react';
import TreeNode from 'primereact/components/treenode/TreeNode';
import { AdminEntity } from '../_models/admin/admin-entity';
import { SuggestedResource } from '../_models/taxonomy/suggested-resource';
import { getAdminTaxonomy } from '../_services/api-resources/admin-taxonomy';
import { getAdminSuggestedTopics } from '../_services/api-resources/suggested-topics';
import { scrollToTop } from '../_services/scroll-to-top';
import { useOnComponentMount } from '../_services/use-on-component-mount';
import { OrganizationChart, OrganizationChartNodeData } from 'primereact/organizationchart';
import { Skeleton } from 'primereact/skeleton';
import { api } from '../_services/api/api';
import { LabelValue } from '../_models/label-value';

export const AdminTaxonomyTreePageUrl = '/admin-taxonomy-tree'

export default function AdminTaxonomyTreePage() {

    useOnComponentMount(() => {
        scrollToTop(true);
        document.body.classList.add('admin-active');

        return () => {
            document.body.classList.remove('admin-active');
            setTimeout(() => {
                (window as any).Beacon('close');
            }, 5)
        }
    });


    return (
        <div>
            <TaxonomyTree />
        </div>
    )
}

function mapTopic(resource: SuggestedResource, wpTopics: LabelValue[]): OrganizationChartNodeData {
    const topic = {
        label: resource.title,
        expanded: true,
        className: resource.active ? '' : 'text-muted',
        children: resource.children.map(r => mapTopic(r, wpTopics)),
        data: {
            ...resource,
            wpTopic: wpTopics.find(wp => wp.value === resource.wordpressTopicId)?.label
        }
    }
    return topic;
}

function TaxonomyTree() {
    const [taxonomy, setTaxonomy] = useState<AdminEntity[]>([]);
    const [treeNode, setTreeNode] = useState<OrganizationChartNodeData>()

    useOnComponentMount(() => {
        getAdminTaxonomy().then(results => {
            setTaxonomy(results?.entities || [])
        });
    })

    useEffect(() => {
        getAdminSuggestedTopics()
            .then(async results => {
                const wordpressTopics = (await api.get.wordPressTopics()) || [];
                const topics = (results || []);

                const root = taxonomy.map(t => {
                    const entity: OrganizationChartNodeData = {
                        label: t.name,
                        expanded: true,
                        className: t.isActive ? '' : 'text-muted',
                        children: t.roles.map(er => {
                            const role: OrganizationChartNodeData = {
                                label: er.role.name,
                                expanded: true,
                                className: er.isActive ? '' : 'text-muted',
                                children: topics
                                    .filter(topic => topic.entity.id === t.id && topic.role.id === er.role.id)
                                    .map(r => mapTopic(r, wordpressTopics))
                            }
                            return role;
                        })
                    }
                    return entity;
                })


                setTreeNode({
                    children: root,
                    expanded: true,
                    label: 'All'
                });
            })
    }, [taxonomy])

    if (!treeNode) {
        return <Skeleton width='100%' height='15em' />
    }

    const nodeTemplate = (node: TreeNode) => {
        if (node.data) {
            return (
                <div>
                    <div className="bg-primary p-2 text-white text-nowrap" style={{
                        margin: '-1.25rem',
                        marginBottom: '1rem'
                    }}>{node.label}</div>
                    <div>
                        <div>{node.data.featured ? <span><i className="fad fa-sparkles"></i> {node.data.featured}</span> : null}</div>
                        <div className="text-nowrap text-left"><small>WordPress Topic</small></div>
                        <div className="text-muted text-nowrap text-left"><small>{node.data.wpTopic}</small></div>
                    </div>
                </div>
            );
        }
        return (
            <div className="bg-primary p-2 text-white text-nowrap" style={{
                margin: '-1.25rem'
            }}>
                {node.label}
            </div>
        );
    }

    return <OrganizationChart value={[treeNode]} nodeTemplate={nodeTemplate} />
}

