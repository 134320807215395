import { Observable } from './observable';

export interface PendingGoogleSearch {
    term: string;
}

export class GoogleCustomSearchService {
    static $currentSearch = new Observable<PendingGoogleSearch | null>(null);
    static $performSearch = new Observable<PendingGoogleSearch | null>(null);
    static $engineReady = new Observable<boolean>(false);
    static $showResults = new Observable<boolean>(false);

    static queueSearch(term: string): void {
        this.$performSearch.next({
            term: term
        });
    }

    static searchComplete(): void {
        this.$currentSearch.next(this.$performSearch.current());
        this.$performSearch.next(null);
    }

    static showResults(): void {
        this.$showResults.next(true);
    }

    static hideResults(): void {
        this.$showResults.next(false);
    }
}
