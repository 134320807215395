import React, { PropsWithChildren, useState } from 'react';
import { SuggestedTopics } from '../_components/_Landing/SuggestedTopics/SuggestedTopics';
import { Sidebar } from '../_components/_Landing/Sidebar';
import { Header } from '../_components/_Landing/Header';
import LandingLayout from '../_layouts/LandingLayout';
import { SidebarResponsive } from '../_components/_Landing/SidebarResponsive';
import { useOnComponentMount } from '../_services/use-on-component-mount';
import { scrollToTop } from '../_services/scroll-to-top';
import { TopicTitles } from '../_components/_Landing/TopicTitles';
import { ScrollTop } from 'primereact/scrolltop';
import { Cookie } from '../_services/cookies';

export const LandingPageUrl = '/'
export const SuggestedTopicsPageUrl = '/'

export default function LandingPage(props: PropsWithChildren<any>) {
    useOnComponentMount(() => {
        scrollToTop(true);
    })



    return (
        <LandingLayout>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-3 sb-main">
                    <Sidebar />
                </div>
                <SidebarResponsive />
                <div className="clearfix"></div>
                <Content />
            </div>
            <span aria-label="Scroll to top"><ScrollTop /></span>
        </LandingLayout >

    )
}

const tocCookie = new Cookie<boolean>('toc', 90);

function Content() {
    const [showTableOfContents, setShowTableOfContents] = useState<boolean>(tocCookie.get(true) as boolean);

    const onVisibilityChanged = (show: boolean) => {
        setShowTableOfContents(show);
        tocCookie.set(show)
    }

    return (
        <div className="col-sm-12 col-md-9 topics-main">
            <Header showTableOfContents={showTableOfContents} tableOfContentsToggle={onVisibilityChanged} />
            <div className="d-flex justify-content-between align-items-top">
                <div className="w-100">
                    <SuggestedTopics />
                </div>
                <TopicTitles show={showTableOfContents} onHide={() => setShowTableOfContents(false)} />
            </div>
        </div>
    )
}