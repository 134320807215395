import React, { PropsWithChildren } from 'react';
import { AutoCompleteTerm } from './AutoCompleteTerm';

export interface AutoCompleteEntryProps {
    onSelect: (term: string) => void;
    remove: (id: number) => void;
    term: AutoCompleteTerm;
    filter?: string;
}

export function AutoCompleteEntry(props: PropsWithChildren<AutoCompleteEntryProps>) {

    const match = props.filter || '';
    const unmatched = props.term.term.substring(props.filter?.length || 0);
    const { term } = props;

    const [classNames, setClassNames] = React.useState('');
    const [ctaSearch, setSearch] = React.useState(false);
    const [ctaRemove, setRemove] = React.useState(false);

    React.useEffect(() => {
        setClassNames(`${ctaSearch ? 'hover-search' : ''}${ctaRemove ? 'hover-clear' : ''} py-1 term`);
    }, [ctaSearch, ctaRemove]);

    return (
        <div className={classNames}>
            <div className="d-flex justify-content-between">
                <button aria-label="Search" className="border-0 p-0 m-0 bg-transparent text-left" onClick={() => props.onSelect(props.term.term)} onMouseEnter={() => setSearch(true)} onMouseLeave={() => setSearch(false)}>
                    <div className="ac-term">
                        <span className="pl-1">
                            {term.isTrending && !ctaSearch ?
                                <i className="fal fa-chart-line text-muted"></i>
                                : null}
                            {term.canRemove && !ctaSearch ?
                                <i className="fal fa-clock text-muted"></i>
                                : null}

                            {ctaSearch ?
                                <i className="fal fa-search text-muted"></i>
                                : null}
                        </span>
                        <span>
                            <b>{match}</b>{unmatched}
                        </span>
                    </div>

                </button>
                <div className="mr-2" data-pr-tooltip="Remove recent search">

                    {term.canRemove ?
                        <button aria-label="Remove search term" onMouseEnter={() => setRemove(true)}
                            onMouseLeave={() => setRemove(false)} 
                            className="border-0 p-0 m-0 bg-transparent text-left"
                            onClick={() => props.remove(term.id)}
                            >
                            <i className="fal fa-times"></i>
                        </button>
                        : null}
                </div>


            </div>
        </div>
    )
}