import React, { useState } from 'react';
import { Header } from './Header';
import { RecentlyAddedResources } from './RecentlyAddedResources';

export function TrendingResourcesPanel() {
    const [filter, setFilter] = useState('');
    return (
        <>
            <Header onFilter={setFilter} />
            <RecentlyAddedResources filter={filter} />
        </>
    );
}
