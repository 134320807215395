import { SearchLevels } from '../../_models/search/search-levels';
import { useSearchParams } from '../../_services/search-params';

export function ThirdPartyNotice() {

    const searchParams = useSearchParams();

    if (searchParams.level === SearchLevels.recommendedResources) {
        return null;
    }

    return (
        <div className="external-notice bg-white mb-4 shadow-sm px-4 py-1">
            <h4 className="title"><i className="fas fa-link pr-2"></i>Notice:</h4>
            <p>
                These links are being provided as a convenience and for informational purposes
                only; they do not constitute an endorsement or an approval by the Lutheran
                Church&mdash;Missouri Synod of any of the products,
                services or opinions of the corporation or organization or individual. <br />
                The Lutheran Church&mdash;Missouri Synod bears no responsibility for the accuracy,
                legality or content of the external site or for that of subsequent links.
            </p>
            <p className="emphasis">
                Contact the external site for answers to questions regarding its content.
            </p>
        </div>
    );
}