import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { useState } from 'react';
import { api } from '../../../_services/api/api';
import { defaultFormValueOf } from '../../__form/default-form-value';
import { FormGroup, FormGroupModel } from '../../__form/FormGroup';
import { CancelButton } from '../CancelButton';

interface AddSearchDialogProps {
    onHide: () => void;
    onSave: () => void;
    toast: React.RefObject<Toast>
}

export function AddSearchDialog(props: AddSearchDialogProps) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [term, setTerm] = useState<FormGroupModel>(defaultFormValueOf(''));

    const formIsValid = term.isValid
    const formIsDirty = term.isDirty;

    const save = (silent: boolean) => async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        if (formIsValid) {
            setIsSubmitting(true);
            return await api.post.addSearchTerm({
                term: term.value
            }).then(result => {
                if (result?.success) {
                    if (!silent) {
                        props.onSave();
                    }

                } else {
                    props.toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Unable to save',
                    });
                }
                setIsSubmitting(false);
                return result?.success || false;
            });
        } else {
            return false;
        }
    };

    const onSubmit = save(false);

    const headerIconClass = 'h2 mb-0 mr-2 px-1 fal fa-file-plus';

    return (
        <Dialog visible onHide={props.onHide} blockScroll={true} closable={false} draggable={false}
            header={<>
                <div className="d-flex justify-content-between align-items-center">
                    <i className={headerIconClass}></i>
                    <h5 className="text-uppercase m-0">
                        Add Search Term
                    {formIsDirty ? <span data-pr-tooltip='Unsaved'> *</span> : null}
                    </h5>
                </div>
            </>}
            style={{ width: '80vh' }}
            footer={<div className="d-flex justify-content-between text-uppercase align-items-end">
                <div>
                    <button aria-label="Contribute Content" className="btn btn-primary" disabled={!formIsValid || isSubmitting}
                        type="button" onClick={() => onSubmit()}
                    >
                        {isSubmitting ? <i className="fal fa-spinner-third fa-spin"></i> : 'Save Search Term'}
                    </button>
                </div>
                <div>
                    <CancelButton onAccept={props.onHide} autoAccept={!formIsDirty} />
                </div>
            </div>}
        >
            <div className="modal-form">
                <FormGroup name='term'
                    value={term.value} onChange={setTerm}
                    label='SEARCH TERM' fieldName='Search term'
                    required maxLength={255}
                    showCharacterCount
                />
            </div>
        </Dialog>
    )
}