import { useEffect } from 'react';
import { SearchLevels } from '../../../_models/search/search-levels';
import { ErrorLog } from '../../../_services/error-log';
import { GoogleCustomSearchService } from '../../../_services/google-custom-search-service';
import { useSearchParams } from '../../../_services/search-params';


export function GoogleParamsWatcher() {
    const searchParams = useSearchParams();

    useEffect(() => {
        const serviceValues: { ready: boolean; } = {
            ready: false,
        }

        const setVisibility = (isVisible: boolean) => {

            const customResults = document.getElementsByClassName('gsc-control-cse').item(0);
            if (!customResults) {
                console.error('Unable to locate results div');
                return;
            }

            if (isVisible) {
                customResults.classList.remove('d-none');
            } else {
                customResults.classList.add('d-none');
            }
        }

        const search = (term: string) => {
            const searchElement = (window as any).google?.search.cse.element.getElement('gcs');
            if (!searchElement) {
                console.error('GCS Not Ready')
                return;
            }

            ErrorLog.log('GCS', `"${term}"`)
            searchElement.execute(term);
        }

        const visibilitySub = GoogleCustomSearchService.$showResults.subscribe(show => {
            if (serviceValues.ready) {
                setVisibility(show);
            }
        })

        const termPushedSub = GoogleCustomSearchService.$performSearch.subscribe(queuedSearch => {
            if (serviceValues.ready) {
                if (queuedSearch?.term) {
                    search(queuedSearch.term);
                }
            }
        })

        const engineReadySub = GoogleCustomSearchService.$engineReady.subscribe(isReady => {
            if (!serviceValues.ready && isReady) {
                const queuedTerm = GoogleCustomSearchService.$performSearch.current();

                if (queuedTerm?.term) {
                    search(queuedTerm.term);
                }

                setVisibility(GoogleCustomSearchService.$showResults.current());
            }
            serviceValues.ready = isReady;
        })

        return () => {
            engineReadySub.unsubscribe();
            termPushedSub.unsubscribe();
            visibilitySub.unsubscribe();
        }
    }, []);

    const { level, term, searchControl } = searchParams;
    useEffect(() => {
        if (level === SearchLevels.partnerResources) {
            GoogleCustomSearchService.showResults();
            if (term) {
                GoogleCustomSearchService.queueSearch(term);
            }
        } else {
            GoogleCustomSearchService.hideResults();
        }

    }, [level, term, searchControl]);

    return <div style={{ display: 'none' }}>Google Custom Search Param Watcher</div>;
}
