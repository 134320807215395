import { PropsWithChildren, useEffect, useState } from 'react';
import RandomBackgroundContext from '../../_services/random-background-context';

export default function RandomBackroundProvider(props: PropsWithChildren<any>) {
    const [className, setClassName] = useState<string>('');

    useEffect(() => {
        setTimeout(() => {
            const image = Math.floor(Math.random() * 7) + 1;
            setClassName(`image-${image}`);
        }, 1)
    }, [])

    return (
        <RandomBackgroundContext.Provider value={className}>
            {props.children}
        </RandomBackgroundContext.Provider>
    )
}