import React from 'react';
import { SearchPanel } from '../_components/_Search/SearchPanel';
import { GoogleCustomSearchResults } from '../_components/_Search/GoogleCustomSearch/GoogleCustomSearchResults';
import { GoogleCustomSearchPlugin } from '../_components/_Search/GoogleCustomSearch/GoogleCustomSearchPlugin';
import { GoogleParamsWatcher } from '../_components/_Search/GoogleCustomSearch/GoogleParamsWatcher';
import MainLayout from '../_layouts/MainLayout';
import { Header } from '../_components/_Search/Header';
import { SubHeader } from '../_components/_Search/SubHeader';
import { WordpressParamsWatcher } from '../_components/_Search/WordpressSearch/WordpressParamsWatcher';
import { ThirdPartyNotice } from '../_components/_Search/ThirdPartyNotice';
import { useOnComponentMount } from '../_services/use-on-component-mount';
import { scrollToTop } from '../_services/scroll-to-top';

export const SearchPageUrl = '/search'

export default function SearchPage() {

    useOnComponentMount(() => {
        scrollToTop(true);
    })

    return (
        <>
            <GoogleCustomSearchPlugin />

            <MainLayout>
                <SearchPanel />
                <div className="mt-2">
                    <Header />
                </div>
                <div className="mt-2">
                    <SubHeader />
                </div>
                <div className="mt-3">
                    <ThirdPartyNotice />
                </div>
                <div className="mt-3">
                    <GoogleCustomSearchResults />
                    <GoogleParamsWatcher />
                    <WordpressParamsWatcher />
                </div>
            </MainLayout>
        </>
    )
}
