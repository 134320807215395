import { useModal } from '../../_services/use-modal';
import { ContributeResourceForm } from '../ContributeResource/ContributeResourceForm';

export function ContributeButton() {

    const { visible, showModal, hideModal } = useModal();

    return (
        <>
            <button aria-label="Open dialog for contributing a resource" className="btn cta-interact btn-sm" type="button"
                onClick={showModal}>
                <div className="d-flex justify-content-between align-items-center">
                    <i className="fas fa-file-plus h3 mb-0 mr-1 px-1 text-primary"></i>
                    <div className="small">CONTRIBUTE</div>
                    <div className="text-left">
                        <div className="cta-title">
                            CONTRIBUTE
                    </div>
                        <div className="cta-subtitle">
                            Submit a resource
                    </div>
                    </div>


                </div>
            </button>
            {visible ? <ContributeResourceForm onHide={hideModal} /> : null}
        </>
    );
}

