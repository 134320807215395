import { LabelValue } from '../../../_models/label-value';
import { scrollToTop } from '../../../_services/scroll-to-top';
import { useTaxonomyFilter } from '../../../_services/taxonomy-filter-context';


export function RoleSelect(props: LabelValue) {
    const taxonmyFilter = useTaxonomyFilter();
    const select = () => {
        taxonmyFilter.setFilter({
            entity: taxonmyFilter.filter.entity,
            featured: taxonmyFilter.filter.featured,
            role: props.value
        });
        scrollToTop();
    }

    const className = taxonmyFilter.filter.role === props.value ? 'border-warning text-warning' : 'border-light';

    return (
        <button aria-label={'Apply Role- ' + props.label} className={'btn btn-block rounded-0 m-0 border ' + className} onClick={select}>
            {props.label}
        </button>
    );
}
