import React, { PropsWithChildren } from 'react';
import { LandingSubPageSidebar } from '../_components/Sidebar/LandingSubPageSidebar';
import LandingLayout from '../_layouts/LandingLayout';
import { TrendingResourcesPanel } from '../_components/_TrendingResources/TrendingResourcesPanel';
import { SubPageSidebarResponsive } from '../_components/Sidebar/SubPageSidebarResponsive';
import { useOnComponentMount } from '../_services/use-on-component-mount';
import { scrollToTop } from '../_services/scroll-to-top';

export const TrendingResourcesPageUrl = '/trending-resources';

export default function TrendingResourcesPage(props: PropsWithChildren<any>) {

    useOnComponentMount(() => {
        scrollToTop();
    })
    
    return (
        <LandingLayout>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-3 sb-main">
                    <LandingSubPageSidebar />
                </div>
                <SubPageSidebarResponsive pageTitle="Trending Resources" />
                <div className="col-sm-12 col-md-9 topics-main">
                    <TrendingResourcesPanel />
                </div>
            </div>
        </LandingLayout>
    );
}

