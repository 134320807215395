import React from 'react';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { SuggestedTopicsPageUrl } from '../../views/Landing';
import { SidebarSections } from './SidebarSections';

export function LandingSubPageSidebar(props: PropsWithChildren<{}>) {
    return (
        <div className="bg-white shadow-sm">
            <Link to={SuggestedTopicsPageUrl}>
                <div className="bg-purple w-100 text-uppercase text-white py-3 px-2 mb-2">
                    <div className="d-flex align-items-center">
                        <i className="fas fa-arrow-left fs-115"></i>
                        <div className="text-center flex-fill return-link">
                            Return to Suggested Topics
                        </div>
                    </div>
                </div>
            </Link>
            <div className="py-2 px-3">
                {props.children ? props.children : <SidebarSections />}
            </div>
        </div>
    )
}