import { Dialog } from 'primereact/dialog';
import { ModalProps } from '../../_models/modal-props';

export function SubmitSuccess(props: ModalProps) {
    return (
        <Dialog blockScroll={true} draggable={false} visible style={{ width: '50vw' }} onHide={props.onHide}
            header={
                <div className="text-uppercase">
                    <span className="fa-stack">
                        <i className="fas fa-file fa-stack-2x text-dark"></i>
                        <i className="fas fa-comment-alt fa-stack-1x ml-0 mt-1 text-white"></i>
                    </span>
                     Request Content
                </div>
            }
            footer={
                <button aria-label="Close dialog" className="btn btn-secondary text-uppercase" type="button"
                    onClick={props.onHide}>
                    Close
                </button>
            }
        >
            <div className="d-flex justify-content-start align-items-center">
                <i className="fad fa-check-circle text-success fa-3x mr-4"></i>
                <span className="text-success"><b>We'll get back to you</b><br /> Your request has been saved.  Check your inbox for a confirmation email.</span>
            </div>
        </Dialog>
    );
}
