import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { defaultCheckboxValueOf, defaultFormValueOf } from '../../../__form/default-form-value';
import { CheckboxFormGroup, CheckboxFormGroupModel } from '../../../__form/CheckboxFormGroup';
import { AdminEntityRole } from '../../../../_models/admin/admin-entity-role';
import { AdminEntity } from '../../../../_models/admin/admin-entity';
import { AdminRole } from '../../../../_models/admin/admin-role';
import { FormGroup, FormGroupModel } from '../../../__form/FormGroup';
import { HideRoleButton } from './HideRoleButton';
import { CancelButton } from '../../CancelButton';
import { clearAdminTaxonomy } from '../../../../_services/api-resources/admin-taxonomy';
import { api } from '../../../../_services/api/api';
import { MoveTopicsButton } from './MoveTopics';

interface EntityRoleEditFormProps {
    toast: React.RefObject<Toast>
    entityRole: AdminEntityRole;
    entity: AdminEntity;
    role: AdminRole;
    onHide: () => void;
    onSave: () => void;
}

export function EntityRoleEditForm(props: EntityRoleEditFormProps) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDefault, setIsDefault] = useState<CheckboxFormGroupModel>(defaultCheckboxValueOf(props.entityRole.isDefault));
    const [role, setRole] = useState<FormGroupModel>(defaultFormValueOf(props.role.name))

    const formIsValid = isDefault.isValid && role.isValid;
    const formIsDirty = props.role.name !== role.value ||
        props.entityRole.isDefault !== isDefault.value;

    const save = (silent: boolean) => async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        if (formIsValid) {
            setIsSubmitting(true);
            return await api.post.saveEntityRole({
                entityId: props.entity.id,
                isDefault: isDefault.value,
                name: role.value,
                roleId: props.role.id
            })
                .then(result => {
                    if (result?.success) {
                        clearAdminTaxonomy();
                        if (!silent) {
                            props.onSave();
                        }

                    } else {
                        props.toast.current?.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Unable to save',
                        });
                    }
                    setIsSubmitting(false);
                    return result?.success || false;
                });
        } else {
            return false;
        }
    };

    const onSubmit = save(false);
    const headerIconClass = 'h2 mb-0 mr-2 px-1 ' + (props.entityRole.id ? 'fal fa-file-edit' : 'fal fa-file-plus');


    return (<>
        <Dialog onHide={props.onHide} visible blockScroll={true} closable={false} draggable={false}
            header={<>
                <div className="d-flex justify-content-between align-items-center">
                    <i className={headerIconClass}></i>
                    <h5 className="text-uppercase m-0">
                        {props.entityRole.id
                            ? <><b>Edit Role -</b> {props.entity.name}</>
                            : <><b>Add Role -</b> to {props.entity.name}</>}
                        {formIsDirty ? <span data-pr-tooltip='Unsaved'> *</span> : null}
                    </h5>
                </div>
            </>}
            style={{ width: '80vh' }}
            footer={<div className="d-flex justify-content-between text-uppercase align-items-center my-1">
                <div>
                    <div>
                        <button aria-label="Contribute Content" className="btn btn-primary" disabled={!formIsValid || isSubmitting}
                            type="button" onClick={() => onSubmit()}
                        >
                            {isSubmitting ? <i className="fal fa-spinner-third fa-spin"></i> : 'Save Role'}
                        </button>

                    </div>
                </div>
                <div>
                    <MoveTopicsButton
                        fromId={props.entityRole.id}
                        disabled={props.entityRole.topicCount === 0}
                        onMove={props.onSave}
                        toast={props.toast}
                        hasChanges={formIsDirty}
                        saveChanges={save(true)}
                    />
                </div>
                <div>
                    <HideRoleButton
                        entityRole={props.entityRole}
                        onHide={props.onSave}
                        toast={props.toast}
                        hasChanges={formIsDirty}
                        disabled={formIsDirty && !formIsValid}
                        saveChanges={save(true)}
                    />
                </div>
                <div>
                    <CancelButton onAccept={props.onHide} autoAccept={!formIsDirty} />
                </div>
            </div>}
        >
            <form onSubmit={onSubmit} className="modal-form">

                <FormGroup
                    name='role' label='ROLE'
                    fieldName='Role'
                    value={role.value}
                    onChange={setRole}
                    required maxLength={255}
                />

                <CheckboxFormGroup name='isDefault'
                    value={isDefault.value} onChange={setIsDefault}
                    label={(isDefault.value ? 'Default for- ' : 'Not default- ') + props.entity.name.toString()} fieldName='Default' />
            </form>
        </Dialog>
    </>);
}

