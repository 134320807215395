import { CompleteTaxonomyFilter } from '../../_models/taxonomy-filter/taxonomy-filter';
import { SuggestedResource } from '../../_models/taxonomy/suggested-resource';
import { api } from '../api/api';
import { ErrorLog } from '../error-log';

export default class Topics {

    static async get(filter: CompleteTaxonomyFilter): Promise<SuggestedResource[] | null> {
        return await api.post.getTopics(filter)
            .then(data => data, error => {
                ErrorLog.log(error);
                return null;
            })
    }
}