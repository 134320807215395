import { confirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog';
import { DialogProps } from 'primereact/dialog';
import { useCallback, useState } from 'react';
import { clearAdminTaxonomy } from '../../../../../_services/api-resources/admin-taxonomy';
import { api } from '../../../../../_services/api/api';
import { NodeData } from '../TaxonomyTable';

export function DeleteButton(props: { node: NodeData; onDelete: () => void; }) {

    const [hiding, setHiding] = useState(false);

    const { node, onDelete } = props;
    const onClick = useCallback(() => {
        confirmDialog({
            message: (
                <dl>
                    <dt className="mt-3">Are you sure you want to hide this {node.type}?</dt>
                </dl>
            ),
            header: 'Hide ' + node.type,
            icon: 'fal fa-question-circle text-primary',
            accept: () => {
                setHiding(true);
                const call = node.type === 'entity' ?
                    api.post.setEntityVisibility({
                        id: node.entity.id,
                        visibility: false
                    }) :
                    api.post.setRoleVisibility({
                        id: node.relationship.id,
                        visibility: false
                    })

                call.then(r => {
                    if (r?.success) {
                        clearAdminTaxonomy()
                        onDelete();
                    }
                    setHiding(false);
                });
            },
            blockScroll: true,
            draggable: false
        } as ConfirmDialogProps & DialogProps);


    }, [node, onDelete]);

    return <button type="button" className='btn btn-sm btn-icon-only'
        data-pr-tooltip="Hide"
        onClick={onClick} disabled={hiding}
    >
        {hiding ? <i className="fad fa-spin fa-spinner"></i> : <i className="fad fa-eye-slash text-dark fa-2x"></i>}
    </button>;
}
