import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { ModalProps } from '../../_models/modal-props';
import { api } from '../../_services/api/api';
import { defaultFormValue } from '../__form/default-form-value';
import { FormGroup, FormGroupModel } from '../__form/FormGroup';
import { SubmitError } from './SubmitError';
import { SubmitSuccess } from './SubmitSuccess';

export function RequestResourceForm(props: ModalProps) {

    const [saved, setSaved] = useState({
        saved: false,
        hadError: false
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [name, setName] = useState<FormGroupModel>(defaultFormValue);
    const [email, setEmail] = useState<FormGroupModel>(defaultFormValue);
    const [request, setRequest] = useState<FormGroupModel>(defaultFormValue);

    if (saved.saved) {
        return saved.hadError ? <SubmitError {...props} /> : <SubmitSuccess {...props} />
    }

    const formValid = name.isValid && email.isValid && request.isValid;

    const save = () => {
        if (formValid) {
            setIsSubmitting(true);
            api.post.requestResource({
                email: email.value,
                name: name.value,
                request: request.value
            }).then(result => {
                if (result) {
                    setSaved({
                        saved: true,
                        hadError: !result.success
                    });
                } else {
                    setSaved({
                        saved: true,
                        hadError: true
                    });
                }
                setIsSubmitting(false);
            }, () => {
                setSaved({
                    saved: true,
                    hadError: true
                });
                setIsSubmitting(false);
            });
        }
    }
    const onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        save();

    };

    return (
        <Dialog draggable={false} blockScroll={true} visible style={{ width: '50vw' }} onHide={props.onHide}
            header={
                <div className="text-uppercase">
                    <span className="fa-stack mr-1">
                        <i className="fas fa-file fa-stack-2x"></i>
                        <i className="fas fa-comment-alt fa-stack-1x ml-0 mt-1 text-white"></i>
                    </span>
                     Request Content
                </div>
            }
            footer={
                <div className="d-flex justify-content-between text-uppercase align-items-end">
                    <div>
                        <div>
                            <button aria-label="Request content dialog" className="btn btn-primary"
                                type="button" onClick={save}
                                disabled={!formValid || isSubmitting}>
                                {isSubmitting ? <i className="fal fa-spinner-third fa-spin"></i> : 'Request Content'}
                            </button>
                        </div>
                    </div>
                    <div>
                        <button aria-label="Close dialog" className="btn btn-secondary"
                            type="button" onClick={props.onHide}>
                            Close
                        </button>
                    </div>

                </div>
            }>
            <form onSubmit={onSubmit} className="modal-form">
                <FormGroup
                    value={name.value} onChange={setName}
                    label="YOUR NAME"
                    fieldName="Your name" name="name"
                    required showCharacterCount maxLength={255} />

                <FormGroup
                    value={email.value} onChange={setEmail}
                    label="EMAIL ADDRESS"
                    fieldName="Email address" name="email"
                    required showCharacterCount maxLength={80}
                    validation={(value) => (/^.+@.+\..+$/.test(value))}
                    validationMessage={'Please enter a valid email address'} />

                <FormGroup
                    value={request.value} onChange={setRequest}
                    label="How can we help?" multiLine
                    subLabel="Provide a little detail on what you are looking for..."
                    fieldName="Request" name="request"
                    showCharacterCount maxLength={255} />
            </form>
        </Dialog>
    );
}
