import React from 'react';
import { Link } from 'react-router-dom';
import { AboutPageUrl } from '../../views/About';
import { LandingSubPageSidebar } from '../Sidebar/LandingSubPageSidebar';
import { useActiveIndex } from './active-index';

export function Sidebar() {

    const activeIndex = useActiveIndex();
    const baseClass = 'btn btn-sm btn-block rounded-0 sb-btn text-uppercase ';

    return (
        <LandingSubPageSidebar>
            <Link to={AboutPageUrl} className={baseClass + (activeIndex === 0 ? 'btn-outline-warning current' : 'btn-secondary')}>
                About
            </Link>
            <a href="/overview.pdf" rel="noreferrer" target="_blank" className={baseClass + (activeIndex === 1 ? 'btn-outline-warning current' : 'btn-secondary')}>
                <i className="mr-2 fal fa-file-pdf text-primary fa-2x"></i> How To Use
            </a>
        </LandingSubPageSidebar>
    );
}