import { Dropdown } from 'primereact/dropdown';
import { SelectItem } from 'primereact/api';
import { SortType } from '../../../_models/admin/sort-type';

const values: SelectItem[] = [
    {
        label: 'Usage (High to Low)',
        value: 'UsageDesc' as SortType
    },
    {
        label: 'Usage (Low to High)',
        value: 'UsageAsc' as SortType
    },
    {
        label: 'A-Z',
        value: 'AlphaAsc' as SortType
    },
    {
        label: 'Z-A',
        value: 'AlphaDesc' as SortType
    }
]

export function SortByDropdown(props: { value: SortType, onChange: (v: SortType) => void }) {
    return (
        <Dropdown value={props.value} onChange={(e) => props.onChange(e.value)} options={values} />
    )
}