import { Dictionary } from '@lcms/helpers';
import { useActiveIndex } from './active-index';
import { HowIsMyDataUsedDialog } from '../HowIsMyDataUsedDialog/HowIsMyDataUsedDialog';
import help1 from './../../assets/images/help1.png';
import help2 from './../../assets/images/help2.png';
import help3 from './../../assets/images/help3.png';
import help4 from './../../assets/images/help4.png';
import help5 from './../../assets/images/help5.png';
import help6 from './../../assets/images/help6.png';
import { useModal } from '../../_services/use-modal';

export function AboutPanel() {
    const activeIndex = useActiveIndex();

    const tabs: Dictionary<JSX.Element | null> = {
        '0': <AboutContent />,
        '1': <HowToUseContent />,
    }

    return (<>
        <div className="bg-white p-3 rounded shadow-sm">
            {tabs[activeIndex]}
        </div>
    </>)
}

function AboutContent() {
    return (
        <>
            <h5 className="text-dark medium mb-2">About | Making Disciples for Life</h5>
            <div className="d-flex justify-content-between align-items-start about-screen flex-wrap">
                <div className="w-50">
                    <iframe
                        title="Home Video"
                        // width="789"
                        // height="444"
                        src="https://player.vimeo.com/video/374498135?background=0"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                            width: '100%',
                            aspectRatio: '789/444'
                        }}
                    >
                    </iframe>
                </div>
                <div className="w-50">
                    <p>Making Disciples for Life is the triennial emphasis of The Lutheran Church—Missouri Synod and is a partnership between individuals, congregations, districts, and the Synod to evangelize the lost, retain the faithful, strengthen congregations, and plant churches and schools. </p>
                    <p>Conferences, grants, and a resource center comprise the means by which resources are shared and utilized to benefit the work of the church.</p>
                    <a href="https://www.lcms.org/how-we-serve/making-disciples" target="_blank" rel="noreferrer" className="btn btn-primary text-uppercase">
                        More News &amp; Information
                    </a>
                </div>
            </div>
        </>
    )
}

function HowToUseContent() {
    const { visible, showModal, hideModal } = useModal();

    return (<div className="text-normal">
        <h5 className="text-dark medium mb-2">How To Use</h5>
        <div className="row mb-2">
            <div className="col">
                <b>Suggested Topics</b>
                <p>Suggested Topics have been identified, created, and organized to help connect you with articles, information, and resources.</p>
                <div className="d-flex justify-content-around align-items-center">
                    <dl className="dl-tips">
                        <dt>Entity</dt>
                        <dd>Choose the 'Entity' that best describes what you are looking for</dd>
                        <dd className="smaller">Unsure? - Select 'View All'</dd>
                        <dt>Select a Role</dt>
                        <dd>Narrow the topics by selecting a 'Role'</dd>
                        <dd className="smaller">Unsure? - Select 'View All'</dd>
                    </dl>
                    <img src={help1} className=" p-0 border rounded shadow-sm" height="auto" width="auto" alt="Suggested Topics" />
                </div>

            </div>
        </div>
        <div className="row mb-2">
            <div className="col">
                <b>Featured Topics</b>
                <p>We will promote, or feature certain topics periodically to highlight a topic related to a liturgical event, intiative or in response to a disaster.</p>
                <div className="d-flex justify-content-around align-items-center">
                    <dl className="dl-tips">
                        <dt>Featured Topic</dt>
                        <dd>Select the Featured Topic you'd like to see related resources.</dd>
                        <dd className="smaller">Note- you can select multiple featured resources</dd>
                    </dl>
                    <img src={help2} className=" p-0 border rounded shadow-sm" height="auto" width="auto" alt="Featured Topics" />
                </div>

            </div>
        </div>
        <div className="row mb-2">
            <div className="col">
                <b>Trending</b>
                <p>User interactions like yours help populate Trending Searches and Trending Resources.</p>
                <div className="d-flex justify-content-around align-items-center">
                    <dl className="dl-tips">
                        <dt>Trending Searches</dt>
                        <dd>Choose the search term that you'd like to find resources on</dd>
                        <dd className="smaller">Tip- You are able to sort and filter this list</dd>
                        <dt>Trending Resources</dt>
                        <dd>Click on the result you'd like to view</dd>
                        <dd className="smaller">Tip- You are able to sort and filter this list</dd>
                    </dl>
                    <img src={help3} className=" p-0 border rounded shadow-sm" height="auto" width="auto" alt="Trending" />
                </div>

            </div>
        </div>
        <div className="row mb-2">
            <div className="col">
                <b>You Matter</b>
                <p>We are building this resource together as a Synod and that includes getting feedback from you.</p>
                <div className="d-flex justify-content-around align-items-center">
                    <dl className="dl-tips">
                        <dt>Contribute</dt>
                        <dd>You are able to share/add resources with us by clicking 'Contribute' and completing the form</dd>
                        <dt>Request</dt>
                        <dd>Did we miss something? Are you in need of a specific resource? Click 'Request' and complete the form</dd>
                    </dl>
                    <img src={help4} className=" p-0 border rounded shadow-sm" height="auto" width="auto" alt="Interact With Us" />
                </div>

            </div>
        </div>
        <div className="row mb-2">
            <div className="col">
                <b>Table of Contents</b>
                <p>Sometimes the amount of suggested content seems overwhelming, for that we provide a top-level "table of contents" view at Topics. </p>
                <div className="d-flex justify-content-around align-items-center">
                    <dl className="dl-tips">
                        <dt>Available Topics</dt>
                        <dd>Clicking on each heading will take you to that section and show you all resources related to your selection</dd>
                        <dt>Updates</dt>
                        <dd>This list will update as you narrow, filter and change your Entity and/or Role selections</dd>
                        <dd className="smaller">Note- This feature is only available for larger screen sizes</dd>
                    </dl>
                    <img src={help5} className=" p-0 border rounded shadow-sm" height="auto" width="auto" alt="Interact With Us" />
                </div>
            </div>
        </div>
        <div className="row mb-2">
            <div className="col">
                <b>Search</b>
                <p>Feel free to look for yourself. We offer two levels of search - MDFL Library Catalog, and Synod and Partner Websites.</p>
                <div className="d-flex justify-content-around align-items-center">
                    <dl className="dl-tips">
                        <dt>As You Type</dt>
                        <dd>-Trending search terms will display to help guide you along</dd>
                        <dd>-Your recent search terms will display for quick access</dd>
                        <dt>MDFL Library Catalog</dt>
                        <dd>This is the hand-curated selection of articles, documents, resources and links that we have assembled for you</dd>
                        <dt>Synod and Partner Websites</dt>
                        <dd>A collection of sites and resources from lcms.org and affiliated partners</dd>
                    </dl>
                    <img src={help6} className=" p-0 border rounded shadow-sm" height="auto" width="auto" alt="Search" />
                </div>
            </div>
        </div>
        <hr />
        <div className="row mb-2">
            <div className="col">
                <b>The site gets smarter every time it's used</b>
                <p>Behind the scenes we are using your data to build a better experience for all.</p>
                <button aria-label="Open dialog to learn how your activity is used" className="btn btn-link normalize p-0 m-0"
                    type="button" onClick={showModal}>Learn how your activity is used, accessed, and stored</button>
            </div>
        </div>
        {visible ? <HowIsMyDataUsedDialog onHide={hideModal} /> : null}
    </div>
    )
}