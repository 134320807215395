import { SidebarSections } from '../Sidebar/SidebarSections';
import { SuggestedTopicsFilterControls } from './FilterControls/SuggestedTopicsFilterControls';

export function Sidebar() {

    return (
        <>
            <div className="bg-white shadow-sm py-2 px-3">
                <div className="mb-5">
                    <SuggestedTopicsFilterControls />
                </div>
                <div>
                    <SidebarSections />
                </div>
            </div>
        </>
    )
}

