import { useCallback, useState } from 'react';
import { getAdminTaxonomy } from '../../../_services/api-resources/admin-taxonomy';
import { TaxonomyFilterResponse } from '../../../_services/api/admin/taxonomy-filters';
import { ErrorLog } from '../../../_services/error-log';
import { useOnComponentMount } from '../../../_services/use-on-component-mount';

type State = 'initial' | 'loading' | 'error';
interface PendingState {
    state: State;
}
export interface LoadedState {
    state: 'done';
    data: TaxonomyFilterResponse;
    reload: () => void
}
type AdminTaxonomyType = PendingState | LoadedState;

export function useAdminTaxonomy() {
    const [topics, setTopics] = useState<AdminTaxonomyType>({
        state: 'initial'
    });

    const reload = useCallback(() => {
        setTopics({
            state: 'loading'
        })
        getAdminTaxonomy()
            .then(result => {
                if (result) {
                    setTopics({
                        state: 'done',
                        data: result,
                        reload: reload
                    })
                } else {
                    setTopics({
                        state: 'error'
                    })
                }

            }, error => {
                ErrorLog.log(error);
                setTopics({
                    state: 'error'
                })
            })
    }, [])

    useOnComponentMount(() => {
        reload();
    });

    return topics;
}