import { Skeleton } from 'primereact/skeleton';
import { useEffect, useState } from 'react';
import { SearchLevels } from '../../../_models/search/search-levels';
import { WordPressSearchResult } from '../../../_models/search/search-result';
import { api } from '../../../_services/api/api';
import { ErrorLog } from '../../../_services/error-log';
import { htmlToText } from '../../../_services/html-to-text';
import { useSearchParams } from '../../../_services/search-params';
import { WordpressResults } from './WordpressResults';

export function WordpressParamsWatcher() {
    const [searching, setSearching] = useState(false)
    const searchParams = useSearchParams();
    const [wordPressResults, setWordPressResults] = useState<WordPressSearchResult[]>([]);

    const { term, level, tags, searchControl } = searchParams;
    useEffect(() => {
        if (!term) { return; }
        ErrorLog.log('SEARCH', term)

        if (level === SearchLevels.recommendedResources) {
            ErrorLog.log('WP', `"${term}"`)
        }

        setSearching(true);

        api.post.search({
            term: term,
            wp: level === SearchLevels.recommendedResources,
            types: tags || []
        }).then(results => {
            const mappedResults: WordPressSearchResult[] = results?.map(r => {
                return {
                    type: 'wordpress',
                    content: {
                        html: r.matchedContent[0] || r.excerpt.replace(/<.*>([\s\S]*)<\/.*>/, '$1'),
                    },
                    title: {
                        text: htmlToText(r.title),
                        html: r.title
                    },
                    url: {
                        actual: r.link,
                        display: r.link,
                        title: r.link
                    },
                    date: new Date(r.modifiedGmt),
                    topics: r.topics,
                    resourceTypes: r.resourceTypes,
                    relevance: r.matchedContent.length
                }
            }) || [];
            setWordPressResults(mappedResults)

            setSearching(false);

        }, () => {
            setWordPressResults([]);
            setSearching(false);

        });

    }, [term, level, tags, searchControl]);

    return (
        <>
            {searching ?
                <div>
                    <div className="bg-purple p-3 text-white w-50 rounded shadow-sm">
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="pl-2">Loading...</span>
                    </div>
                    {Array.from({ length: 5 }).map((_x, i) =>
                        <Skeleton key={i} height="10em" width="100%" className="mb-4" />
                    )}

                </div> :
                <WordpressResults results={wordPressResults} />
            }
        </>
    );
}