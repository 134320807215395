import React, { PropsWithChildren, useState } from 'react';
import { ContributeButton } from '../__buttons/ContributeButton';
import { RequestResourceButton } from '../__buttons/RequestResourceButton';
import { FeaturedTopics } from '../FeaturedTopics/FeaturedTopics';

export interface HeaderProps {
    noFilter?: boolean;
    filterProps?: HeaderFilterProps;
    onFilter?: (filter: string) => void;
    noFeaturedTopics?: boolean;
    noButtons?: boolean;
    pageTitle?: JSX.Element;
    additionalContent?: JSX.Element;
}

export interface HeaderFilterProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> { }

export function SectionHeader(props: PropsWithChildren<HeaderProps>) {

    const [value, setValue] = useState((props.filterProps?.defaultValue?.toString()) || '')

    const onValueChange = (value: string, search: boolean, e?: React.ChangeEvent<HTMLInputElement>) => {
        setValue(value);
        if (search) {
            props.onFilter?.(value)
        }
        if (e) {
            props.filterProps?.onChange?.(e);
        }
    }

    return (
        <>
            <div id="anchor-top"></div>
            {props.pageTitle}
            <div className="mb-4">
                <div className="bg-white rounded shadow-sm p-3">
                    {props.noFeaturedTopics && props.noButtons ? null :
                        (
                            <div className="d-flex justify-content-md-between mb-2 filter-container">

                                <div className="feature-container">
                                    {props.noFeaturedTopics ? null : (
                                        <div className="container">
                                            <FeaturedTopics hideOnMobile={true} card={true} />
                                        </div>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between align-items-center cta-container">
                                    {props.noButtons ? null : (
                                        <>
                                            <ContributeButton />
                                            <RequestResourceButton />
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        {props.noFilter ? null : (
                            <form onSubmit={e => {
                                e.preventDefault();
                                props.onFilter?.(value)
                            }}
                                className="w-50 topic-filter-search my-3">
                                <div className="topic-filter form-group mb-0">
                                    <div className="input-group input-group-alternative">

                                        <input type="text" className="form-control" {...props.filterProps}
                                            value={value}
                                            onChange={e => onValueChange(e.target.value, false, e)}
                                        />
                                        {
                                            value ?
                                                <div className="input-group-append" >
                                                    <span className="input-group-text selectable"
                                                        onClick={() => onValueChange('', true)}>
                                                        <i className="fal fa-times"></i>
                                                    </span>
                                                </div>
                                                : null
                                        }

                                        <button aria-label="Apply search term to filter list" className="btn btn-outline-warning" data-pr-position="top" data-pr-tooltip="Click to narrow">Go</button>
                                    </div>
                                </div>

                            </form>
                        )}
                        {props.additionalContent}
                    </div>
                </div>
            </div >
        </>
    );
}