import { PropsWithChildren } from 'react';
import { SiteNotice } from '../_components/SiteNotice';

export interface PageProps {

}

export default function Page(props: PropsWithChildren<PageProps>) {

    return (
        <>
        <SiteNotice />
        <main>
            {props.children}
        </main>
        </>
    )
}