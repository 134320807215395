import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { SearchLevels } from '../../_models/search/search-levels';
import search from '../../_services/search';
import { useSearchParams } from '../../_services/search-params';

export function DropDownNav() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [level, setLevel] = useState('');
    const searchParams = useSearchParams();
    const router = useHistory();

    useEffect(() => {
        if (searchParams.level) {
            setLevel(searchParams.level);
        }
    }, [searchParams.level]);

    const levelChange = (newLevel: string) => {
        setLevel(newLevel);
        if (searchParams.term) {
            search(searchParams.term, newLevel, router, [])
        }
    }

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <>
            <span className="text-uppercase">Currently Viewing: </span>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>
                    {
                        level === SearchLevels.recommendedResources ?
                            'MDFL Library Catalog' :
                            'Synod and Partner Websites'
                    }
                </DropdownToggle>
                <DropdownMenu>
                    {level !== SearchLevels.recommendedResources ?
                        <DropdownItem onClick={() => levelChange(SearchLevels.recommendedResources)}>MDFL Library Catalog</DropdownItem>
                        : null}
                    {level !== SearchLevels.partnerResources ?
                        <DropdownItem onClick={() => levelChange(SearchLevels.partnerResources)}>Synod and Partner Websites</DropdownItem>
                        : null}
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

