import { PropsWithChildren, useState } from 'react';
import { useOnComponentMount } from '../../_services/use-on-component-mount';


export interface FormGroupModel {
    value: string;
    isValid: boolean;
    isDirty: boolean;
}

export interface FormGroupProps {
    label?: string;
    subLabel?: string;
    value: string;
    onChange: (value: FormGroupModel) => void;
    name: string;
    maxLength?: number;
    required?: boolean;
    showCharacterCount?: boolean;
    fieldName?: string;
    multiLine?: boolean;
    requireTouched?: boolean;
    validation?: (value: string) => boolean;
    validationMessage?: string;
    onBlur?: () => void;
    disabled?: boolean;
}

export function FormGroup(props: PropsWithChildren<FormGroupProps>) {

    const [touched, setTouched] = useState(false);
    const [cleanValue, setCleanValue] = useState<string | undefined>()

    const isValid = (newValue: string) => {
        if (props.required && !newValue) {
            return false;
        }

        if (props.maxLength && (newValue?.length > props.maxLength)) {
            return false;
        }

        if (newValue && props.validation && !props.validation(newValue)) {
            return false;
        }

        return true;
    }

    useOnComponentMount(() => {
        setCleanValue(props.value)
        onChange({
            value: props.value,
            isValid: isValid(props.value),
            isDirty: false
        })
    })

    const { value, onChange, name } = props;
    const fieldName = props.fieldName || props.label || 'Field';

    return (
        <div className="form-group">
            <label htmlFor={name}>{props.label} {props.required ? <i className="fas fa-asterisk req-marker"></i> : ''} {props.showCharacterCount ? <small className="text-muted">({value?.length || 0}{props.maxLength ? `/${props.maxLength}` : ''})</small> : null}
                {props.subLabel ?
                    <div>
                        <small>{props.subLabel}</small>
                    </div>
                    : null}
            </label>

            {props.multiLine ?
                <textarea className="form-control"
                    name={name}
                    onFocus={() => setTouched(true)}
                    value={value} onChange={e => onChange({
                        value: e.target.value,
                        isValid: isValid(e.target.value),
                        isDirty: e.target.value !== cleanValue
                    })}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                /> :
                <input type="text" className="form-control"
                    name={name}
                    onFocus={() => setTouched(true)}
                    value={value} onChange={e => onChange({
                        value: e.target.value,
                        isValid: isValid(e.target.value),
                        isDirty: e.target.value !== cleanValue
                    })}
                    onBlur={props.onBlur}
                    disabled={props.disabled}
                />
            }
            {props.maxLength && (value?.length > props.maxLength) ?
                <div><small className="input-sublabel">{fieldName} must be {props.maxLength} characters or less</small></div>
                : null}
            {props.required && !value && (touched || !props.requireTouched) ?
                <div><small className="input-sublabel">{fieldName} is required</small></div>
                : null}
            {value && props.validation && !props.validation(value) ?
                <div>
                    <small className="input-sublabel">
                        {props.validationMessage ? props.validationMessage : `${fieldName} is not valid`}
                    </small>
                </div>
                : null}
        </div>
    )
}