import { ApiCall, ParameterTypes } from '@lcms/api-definition'
import endpoints from './api-endpoint-definition.json';

export type ContributeResourceResponse = { success: boolean };

const definition = new ApiCall<ContributeResourceResponse>()
    .post('contributeResource', {
        route: endpoints.contactContributeResource,
        params: {
            name: {
                type: ParameterTypes.String
            },
            email: {
                type: ParameterTypes.String
            },
            description: {
                type: ParameterTypes.String
            },
            roleId: {
                type: ParameterTypes.String
            },
            role: {
                type: ParameterTypes.String
            },
            entityId: {
                type: ParameterTypes.String
            },
            entity: {
                type: ParameterTypes.String
            },
            links: {
                type: ParameterTypes.StringArray
            },
            hasFiles: {
                type: ParameterTypes.Boolean
            }
        }
    })
export default definition;