import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { getRecentSearches } from '../../_services/user-activity/search-history';
import { useSearchParams } from '../../_services/search-params';
import search from '../../_services/search';
import { SearchLevels } from '../../_models/search/search-levels';
import { Link, useHistory } from 'react-router-dom';
import { RecentActivityPageUrl } from '../../views/RecentActivity';


export function RecentSearchesModal(props: { onHide: () => void; }) {
    const params = useSearchParams();
    const [recentSearches] = useState(getRecentSearches());
    const router = useHistory();

    const header = (
        <span className="mr-4">
            <i className="fal fa-history mr-2"></i> Recent Searches
        </span>
    );

    const footer = (
        <div className="d-flex justify-content-between">
            <Link to={RecentActivityPageUrl} className="btn btn-link text-normal">View all of your recent activity</Link>
            <button aria-label="Close dialog" type="button" className="btn btn-white no-shadow" onClick={props.onHide}>Close</button>
        </div>
    );

    const searchRecentTerm = (term: string) => {
        search(term, params.level || SearchLevels.recommendedResources, router, []);
        props.onHide();
    };

    return (
        <Dialog blockScroll={true} onHide={props.onHide} draggable={false} visible={true} header={header} footer={footer} style={{ width: '37.5vw' }}>
            {recentSearches.map((t, i) => <div key={i}>
                <button aria-label="Search" type="button" className="btn btn-term-link"
                    onClick={() => searchRecentTerm(t)}
                >
                    {t}
                </button>
            </div>
            )}
        </Dialog>
    );
}
