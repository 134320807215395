import { useEffect, useState } from 'react';
import { useQueryString } from './query-string';

export const TermQuery = 's';
export const LevelQuery = 'l';
export const TagQuery = 't';
export const SearchControlQuery = 'ts';

export interface SearchParams {
    term?: string;
    level?: string;
    tags?: string[];
    searchControl?: string;
}

export function useSearchParams(): SearchParams {

    const [search, setSearch] = useState<SearchParams>({});
    const queryString = useQueryString();

    useEffect(() => {

        const newSearch = {
            term: queryString[TermQuery]?.single,
            level: queryString[LevelQuery]?.single,
            tags: queryString[TagQuery]?.array,
            searchControl: queryString[SearchControlQuery]?.single
        }

        if (newSearch.term !== search.term ||
            newSearch.level !== search.level ||
            newSearch.tags !== search.tags||
            newSearch.searchControl !== search.searchControl) {
            setSearch(newSearch);
        }

    }, [queryString, search])

    return search;
}
