import { api } from '../api/api';
import { FeaturedTopicsResponse } from '../api/featured-topics';
import { useApiResource } from '../use-api-resource';
import { ApiResource } from './api-resource';

const featuredTopics = new ApiResource<FeaturedTopicsResponse>(() => api.get.featuredTopics());

export function clearFeaturedTopics() {
    return featuredTopics.clearCache();
}

export async function getFeaturedTopics() {
    return await featuredTopics.get();
}

export function useFeaturedTopics() {
    return useApiResource(getFeaturedTopics);
}