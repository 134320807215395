import { api } from '../api/api';
import { RecentResourcesResponse } from '../api/recent-resources';
import { useApiResource } from '../use-api-resource';
import { ApiResource } from './api-resource';

const trendingResources = new ApiResource<RecentResourcesResponse>(() => api.get.recentlyAdded());

export async function getRecentlyAdded() {
    return await trendingResources.get();
}

export function useRecentResources() {
    return useApiResource(getRecentlyAdded)
}