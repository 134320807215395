import React from 'react';
import { useHistory } from 'react-router-dom';
import search from '../../_services/search';
import { SearchLevels } from '../../_models/search/search-levels';
import { AsyncResource } from '../AsyncResource/AsyncResource';
import { useTrendingSearches } from '../../_services/api-resources/trending-searches';

export function TrendingSearches(props: { filter: string; }) {

    const trendingSearches = useTrendingSearches();
    const router = useHistory();

    return (
        <div className="bg-white rounded shadow-sm p-3">
            <h4 className="page-heading"><i className="fal fa-chart-line px-2"></i> Trending Searches</h4>
            <hr />
            <AsyncResource resource={trendingSearches}
                errorText="Unable to retrieve trending searches"
                render={(resources) =>
                    <div>
                        {resources?.filter(r => !props.filter || r.toLocaleLowerCase().indexOf(props.filter.toLocaleLowerCase()) >= 0)
                            .map((s, i) =>
                                <button aria-label="Trending search view item" key={i} className="btn btn-md btn-primary-lighten rounded-pill mb-2"
                                    onClick={() => search(s, SearchLevels.recommendedResources, router, [])}
                                >
                                    <i className="mr-2 text-muted fal fa-chart-line"></i> {s}
                                </button>
                            )}
                    </div>
                } />
        </div>
    );
}
