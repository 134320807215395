import { PropsWithChildren, useCallback, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useApiResource } from '../../_services/use-api-resource';
import { AsyncResource } from '../AsyncResource/AsyncResource';
import { useOnComponentMount } from '../../_services/use-on-component-mount';
import { Skeleton } from 'primereact/skeleton';
import { LabelValue } from '../../_models/label-value';

export interface DropdownItem {
    label: string;
    value: string;
}

export interface DropdownFormGroupModel {
    selected?: DropdownItem;
    isValid: boolean;
    isDirty: boolean;
}

export interface DropdownFormGroupProps<T> {
    label?: string;
    subLabel?: string;
    value?: DropdownItem;
    onChange: (value: DropdownFormGroupModel) => void;
    name: string;
    required?: boolean;
    fieldName?: string;
    requireTouched?: boolean;
    validation?: (value: DropdownItem) => boolean;
    validationMessage?: string;
    load: () => Promise<T[] | null>,
    map: (item: T) => DropdownItem;
    editable?: boolean;
    maxLength?: number;
    filter?: boolean;
    valueTemplate?: (options: any, value: object) => any;
    itemTemplate?: (options: any) => any;
    showClear?: boolean;
    className?: string;
}

export function noMap(e: LabelValue): LabelValue {
    return e;
}
export function DropdownFormGroup<T>(props: PropsWithChildren<DropdownFormGroupProps<T>>) {

    const { load, map } = props;

    const [touched, setTouched] = useState(false);
    const [cleanValue, setCleanValue] = useState<string | undefined>();

    const loadCallback = useCallback(() => {
        return load().then(items => items?.map(map) || [])
    }, [load, map])

    const items = useApiResource(loadCallback);

    const isValid = (newValue?: DropdownItem) => {
        if (props.required && !newValue?.value) {
            return false;
        }

        if (newValue?.value && props.maxLength && (newValue.value.length > props.maxLength)) {
            return false;
        }

        if (newValue && props.validation && !props.validation(newValue)) {
            return false;
        }

        return true;
    }

    const { value, onChange, name } = props;

    useOnComponentMount(() => {
        setCleanValue(props.value?.value);
        onChange({
            selected: props.value,
            isValid: isValid(props.value),
            isDirty: false
        })
    })

    const fieldName = props.fieldName || props.label || 'Field';

    if (!items.loading && !items.resource?.length) {
        return null;
    }

    return (
        <div className="form-group">
            <label htmlFor={name}>{props.label} {props.required ? <i className="fas fa-asterisk req-marker"></i> : ''}
                {props.subLabel ?
                    <div>
                        <small>{props.subLabel}</small>
                    </div>
                    : null}
            </label>

            <div>
                <AsyncResource
                    resource={items}
                    render={values =>

                        <Dropdown value={props.value?.value}
                            showClear={props.showClear}
                            options={values}
                            name={name}
                            editable={props.editable} filter={props.filter}
                            placeholder={props.label ? `Select a ${props.label.toLowerCase()}` : undefined}
                            onFocus={() => setTouched(true)}
                            onChange={(e) => {
                                const selected = (!props.editable) ?
                                    values.filter(v => v.value === e.value)[0] :
                                    {
                                        label: e.value,
                                        value: e.value
                                    }
                                onChange({
                                    selected: selected,
                                    isValid: isValid(selected),
                                    isDirty: selected?.value !== cleanValue
                                })
                            }}
                            valueTemplate={props.valueTemplate}
                            itemTemplate={props.itemTemplate}
                            className={props.className}
                        />

                    }
                    loading={<>
                        <Skeleton width="7em" height="2.5em" />
                    </>}
                />
            </div>
            {props.maxLength && ((value?.value?.length || 0) > props.maxLength) ?
                <div><small className="input-sublabel">{fieldName} must be {props.maxLength} characters or less</small></div>
                : null}
            {props.required && !value?.value && (touched || !props.requireTouched) ?
                <div><small className="input-sublabel">{fieldName} is required</small></div>
                : null}
            {value && props.validation && !props.validation(value) ?
                <div>
                    <small className="input-sublabel">
                        {props.validationMessage ? props.validationMessage : `${fieldName} is not valid`}
                    </small>
                </div>
                : null}
        </div>
    )
}