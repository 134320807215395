import { useModal } from '../../_services/use-modal';
import { RequestResourceForm } from '../RequestResource/RequestResourceForm';

export function RequestResourceButton() {
    const { visible, showModal, hideModal } = useModal();

    return (
        <>
            <button aria-label="Open dialog for requesting a resource" className="btn cta-interact btn-sm" type="button"
            onClick={showModal}>
                <div className="d-flex justify-content-between align-items-center">
                    <span className="fa-stack">
                        <i className="fas fa-file fa-stack-2x text-primary"></i>
                        <i className="fas fa-comment-alt fa-stack-1x ml-0 mt-1 text-white"></i>
                    </span>
                    <div className="small">REQUEST</div>
                    <div className="text-left">
                        <div className="cta-title">
                            REQUEST
                    </div>
                        <div className="cta-subtitle">
                            Ask for a resource
                    </div>
                    </div>
                </div>
            </button>
            {visible ? <RequestResourceForm onHide={hideModal} /> : null}
        </>
    );
}
