import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import { useCallback } from 'react';
import { SortType } from '../../../_models/admin/sort-type';
import { api } from '../../../_services/api/api';
import { useApiResource } from '../../../_services/use-api-resource';
import { AsyncResource } from '../../AsyncResource/AsyncResource';
import { TrendingResource } from './TrendingResource';

export function TrendingResources(props: { filter: string; sort: SortType; visible?: boolean; reloadControl?: number; }) {

    const { sort, filter, visible, reloadControl } = props;

    const load = useCallback(() =>
        api.post.trendingResourcesAdmin({
            sort: sort,
            filter: filter,
            active: visible
        })
        , [sort, filter, visible, reloadControl])

    const resources = useApiResource(load);

    return (
        <>
            <AsyncResource
                resource={resources}
                errorText="Unable to load trending resources"
                render={(searches) => searches === null ?
                    <div>No Trending Resources to display</div>
                    : <div>
                        <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />

                        {searches.map(resource => (
                            <TrendingResource 
                                key={resource.id} 
                                resource={resource} 
                                reload={resources.reload}
                            />
                        ))}
                    </div>}
                loading={
                    <div>
                        <div className="bg-purple p-3 text-white w-50 rounded shadow-sm">
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            <span className="pl-2">Loading...</span>
                        </div>
                        {Array.from({ length: 10 }).map((r, i) => <Skeleton key={i} className="mb-2" width="100%" height="3em" borderRadius="5" />)}
                    </div>
                }
            />
        </>
    )
}

