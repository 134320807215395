import { SuggestedResource, SuggestedResourceFormModel } from '../../../../../_models/taxonomy/suggested-resource';

export function AddChildButton(props: { resource: SuggestedResource; showModal: (resource: SuggestedResourceFormModel) => void; }) {
    return (
        <button type="button" className='btn btn-sm btn-icon-only btn-warning rounded-circle' data-pr-tooltip="Add Child Topic"
            onClick={() => props.showModal({
                entity: props.resource.entity,
                role: props.resource.role,
                parent: props.resource,
                activeChildCount: props.resource.children.filter(c => c.active).length,
                canChangeParent: false
            })}
        >
            <i className="fas fa-plus"></i>
        </button>
    );
}
