import { EmptyResultSetContent, EmptyResultSetContentProps } from '../EmptyResultSet/EmptyResultSetContent';

export function EmptyResultSet(props: EmptyResultSetContentProps) {
    return (
        <div className="bg-white rounded shadow-sm shadow-dark pt-2 pb-4 pr-4 pl-4 mb-4 mt-4 suggested-topic no-results col-12">
            <h4 className="text-uppercase title mb-0">
                <i className="fad fa-empty-set mr-2 mt-2 fa-1x text-warning"></i> No Results Found
                </h4>
            <hr />
            <div className="d-flex justify-content-start align-items-top flex-wrap">
                <EmptyResultSetContent {...props} />
            </div>
        </div>
    );
}