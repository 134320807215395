import { PropsWithChildren, useEffect, useCallback, useState } from 'react';
import { EntityFilter } from '../../_models/taxonomy-filter/entity-filter';
import { TaxonomyFilter } from '../../_models/taxonomy-filter/taxonomy-filter';
import { SuggestedResource } from '../../_models/taxonomy/suggested-resource';
import { api } from '../../_services/api/api';
import { ErrorLog } from '../../_services/error-log';
import Topics from '../../_services/suggested-topics/topics';
import TaxonomyFilterContext, { TaxonomyFilterContextType } from '../../_services/taxonomy-filter-context';
import { saveTopicFilter } from '../../_services/user-activity/topic-filter-history';

export default function TaxonomyFilterProvider(props: PropsWithChildren<any>) {
    const [loadingFilterSchema, setLoadingFilterSchema] = useState(true);
    const [entityTree, setEntityTree] = useState<EntityFilter[]>([])

    const [loadingResources, setLoadingResources] = useState<boolean>(true);
    const [resources, setResources] = useState<SuggestedResource[] | null>(null);
    const [subResources, setSubResources] = useState<SuggestedResource[]>();

    const [filter, setFilter] = useState<TaxonomyFilter>({});

    const loadResources = useCallback(async (newFilter: TaxonomyFilter) => {
        if (!newFilter.entity || !newFilter.role) {
            setResources(null);
            return;
        }

        setLoadingResources(true);
        const search = {
            entity: newFilter.entity,
            role: newFilter.role,
            term: newFilter.term,
            featured: newFilter.featured
        }

        const entityResources = await Topics.get(search);
        setLoadingResources(false);
        setSubResources(undefined);

        if (!entityResources) {
            // Error
            setResources(null);
            return;
        }

        setResources(entityResources);
    }, [])

    useEffect(() => {
        api.get.topicFilter()
            .then((data) => {
                if (data) {
                    setEntityTree(data);

                    const defaultEntity = data.find(e => e.isDefault);
                    const defaultRole = defaultEntity?.roles.find(r => r.isDefault);
    
                    setFilter({
                        entity: defaultEntity?.value,
                        role: defaultRole?.value
                    })
                }
                setLoadingFilterSchema(false);
                
            }, (error) => {
                ErrorLog.log(error);
                setLoadingFilterSchema(false);
            })

    }, [setEntityTree, setLoadingFilterSchema, setFilter])

    useEffect(() => {
        loadResources(filter);
    }, [loadResources, filter])

    const context: TaxonomyFilterContextType = {
        filter: filter,
        setFilter: (filter: TaxonomyFilter) => {
            if (filter.entity) {
                const validRoles = context.filterSchema.roles(filter.entity) || [];
                if (filter.role) {
                    if (validRoles.map(r => r.value).indexOf(filter.role) < 0) {
                        filter.role = undefined;
                    }
                }
                if (!filter.role) {
                    filter.role = validRoles.filter(role => role.isDefault)[0]?.value
                }
            } else {
                filter.role = undefined;
            }

            if (filter.term) {
                const names = context.getFilterNames();
                saveTopicFilter(filter.term,
                    filter.entity && names.entity ? {
                        id: filter.entity,
                        name: names.entity
                    } : undefined,
                    filter.role && names.role ? {
                        id: filter.role,
                        name: names.role
                    } : undefined)
            }
            setFilter(filter);
        },
        getFilterNames: () => {
            const filter: TaxonomyFilter = {
                role: undefined,
                entity: undefined
            }

            if (context.filter.entity) {
                const entities = context.filterSchema.entities.filter(entity => entity.value === context.filter.entity)[0];

                filter.entity = entities?.label;
                if (context.filter.role) {
                    filter.role = entities?.roles.filter(role => role.value === context.filter.role)[0]?.label;
                }
            }

            return filter;
        },
        suggested: {
            loading: loadingResources,
            resources: resources,
            subResource: subResources,
            viewSubResources: (topic?: SuggestedResource) => {
                setSubResources(topic?.children);
            }
        },
        filterSchema: {
            loading: loadingFilterSchema,
            entities: entityTree,
            roles: (entity: string) => entityTree.filter(e => e.value === entity)[0]?.roles,
        }
    };

    return (
        <TaxonomyFilterContext.Provider value={context}>
            {props.children}
        </TaxonomyFilterContext.Provider>
    )
}