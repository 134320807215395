import { Skeleton } from 'primereact/skeleton';
import { PropsWithChildren } from 'react';
import { useFeaturedTopics } from '../../_services/api-resources/featured-topics';
import { useTaxonomyFilter } from '../../_services/taxonomy-filter-context';
import { AsyncResource } from '../AsyncResource/AsyncResource';
import { FeaturedTopic } from './FeaturedTopic';
import { RecentlyAdded } from './RecentlyAdded';

export interface FeaturedTopicsProps {
    card?: boolean;
    hideOnMobile?: boolean;
}

export function FeaturedTopics(props: PropsWithChildren<FeaturedTopicsProps>) {

    const featuredTopics = useFeaturedTopics();
    const { setFilter, filter } = useTaxonomyFilter();


    const isSelected = (topic: string) => {
        return ((filter.featured || []).indexOf(topic)) >= 0;
    }

    const addTopic = (topic: string) => {

        if (!filter.featured?.length) {
            setFilter({
                entity: 'all',
                role: 'all',
                featured: [topic]
            })
            return;
        }

        if (!isSelected(topic)) {
            setFilter({
                entity: filter.entity,
                role: filter.role,
                term: filter.term,
                featured: [...(filter.featured || [])].concat([topic])
            })
        }
    }

    const removeTopic = (topic: string) => {
        setFilter({
            entity: filter.entity,
            role: filter.role,
            term: filter.term,
            featured: filter.featured?.filter(t => t !== topic)
        })
    }

    return (
        <div className="row featured-pills">
            <AsyncResource resource={featuredTopics}
                errorText="Unable to retrieve featured topics"
                render={(resources) =>
                    <>

                        {resources?.map((topic, i) =>
                            <FeaturedTopic key={i} topic={{
                                title: topic,
                                selected: isSelected(topic)
                            }}
                                hideOnMobile={props.hideOnMobile}
                                addTopic={addTopic}
                                removeTopic={removeTopic}
                            />
                        )}
                        <RecentlyAdded hideOnMobile={props.hideOnMobile} />
                    </>
                }
                loading={<>
                    <div>
                        <Skeleton borderRadius="50rem" height={props.hideOnMobile ? '1.7rem' : '2.2rem'} width="6rem" />
                    </div>
                </>}
            />

        </div>
    )
}

