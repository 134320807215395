import { User } from '@lcms/identity';
import { ApiWithCredentials } from './api-with-credentials';
import { SSOVendor } from '@lcms/identity-api';

export class UserService {

    static async whoAmI(ssoCallback?: (sso: SSOVendor) => void | Promise<void>): Promise<User | null> {
        const data = await ApiWithCredentials.get<User>('/api/auth/me');
        if (!data.error && data.authorized && data.payload) {
            return data.payload
        }

        if (ssoCallback && !data.authorized && data.sso) {
            await ssoCallback(data.sso);
        }
        return null;
    }

    static async logout(): Promise<boolean> {
        const data = await ApiWithCredentials.get<User>('/api/auth/logout');
        if (!data.error && data.authorized) {
            return true;
        }

        return false;
    }
}