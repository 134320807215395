import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import endpoints from './api-endpoint-definition.json';

export type RequestResourceResponse = { success: boolean };

const definition = new ApiCall<RequestResourceResponse>().post('requestResource', {
    route: endpoints.contactRequestResource,
    params: {
        name: {
            type: ParameterTypes.String
        },
        email: {
            type: ParameterTypes.String
        },
        request: {
            type: ParameterTypes.String
        }
    }
})
export default definition;