import { useEffect } from 'react';
import { ErrorLog } from '../_services/error-log';
import { useScript } from '../_services/scripts';


export function NoScript(props: { code: string; }) {

    useEffect(() => {
        const script = document.createElement('noscript');
        script.innerHTML = props.code;
        document.body.appendChild(script);

        return () => {
            try {
                document.body.removeChild(script);
            } catch (error: any) {
                ErrorLog.log('Error cleaning up script:', error);
            }
        }
    }, [props.code]);

    return null;
}

export function Script(props: { type: string; code: string; }) {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = props.type;
        script.innerHTML = props.code;
        document.body.appendChild(script);

        return () => {
            try {
                document.body.removeChild(script);
            } catch (error: any) {
                ErrorLog.log('Error cleaning up script:', error);
            }
        }
    }, [props.type, props.code]);

    return null;
}

export function AsyncScript(props: { url: string, defer?: boolean, async?: boolean }) {
    useScript(props.url, {
        defer: typeof props.defer === 'undefined' ? false : props.defer,
        isAsync: typeof props.async === 'undefined' ? true : props.async
    })
    return null;
}