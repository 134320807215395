import { useState } from 'react';
import { SidebarSections } from './SidebarSections';
import { Sidebar as PrimeSidebar } from 'primereact/sidebar';
import { Link } from 'react-router-dom';
import { SuggestedTopicsPageUrl } from '../../views/Landing';

let menuClicked = false;

export interface SidebarResponsiveProps {
    pageTitle: string;
    prependOptions?: JSX.Element;
    appendOptions?: JSX.Element;
    showBack?: boolean;
    hasSearch?: boolean;
    prependMenuItems?: JSX.Element;
    appendMenuItems?: JSX.Element;
}

export function SidebarResponsive(props: SidebarResponsiveProps) {

    const showBack = typeof (props.showBack) === 'undefined' ? true : false;
    const hasSearch = typeof (props.hasSearch) === 'undefined' ? true : false;

    const [visibleMenu, setVisibleMenu] = useState(false);

    return (
        <>
            <div className="col-sm-2 sb-mini">
                <div className="sticky-top">
                    <ul role="tablist" className="nav-pills-circle nav nav-pills ml-1 mr-1">
                        {props.prependOptions}
                        <li className="nav-item mb-2">
                            <div className={'rounded-circle text-primary cursor-pointer nav-link menu-btn' + (!menuClicked ? ' untouched' : ' no-shadow')} onClick={() => { setVisibleMenu(true); menuClicked = true; }}>
                                <span className="nav-link-icon d-block">
                                    <i className="fas fa-bars"></i>
                                </span>
                            </div>
                            <small  className="pt-2">Menu</small>
                        </li>
                        {showBack ?
                            <>
                                <li className="nav-item my-1">
                                    <Link to={SuggestedTopicsPageUrl}>
                                        <div className="cursor-pointer nav-link shadow-none">
                                            <span className="nav-link-icon d-block">
                                                <i className="fad fa-arrow-left"></i>
                                            </span>
                                        </div>
                                    </Link>
                                    <small  className="pt-2">Back</small>
                                </li>
                            </> : null}

                        {hasSearch ?
                            <>
                                <li className="nav-item">
                                    <div onClick={() => { document.getElementById('search-input')?.focus(); document.getElementById('search-input')?.click(); document.getElementById('anchor-search')?.scrollIntoView() }} className="cursor-pointer nav-link shadow-none">
                                        <span className="nav-link-icon d-block">
                                            <i className="fad fa-search"></i>
                                        </span>
                                    </div>
                                    <small  className="pt-2">Search</small>
                                </li>
                            </> : null}

                        {props.appendOptions}

                        <li className="nav-item">
                            <div onClick={() => { document.getElementById('anchor-top')?.scrollIntoView() }} className="cursor-pointer nav-link shadow-none">
                                <span className="nav-link-icon d-block">
                                    <i className="fad fa-arrow-to-top"></i>
                                </span>
                            </div>
                            <small  className="pt-2">Top</small>
                        </li>

                    </ul>
                </div>
            </div>

            {/* Menu Sidebar */}
            <PrimeSidebar baseZIndex={999999} showCloseIcon={false} blockScroll={true} visible={visibleMenu} onHide={() => setVisibleMenu(false)}>
                <div className="sb-header sticky-top">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="text-uppercase mb-0 pt-1">Menu</h6>
                        <button aria-label="Close Menu" type="button" className="btn btn-white text-dark border-dark" onClick={() => setVisibleMenu(false)}> <i className="mr-2 fas fa-times"></i> CLOSE</button>
                    </div>
                </div>
                <div className="mt-5 py-2 px-3">
                    {props.prependMenuItems}
                    <SidebarSections />
                    {props.appendMenuItems}
                </div>
            </PrimeSidebar>
        </>
    )
}
