import React, { useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { clearAdminTaxonomy } from '../../../../_services/api-resources/admin-taxonomy';
import { api } from '../../../../_services/api/api';
import { useAdminTaxonomy } from '../use-admin-taxonomy';
import { Toast } from 'primereact/toast';
import { confirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog';
import { DialogProps } from 'primereact/dialog';
import { confirmPopup } from 'primereact/confirmpopup';

interface MoveTopicsButtonProps {
    fromId: string;
    onMove: () => void;
    toast: React.RefObject<Toast>;
    hasChanges: boolean;
    disabled?: boolean;
    saveChanges: () => Promise<boolean>;
}

export function MoveTopicsButton(props: MoveTopicsButtonProps) {
    const [moving, setMoving] = useState(false);

    const panel = useRef<OverlayPanel>(null);
    const filter = useAdminTaxonomy();

    const move = (toId: string) => confirmDialog({
        message: (
            <dl>
                <dt className="mt-3">Are you sure you want to move all topics from this entity/role to another?</dt>
            </dl>
        ),
        header: 'Move Topics',
        icon: 'fal fa-question-circle text-primary',
        accept: () => {
            panel.current?.hide();
            setMoving(true);
            api.post.moveTopics({
                fromId: props.fromId,
                toId: toId
            }).then(results => {
                if (results?.success) {
                    clearAdminTaxonomy();
                    props.onMove();
                } else {
                    props.toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Unable to move topics',
                    })
                }
                setMoving(false);
            });
        },
        baseZIndex: 1,
        blockScroll: true,
        draggable: false
    } as ConfirmDialogProps & DialogProps);

    const save = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, toId: string) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'If you proceed your changes will be saved.  Proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                if (await props.saveChanges()) {
                    await move(toId);
                }
            }
        });
    }

    const onMoveClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, toId: string) => {
        if (props.hasChanges) {
            save(event, toId);
        } else {
            move(toId);
        }
    };


    if (!props.fromId) {
        return null;
    }

    return <>
        <button className="btn btn-outline-white text-primary"
            type="button"
            onClick={(e) => panel.current?.toggle(e)}
            disabled={props.disabled || moving}
        >
            {moving ?
                <i className="fad fa-spin fa-spinner"></i>
                : <><i className="far fa-folder-tree mr-2"></i> Move</>}
        </button>
        <OverlayPanel ref={panel} showCloseIcon dismissable>
            <h6 className="mb-1 border-bottom pb-2">Select the entity and role you'd like to move the topics to </h6>
            <div className="topic-move-list">
            {filter.state === 'done' ?
                filter.data.entities.map(e => e.roles.map(r => {
                    return {
                        ...r,
                        entity: e
                    };
                })).reduce((a, b) => a.concat(b), [])
                    .map(er => <>
                        <button className="btn btn-sm btn-link"
                            type="button" onClick={(e) => onMoveClick(e, er.id)}
                        >
                            <span className={er.entity.isActive ? '' : 'line-through'}>{er.entity.name} </span>
                                /
                                <span className={er.isActive ? '' : 'line-through'}> {er.role.name} </span>
                        </button>
                        <br />
                    </>)
                : null}
            </div>
            
        </OverlayPanel>

    </>;
}
