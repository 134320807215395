import { PropsWithChildren, useRef } from 'react';
import { useEffect, useState } from 'react';
import { useOutsideClick } from '../../_services/clicks';
import { getRecentSearches, removeSearch } from '../../_services/user-activity/search-history';
import { useSearchParams } from '../../_services/search-params';
import { AutoComplete } from '../AutoComplete/AutoComplete';
import { AutoCompleteTerm } from '../AutoComplete/AutoCompleteTerm';
import { getTrendingSearches } from '../../_services/api-resources/trending-searches';
import { ErrorLog } from '../../_services/error-log';

export interface SearchBarProps {
    search: (term: string) => void;
    searchOnComplete?: boolean;
}

export default function SearchBar(props: PropsWithChildren<SearchBarProps>) {
    const [term, setTerm] = useState<string>('');
    const [hasFocus, setHasFocus] = useState<boolean>(false);

    const [prevousSearches, setPreviousSearches] = useState(getRecentSearches());
    const [trendingSearches, setTrendingSearches] = useState<string[]>([]);

    const searchParams = useSearchParams();

    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideClick(wrapperRef, () => setHasFocus(false));

    useEffect(() => {
        if (searchParams.term) {
            setTerm(searchParams.term);
        }
    }, [searchParams.term])

    useEffect(() => {
        getTrendingSearches()
            .then((data) => {
                setTrendingSearches(data || []);
            }, (error) => {
                ErrorLog.log(error);
                setTrendingSearches([]);
            })
    }, [setTrendingSearches]);

    const onTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTerm(event.target.value);
    }

    const history: AutoCompleteTerm[] =
        prevousSearches.map((t, i) => {
            return {
                id: i,
                term: t,
                canRemove: true,
                isTrending: false
            }
        }).concat(trendingSearches.map(t => {
            return {
                id: -1,
                term: t,
                canRemove: false,
                isTrending: true
            }
        }));

    const search = async () => {
        if (!term) {
            return;
        }

        props.search(term);
    }

    const onAutoComplete = (term: string) => {
        setTerm(term);
        setHasFocus(false)
        if (props.searchOnComplete) {
            props.search(term);
        }
    }


    return (
        <form onSubmit={e=>{
            e.preventDefault();
            search();
            setHasFocus(false);
        }}>
            <div ref={wrapperRef} className={'top-search form-group ' + (term ? 'has-term' : '')}>
                <div className="input-group input-group-alternative">
                    <input autoComplete="off" className="form-control" id="search-input" type="text" value={term} onChange={onTermChange} placeholder="Know what you're looking for..." onFocus={(e) => { setHasFocus(true); e.target.placeholder = 'Search...' }} onBlur={(e) => e.target.placeholder = 'Know what you are looking for...'} />
                    {term ?
                        <div className="input-group-append" >
                            <span className="input-group-text selectable" onClick={() => setTerm('')}><i className="fal fa-times"></i></span>
                        </div>
                        : null}

                    <button aria-label="Search" disabled={!term} className="btn btn-warning"><i className="fa fa-search"></i></button>
                </div>
                <AutoComplete show={hasFocus} terms={history} filter={term}
                    onSelect={onAutoComplete}
                    remove={id => {
                        removeSearch(id);
                        setPreviousSearches(getRecentSearches());
                    }} />
            </div>
        </form>
    )
}