import React from 'react';
import ReactDOM from 'react-dom';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import App from './App';

import './index.scss';

import { compatability, standardCompatability } from '@lcms/supported-browsers'
import NotSupported from './NotSupported';
const isSupported = compatability(standardCompatability);

ReactDOM.render(
  <React.StrictMode>
    {isSupported ? <App /> : <NotSupported />}
  </React.StrictMode>,
  document.getElementById('root')
);

