import * as Msal from 'msal';
import { ApiWithCredentials } from './api-with-credentials';
import { User } from '@lcms/identity';
import { MicrosoftLoginService, LCMSTennantConfiguration } from '@lcms/microsoft-graph-client'
import { Observable } from '../observable';

// eslint-disable-next-line no-restricted-globals
const host = location.protocol + '//' + location.host;
const msalConfig: Msal.Configuration = {
  ...LCMSTennantConfiguration,
  auth: {
    clientId: '60353ce1-8f44-44d2-92ae-f6a60c94b01e',
    redirectUri: host,
    postLogoutRedirectUri: host + '/logout',
    ...LCMSTennantConfiguration.auth
  },
};

export class AdminAuthorizationService {
  private loginService: MicrosoftLoginService;

  public user = new Observable<User | null>(null);
  public loggingIn = new Observable<boolean>(false);

  constructor() {
    this.loginService = new MicrosoftLoginService(msalConfig);
    this.loginService.registerCallbackToken(async token => {
      if (token) {
        this.loggingIn.next(true);
        const user = await this.saveToken(token);
        this.user.next(user);
        this.loggingIn.next(false);
      }
    })
  }

  async login(email: string): Promise<User | null> {
    return await this.loginService.login(email)
      .then(async token => {
        if (token) {
          const user = await this.saveToken(token);
          return user;
        }
        return null;
      }).catch(err => {
        return null;
      });
  }

  logout(): boolean {
    return this.loginService.logout();
  }

  private async saveToken(token: string): Promise<User | null> {
    const result = await ApiWithCredentials.post<User>('/api/auth/login/azure', {
      token
    });
    if (result.error || !result.authorized) {
      return null;
    }
    return result?.payload || null;
  }
}

export const adminAuthorization = new AdminAuthorizationService();