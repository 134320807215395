import { saveResource } from '../../_services/user-activity/resource-history';
import { SearchResult as SearchResultModel } from '../../_models/search/search-result';
import { PropsWithChildren, useState } from 'react';
import { HtmlSpan } from '../HtmlSpan';
import { Tooltip } from 'primereact/tooltip';
import { ResourcesDialog } from '../Resources/ResourcesDialog';
import { compare } from '@lcms/helpers';
import { api } from '../../_services/api/api';

export function SearchResult(props: PropsWithChildren<{ result: SearchResultModel; }>) {
    const { result } = props;

    const [showResources, setShowResources] = useState<string>('');

    const link = result.url.display;
    const title = result.title.text;

    const onClick = async () => {
        saveResource(title, link);
        await api.post.trackResource({
            link,
            title
        });
    };

    return (
        <div className="s-result bg-white mb-4 rounded shadow-sm px-4 py-3">

            <div>
                <a href={result.url.actual} onClick={onClick} target='_blank' rel="noreferrer">
                    <div className="d-flex justify-content-between">
                        <div className="s-heading">
                            {result.title.html ?
                                <HtmlSpan html={result.title.html} /> :
                                <span>{result.title.text}</span>
                            }
                        </div>
                        {result.type === 'wordpress' ?
                            <div className="text-date">{result.date?.toLocaleDateString()}</div>
                            : null}
                    </div>


                    <div>
                        <div className="s-snippet">
                            {result.content.html ?
                                <HtmlSpan html={result.content.html} /> :
                                <span>{result.content.text}</span>
                            }
                        </div>
                        <div className="s-url">
                            <div className="d-flex justify-content-between align-items-top flex-wrap">
                            {result.url.title}
                            {result.type === 'google' ?
                                <small className="text-muted clip-url">{result.url.display}</small> : null}
                            </div>
                       </div>
                    </div>
                </a>
                {result.type === 'wordpress' && result.topics?.length ?
                    <div className="d-flex justify-content-between align-items-center mt-3 pt-2 border-top">
                        <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                        <div className="topic-tags">
                            <i className="fad fa-copy mr-2 text-primary" data-pr-tooltip="View Similar Resources"></i>
                            {result.topics
                                .sort(compare(a => a.value.length))
                                .map((r, i) =>
                                    <span key={i} className="btn-similar text-primary cursor-pointer px-2 mr-1" onClick={() => setShowResources(r.value)}>{r.label}</span>
                                )}
                        </div>
                    </div>
                    :
                    null}
                {result.type === 'wordpress' && result.resourceTypes?.length ?
                
                    <div className="d-flex justify-content-between align-items-center mt-3 pt-2 border-top">
                        <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                        <div className="topic-tags">
                            <i className="fal fa-tags mr-2" data-pr-tooltip="Resource types applied to this result"></i>
                            {result.resourceTypes?.map(t=>t.label).join(', ')}
                        </div>
                    </div>
                    :
                    null}
            </div>
            {result.type === 'google' ?
                <div className="mt-2 p-2 ext-link d-flex justify-content-between align-items-center">
                    <div><i className="far fa-link pr-1"></i> This result will open in a new tab to an external site</div>
                    <a className="btn btn-sm btn-text text-warning"
                        href={`mailto:mdfl@lcms.org?body=You are reporting an issue with the following external resource ${result.url.display}.%0D%0A%0D%0APlease provide more detail below to allow us to contact the owner of the external resource and resolve the issue.%0D%0A----Example reasons... the resource link is broken, the resource appears compromised or suspicious.----&subject=MDFL Issue with External Resource`}
                    ><small><i className="fal fa-comment-exclamation fa-flip-horizontal fa-2x mr-2"></i>Report an Issue</small></a>
                </div>
                : null}
            {result.type === 'wordpress' && result.topics?.length && showResources ? <ResourcesDialog onHide={() => setShowResources('')} resource={{
                children: [],
                entity: {
                    id: '',
                    name: ''
                },
                featured: '',
                id: '',
                role: {
                    id: '',
                    name: ''
                },
                title: result.title.text,
                wordpressTopicId: showResources,
                active: true
            }} /> : null}
        </div>
    );
}
