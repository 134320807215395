import endpoints from './api-endpoint-definition.json';
import { WordPressResult } from '../../_models/search/wordpress-result';
import { ApiCall, ParameterTypes } from '@lcms/api-definition';

export type SearchResponse = WordPressResult[] | null;

const definition = new ApiCall<SearchResponse>().post('search', {
    route: endpoints.search,
    params: {
        term: {
            type: ParameterTypes.String
        },
        types: {
            type: ParameterTypes.StringArray
        },
        wp: {
            type: ParameterTypes.Boolean
        }
    }
})
export default definition;