import React, { useState } from 'react';
import { SortType } from '../../../_models/admin/sort-type';
import { Header } from './Header';
import { TrendingSearches } from './TrendingSearches';
import { Toast } from 'primereact/toast';

export function TrendingSearchesPanel(props: { toast: React.RefObject<Toast> }) {
    const [filter, setFilter] = useState('');
    const [reloadControl, setReloadControl] = useState<number | undefined>();
    const [sort, setSort] = useState<SortType>('UsageDesc');
    const [visibleFilter, setVisibleFilter] = useState<boolean | undefined>(undefined);
    return (
        <>
            <Header onFilter={setFilter} sort={sort} onSortChange={setSort}
                visibleFilter={visibleFilter}
                visibleFilterChange={setVisibleFilter}
                onSave={() => setReloadControl(new Date().valueOf())}
                toast={props.toast}
            />
            <TrendingSearches filter={filter} sort={sort} visible={visibleFilter}
                reloadControl={reloadControl}
            />
        </>
    );
}
