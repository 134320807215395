import React, { useEffect, useRef, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Link, useHistory } from 'react-router-dom';
import { queryString, useQueryString } from '../../_services/query-string';
import { useAuthenticatedUser } from '../../_services/user-context';
import { SuggestedTopics } from './SuggestedTopics/SuggestedTopics';
import { TrendingResourcesPanel } from './TrendingResources/TrendingResourcesPanel';
import { TrendingSearchesPanel } from './TrendingSearches/TrendingSearchesPanel';
import { TrendingTopicPanel } from './TrendingTopicFilters/TrendingTopicPanel';
import { Toast } from 'primereact/toast';
import { Taxonomy } from './Taxonomy/Taxonomy';
import { ClearWordPressCacheButton } from './ClearWordpressCacheButton';
import { LogoutPageUrl } from '../../views/Logout';
// import './admin.scss';
export function ReviewTrends() {

    const [activeIndex, setActiveIndex] = useState(0);
    const queryParams = useQueryString();
    const router = useHistory();
    const toast = useRef<Toast>(null);


    useEffect(() => {
        if (queryParams.tab?.single === 'topics') {
            setActiveIndex(0);
        } else if (queryParams.tab?.single === 'searches') {
            setActiveIndex(1);
        } else if (queryParams.tab?.single === 'resources') {
            setActiveIndex(2);
        } else if (queryParams.tab?.single === 'filters') {
            setActiveIndex(3);
        } else if (queryParams.tab?.single === 'stats') {
            setActiveIndex(4);
        }

    }, [queryParams])

    const tabChange = (index: number) => {
        let tab = 'topics';
        if (index === 1) {
            tab = 'searches';
        } else if (index === 2) {
            tab = 'resources';
        } else if (index === 3) {
            tab = 'filters';
        } else if (index === 4) {
            tab = 'stats';
        }

        router.push({
            search: queryString({
                tab: tab
            })
        })
    }

    const userContext = useAuthenticatedUser();

    if (!userContext.user) {
        return null;
    }


    return (

        <div className="bg-white rounded shadow-sm mb-3">
            <div className="float-right p-tabview p-component mt-1">
                <Link to={LogoutPageUrl} aria-label="Logout" className="btn btn-danger-lighten"><i className="fas fa-sign-out mr-2"></i> Logout</Link>
            </div>
            <Toast ref={toast} />
            <TabView activeIndex={activeIndex} onTabChange={(e) => tabChange(e.index)}>
                <TabPanel header="Manage Topics">
                    <div className="d-flex justify-content-between w-100">
                        <Taxonomy toast={toast} />
                        <ClearWordPressCacheButton toast={toast} />
                    </div>
                    <SuggestedTopics toast={toast} />
                </TabPanel>
                <TabPanel header="Trending Searches">
                    <TrendingSearchesPanel toast={toast} />
                </TabPanel>
                <TabPanel header="Trending Resources">
                    <TrendingResourcesPanel toast={toast} />
                </TabPanel>
                <TabPanel header="Trending Topics">
                    <TrendingTopicPanel />
                </TabPanel>
                <TabPanel header="Analytics">
                    <dl>
                        <dt>
                            <a href="https://analytics.google.com/analytics/web/#/" rel="noreferrer" target="_blank"> Google Analytics 
                                <i className="ml-2 far fa-external-link-alt"></i>
                            </a>
                        </dt>
                        <dd>
                        Google Analytics requires a Google Verified Account, contact the site administrator to help set your account up.
                        </dd>
                        <dt>
                            <a href="https://clarity.microsoft.com/projects" rel="noreferrer" target="_blank"> Microsoft Clarity 
                                <i className="ml-2 far fa-external-link-alt"></i>
                            </a>
                        </dt>
                        <dd>
                        Contact the site administrator to help set your account up.
                        </dd>
                    </dl>
                </TabPanel>
            </TabView>

        </div>


    );
}