import { useCallback, useState } from 'react';
import { clearFeaturedTopics } from '../../../../../_services/api-resources/featured-topics';
import { clearAdminSuggestedTopics } from '../../../../../_services/api-resources/suggested-topics';
import { api } from '../../../../../_services/api/api';


export function RestoreButton(props: { id: string; onRestore: () => void; }) {

    const [restoring, setRestoring] = useState(false);

    const { id, onRestore } = props;
    const onClick = useCallback(() => {
        setRestoring(true);
        api.post.restoreSuggestedTopic({
            id: id
        }).then(r => {
            if (r?.success) {
                clearAdminSuggestedTopics();
                clearFeaturedTopics();
                onRestore();
            }
            setRestoring(false);
        });
    }, [id, onRestore]);

    return <button type="button" className='btn btn-sm btn-icon-only'
        data-pr-tooltip="Restore Topic"
        onClick={onClick} disabled={restoring}
    >
        {restoring ? <i className="fad fa-spin fa-spinner"></i> : <i className="fad fa-trash-restore text-primary fa-2x"></i>}
    </button>;
}
