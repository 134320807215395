import endpoints from '../api-endpoint-definition.json';
import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { ApiResponse } from './api-response';

const definition = new ApiCall<ApiResponse>().post('saveEntityRole', {
    route: endpoints.adminTaxonomyRoleSave,
    params: {
        roleId: {
            type: ParameterTypes.String,
            optional: true
        },
        entityId: {
            type: ParameterTypes.String
        },
        name: {
            type: ParameterTypes.String
        },
        isDefault: {
            type: ParameterTypes.Boolean
        }
    }
})
export default definition;

