import ReactDOM from 'react-dom';
import { useScript } from '../../../_services/scripts';
import { GoogleResult } from '../../../_models/search/google-result';
import { GoogleResults } from './GoogleResults';
import { GoogleCustomSearchService } from '../../../_services/google-custom-search-service';
import { SearchResult } from '../../../_models/search/search-result';
import { parseQueryString } from '../../../_services/query-string';

const initCallbacks = {
    init: () => GoogleCustomSearchService.$engineReady.next(false),
    cleanup: () => GoogleCustomSearchService.$engineReady.next(false),
}

export function GoogleCustomSearchPlugin() {
    const engineReady = function () {
        GoogleCustomSearchService.$engineReady.next(true);
    }

    const renderResults = function (name: string, q: string, promos: any[], results: GoogleResult[], resultsDiv: HTMLElement) {
        const mappedResults: SearchResult[] = results.filter(r => r.url).map(r => {
            return {
                type: 'google',
                content: {
                    html: r.content,
                    text: r.contentNoFormatting
                },
                url: {
                    actual: r.url,
                    title: r.visibleUrl,
                    display: parseQueryString(r.url.split('?')[1] || '').q?.single
                },
                title: {
                    html: r.title,
                    text: r.titleNoFormatting
                },
                thumbnailImage: r.thumbnailImage ? {
                    height: r.thumbnailImage.height,
                    width: r.thumbnailImage.width,
                    url: r.thumbnailImage.url
                } : undefined,
                date: r.richSnippet?.metatags?.lastModified ? new Date(r.richSnippet?.metatags?.lastModified) : undefined
            }
        })

        const resultSet = <GoogleResults results={mappedResults} />;
        ReactDOM.render(resultSet, resultsDiv);
        return true;
    };

    const gcse = ((window as any).__gcse || ((window as any).__gcse = {}));
    gcse.initializationCallback = engineReady;
    gcse.searchCallbacks = {
        web: {
            ready: renderResults,
            rendered: () => {
                GoogleCustomSearchService.searchComplete();
            }
        },
    };

    useScript('https://cse.google.com/cse.js?cx=8c864ed9fcb10417b', {
        defer: true,
        isAsync: true
    }, initCallbacks);

    return <div style={{ display: 'none' }}>Google Custom Search Script</div>;
}
