import { History } from 'history';
import { SearchPageUrl } from '../views/Search';
import { Dictionary } from '@lcms/helpers';
import { queryString } from './query-string';
import { saveSearch } from './user-activity/search-history';
import { LevelQuery, SearchControlQuery, TagQuery, TermQuery } from './search-params';

export default function search(term: string, level: string, router: History<unknown>, tags: string[]) {
    if (!term) {
        return;
    }

    saveSearch(term);

    const params: Dictionary<string | string[]> = {};
    params[TermQuery] = term;
    params[LevelQuery] = level;
    params[TagQuery] = tags;
    params[SearchControlQuery] = new Date().valueOf().toFixed(0);
    router.push(`${SearchPageUrl}${queryString(params)}`);
}