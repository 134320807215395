import { HeaderButtons } from '../Header/HeaderButtons';
import { DropDownNav } from './DropDownNav';


export function SubHeader() {

    return (
        <div className="bg-white p-2">
            <div className="d-flex justify-content-between align-items-center p-2">
                <div>
                    <DropDownNav />                    
                </div>
                <div>
                    <HeaderButtons />
                </div>
            </div>
        </div>
    )
}