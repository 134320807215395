import React from 'react';
import { SectionHeader } from '../Header/SectionHeader';

export function Header(props: { onFilter: (text: string) => void; }) {

    return (
        <>
            <SectionHeader filterProps={{
                placeholder: 'Narrow trending searches...'
            }} onFilter={props.onFilter} />
        </>
    );
}