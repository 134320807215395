import { confirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog';
import { DialogProps } from 'primereact/dialog';

export function CancelButton(props: { onAccept?: () => void; onReject?: () => void; autoAccept?: boolean; autoReject?: boolean; }) {
    const onCancelClick = () => {

        if (props.autoAccept) {
            props.onAccept?.();
        } else if (props.autoReject) {
            props.onReject?.();
        } else {
            confirmDialog({
                message: (
                    <dl>
                        <dt className="mt-3">Are you sure you want to cancel?</dt>
                        <dd><small>All your changes will be lost.</small></dd>
                    </dl>
                ),
                icon: 'fal fa-info-circle text-primary',
                acceptClassName: 'btn btn-primary',
                rejectClassName: 'btn btn-secondary text-dark',
                header: 'Cancel',
                accept: props.onAccept,
                reject: props.onReject,
                blockScroll: true,
                draggable: false
            } as (DialogProps & ConfirmDialogProps));
        }
    };
    return (
        <button aria-label="Close dialog"
            className="btn btn-secondary"
            type="button" onClick={onCancelClick}>
            Cancel
        </button>
    );
}