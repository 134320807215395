import { useState } from 'react';
import { AdminTrendingResource } from '../../../_models/admin/admin-trending-resource';
import { api } from '../../../_services/api/api';
import { confirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog';
import { DialogProps } from 'primereact/dialog';
export function TrendingResource(props: { resource: AdminTrendingResource; reload: () => void; }) {

    const [working, setWorking] = useState(false);
    const { link, title, showInTrending, trendingCount, id, isSticky } = props.resource;

    const className = 'rounded p-2 shadow-sm my-2 admin-content-items ' + (showInTrending ? 'text-success in-use' : 'text-dark');
    const buttonClassName = 'btn btn-sm btn-icon-only rounded-circle btn-block my-2 ' + (showInTrending ? 'btn-success' : 'btn-danger');
    const stickyClassName = 'my-2 btn btn-sm btn-block btn-icon-only rounded-circle ' + (isSticky ? 'btn-dark text-white' : 'btn-secondary text-primary');

    const toggleVisibility = (id: string, visibility: boolean) => {
        setWorking(true);
        api.post.setTrendingResourceVisibility({
            id,
            visibility
        }).then(result => {
            if (result?.success) {
                props.reload();
            }
            setWorking(false);
        })
    }

    const toggleSticky = (id: string, sticky: boolean) => {
        setWorking(true);
        api.post.setTrendingResourceSticky({
            id,
            sticky
        }).then(result => {
            if (result?.success) {
                props.reload();
            }
            setWorking(false);
        })
    }

    const block = (id: string) => {
        setWorking(true);
        confirmDialog({
            message: (
                <dl>
                    <dt className="mt-3">Are you sure you want to block this?</dt>
                    <dd><small>This trending resource will be blocked and you will no longer see it.</small></dd>
                </dl>
            ),
            icon: 'fal fa-info-circle text-primary',
            acceptClassName: 'btn btn-primary',
            rejectClassName: 'btn btn-secondary text-dark',
            header: 'Block Trending Resource',
            accept:() => {
                api.post.blockTrendingResource({
                    id
                }).then(result => {
                    if (result?.success) {
                        props.reload();
                    }
                    setWorking(false);
                })
            },
            onHide:() => {
                setWorking(false);
            },
            blockScroll: true,
            draggable: false
        } as (DialogProps & ConfirmDialogProps));

    }


    return (
        <div className={className} style={{ position: 'relative' }}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-between align-items-top">
                    <div className="p2 mr-3 align-self-center">
                        <button aria-label={(showInTrending ? 'Hide' : 'Show') + ' Trending Resource to Users'}
                            type="button" className={buttonClassName} data-pr-tooltip={(showInTrending ? 'Hide' : 'Show') + ' Trending Resource to Users'}
                            onClick={() => toggleVisibility(id, !showInTrending)}
                        >
                            <i className={showInTrending ? 'far fa-check' : 'far fa-slash'}></i>
                        </button>
                        {showInTrending ?
                            <button type="button" aria-label="Sticky Trending Resource" className={stickyClassName}
                                data-pr-tooltip={isSticky ? 'Release Sticky' : 'Set Sticky'}
                                onClick={() => toggleSticky(id, !isSticky)}>
                                <i className={(isSticky ? 'fas' : 'far') + ' fa-thumbtack'}></i>
                            </button>
                            : null}
                    </div>
                    <div className="d-flex align-items-start flex-column handle-wrap">
                        <span className="h6 admin-list-title mb-0">{title}</span>
                        <small className="clip-url"><a href={link} target="_blank" rel="noreferrer">{link}</a></small>
                        <small className="text-date ml-1"><i className="fal fa-wave-sine pr-2"></i>{trendingCount} {trendingCount !== 1 ? 'clicks' : 'click'}</small>
                    </div>

                </div>
                <div>
                    <button aria-label="Block Trending Resource" type="button" className="btn btn-secondary btn-sm"
                        data-pr-tooltip="Block Trending Resource"
                        onClick={() => block(id)}
                    >
                        <i className="fas fa-ban text-danger"></i>
                    </button>
                </div>
            </div>
            {working ? <div style={{
                position: 'absolute',
                border: 'solid 2px #000',
                left: 0, right: 0, top: 0, bottom: 0,
                background: 'rgba(255, 255, 255, 0.65)',
                backdropFilter: 'blur(1px)'
            }} className="d-flex text-dark justify-content-center align-items-center">
                <div className="spinner-grow text-dark mr-3" role="status"></div> 
                <h4><strong>Updating...</strong></h4> 
            </div> : null}
        </div>
    );
}
