import { PropsWithChildren, useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { useOnComponentMount } from '../../_services/use-on-component-mount';


export interface CheckboxFormGroupModel {
    value: boolean;
    isValid: boolean;
    isDirty: boolean;
}

export interface CheckboxFormGroupProps {
    label?: string;
    subLabel?: string;
    value: boolean;
    onChange: (value: CheckboxFormGroupModel) => void;
    name: string;
    fieldName?: string;
    validation?: (value: boolean) => boolean;
    validationMessage?: string;
}

export function CheckboxFormGroup(props: PropsWithChildren<CheckboxFormGroupProps>) {

    const [cleanValue, setCleanValue] = useState<boolean | undefined>()

    const isValid = (newValue: boolean) => {

        if (newValue && props.validation && !props.validation(newValue)) {
            return false;
        }

        return true;
    }

    useOnComponentMount(() => {
        setCleanValue(props.value)
        onChange({
            value: props.value,
            isValid: isValid(props.value),
            isDirty: false
        })
    })

    const { value, onChange, name } = props;
    const fieldName = props.fieldName || props.label || 'Field';

    return (
        <div className="form-group">
            <label htmlFor={name}>{props.label}
                {props.subLabel ?
                    <div>
                        <small>{props.subLabel}</small>
                    </div>
                    : null}
            </label><br />

            <InputSwitch 
                checked={value} onChange={(e) => onChange({
                    value: e.target.value,
                    isValid: isValid(e.target.value),
                    isDirty: e.target.value !== cleanValue
                })} 
            />

            {value && props.validation && !props.validation(value) ?
                <div>
                    <small className="input-sublabel">
                        {props.validationMessage ? props.validationMessage : `${fieldName} is not valid`}
                    </small>
                </div>
                : null}
        </div>
    )
}