import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalProps } from '../../_models/modal-props';
import { SearchLevels } from '../../_models/search/search-levels';
import { SuggestedResource } from '../../_models/taxonomy/suggested-resource';
import { api } from '../../_services/api/api';
import search from '../../_services/search';
import { useApiResource } from '../../_services/use-api-resource';
import { AsyncResource } from '../AsyncResource/AsyncResource';
import { EmptyResultSet } from '../EmptyResultSet/EmptyResultSet';
import { Resource } from './Resources';

function getChildTopicIds(resource: SuggestedResource): (string | undefined)[] {
    return resource.children?.reduce((current, child) => current.concat(getChildTopicIds(child)), [resource.wordpressTopicId]) || [resource.wordpressTopicId];
}

export function ResourcesDialog({ onHide, resource }: ModalProps & { resource: SuggestedResource }) {
    const callback = useCallback(() => {
        const ids = getChildTopicIds(resource).filter(id => id) as string[];

        return api.post.getTopicResources({
            ids: ids,
            rootId: resource.wordpressTopicId || resource.title
        })
    }, [resource])

    const resources = useApiResource(callback)

    const router = useHistory();

    return (
        <Dialog draggable={false} onHide={onHide} blockScroll={true} style={{ width: '55vw' }} visible
            header={
                <>
                    <div>
                        {resource.title}
                    </div>
                    <div className="topic-description text-dark mb-0">{resource.description}</div>
                </>
            }
            footer={
                <div className="d-flex justify-content-between">
                    <button aria-label="Search Synod and Partner Websites" type="button" className="btn btn-link text-primary" onClick={() => search(resource.keywords || resource.title, SearchLevels.partnerResources, router, [])}><i className="fal fa-search mr-2"></i> Search Synod and Partner Websites</button>
                    <button aria-label="Close dialog" type="button" className="btn btn-primary" onClick={onHide}>Close</button>
                </div>
            }
        >
            <AsyncResource resource={resources} render={res => <>
                {res?.length ?
                    res.map((resource, i) => <Resource key={i} resource={resource} />) :
                    <div>
                        <EmptyResultSet onFeedbackClick={onHide} />
                    </div>
                }
            </>}
                loading={
                    <div>
                        <div className="bg-purple p-3 text-white w-50 rounded shadow-sm">
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            <span className="pl-2">Loading...</span>
                        </div>
                        {Array.from({ length: 3 }).map((_x, i) =>
                            <Skeleton key={i} height="10em" width="100%" className="mb-4" />
                        )}
                    </div>
                }
            />
        </Dialog>
    )
}