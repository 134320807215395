import { useModal } from '../../_services/use-modal';
import { ContributeResourceForm } from '../ContributeResource/ContributeResourceForm';
import { RequestResourceForm } from '../RequestResource/RequestResourceForm';

export interface EmptyResultSetContentProps {
    onRequestResourceClick?: () => void;
    onContributeResourceClick?: () => void;
    onFeedbackClick?: () => void;
    onInteractionClick?: () => void;
}

export function EmptyResultSetContent(props: EmptyResultSetContentProps) {
    const { visible: contributeIsVisible, showModal: showContributeModal, hideModal: hideContributeModal } = useModal();
    const { visible: requestIsVisible, showModal: showRequestModal, hideModal: hideRequestModal } = useModal();

    const onRequestResourceClick = () => {
        props.onRequestResourceClick?.();
        props.onInteractionClick?.();
        showRequestModal();
    }

    const onContributeResourceClick = () => {
        props.onContributeResourceClick?.();
        props.onInteractionClick?.();
        showContributeModal();
    }

    const onFeedbackClick = () => {
        props.onFeedbackClick?.();
        props.onInteractionClick?.();
        setTimeout(() => {
            (window as any).Beacon('open');
        }, 5)
    }

    return (
        <>
            <dl>
                <dt>We are constantly adding resources...</dt>
                <dd>Would you like help to <span className="text-warning cursor-pointer" onClick={onContributeResourceClick}>contribute content?</span></dd>
                <dd>Would you like to <span className="text-warning cursor-pointer" onClick={onRequestResourceClick}>request specific content?</span></dd>
                <dd>Do you have a <span className="text-warning cursor-pointer" onClick={onFeedbackClick}>question or comment?</span></dd>
            </dl>
            {contributeIsVisible ? <ContributeResourceForm onHide={hideContributeModal} /> : null}
            {requestIsVisible ? <RequestResourceForm onHide={hideRequestModal} /> : null}
        </>
    );
}