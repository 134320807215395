import { RadioButton } from 'primereact/radiobutton';
import { useEffect } from 'react';
import { SearchLevels } from '../../_models/search/search-levels';
import { useSearchParams } from '../../_services/search-params';

export function SearchLevel(props: { level: string; setLevel: (type: string) => void }) {
    
    const { level, setLevel } = props;
    const searchParams = useSearchParams();

    useEffect(() => {
        if (searchParams.level) {
            setLevel(searchParams.level);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.level])

    return (
        <>
            <div className="mb-1 ml-3">
                    <RadioButton ariaLabelledBy={'Set Search to MDFL Library Catalog'} inputId={SearchLevels.recommendedResources} value={SearchLevels.recommendedResources} name="type" onChange={(e) => setLevel(e.value)} checked={level === 'recommended'} />
                    <label className="mb-0 ml-2" htmlFor={SearchLevels.recommendedResources}>MDFL Library Catalog</label>
            </div>
            <div className="mb-1 ml-3">
                    <RadioButton ariaLabelledBy={'Set Search to Synod and Partner Websites'} inputId={SearchLevels.partnerResources} value={SearchLevels.partnerResources} name="type" onChange={(e) => setLevel(e.value)} checked={level === 'partner'} />
                    <label className="mb-0 ml-2" htmlFor={SearchLevels.partnerResources}>Synod and Partner Websites</label>
            </div>
        </>
    );
}
