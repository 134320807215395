import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { FormGroup, FormGroupModel } from '../../../__form/FormGroup';
import { defaultCheckboxValueOf, defaultFormValueOf } from '../../../__form/default-form-value';
import { api } from '../../../../_services/api/api';
import { CheckboxFormGroup, CheckboxFormGroupModel } from '../../../__form/CheckboxFormGroup';
import { AdminEntity } from '../../../../_models/admin/admin-entity';
import { clearAdminTaxonomy } from '../../../../_services/api-resources/admin-taxonomy';
import { HideEntityButton } from './HideEntityButton';
import { CancelButton } from '../../CancelButton';

interface EntityEditFormProps {
    toast: React.RefObject<Toast>
    entity: AdminEntity;
    onHide: () => void;
    onSave: () => void;
}

export function EntityEditForm(props: EntityEditFormProps) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [name, setName] = useState<FormGroupModel>(defaultFormValueOf(props.entity.name || ''));
    const [isDefault, setIsDefault] = useState<CheckboxFormGroupModel>(defaultCheckboxValueOf(props.entity.isDefault));

    const formIsValid = name.isValid &&
        isDefault.isValid;

    const formIsDirty = props.entity.name !== name.value ||
        props.entity.isDefault !== isDefault.value;

    const save = (silent: boolean) => async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        if (formIsValid) {
            setIsSubmitting(true);
            return await api.post.saveEntity({
                isDefault: isDefault.value,
                name: name.value,
                id: props.entity.id
            }).then(result => {
                if (result?.success) {
                    clearAdminTaxonomy();
                    if (!silent) {
                        props.onSave();
                    }

                } else {
                    props.toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Unable to save',
                    });
                }
                setIsSubmitting(false);
                return result?.success || false;
            });
        } else {
            return false;
        }
    };

    const onSubmit = save(false);

    const headerIconClass = 'h2 mb-0 mr-2 px-1 ' + (props.entity.id ? 'fal fa-file-edit' : 'fal fa-file-plus');

    return (
        <Dialog onHide={props.onHide} visible blockScroll={true} closable={false} draggable={false}
            header={<>
                <div className="d-flex justify-content-between align-items-center">
                    <i className={headerIconClass}></i>
                    <h5 className="text-uppercase m-0">
                        {props.entity.id
                            ? <><b>Edit Entity-</b> {props.entity.name}</>
                            : <><b>Add Entity</b> </>}
                        {formIsDirty ? <span data-pr-tooltip='Unsaved'> *</span> : null}
                    </h5>
                </div>
            </>}
            style={{ width: '80vh' }}
            footer={<div className="d-flex justify-content-between text-uppercase align-items-end">
                <div>
                    <button aria-label="Contribute Content" className="btn btn-primary" disabled={!formIsValid || isSubmitting}
                        type="button" onClick={() => onSubmit()}
                    >
                        {isSubmitting ? <i className="fal fa-spinner-third fa-spin"></i> : 'Save Entity'}
                    </button>
                </div>
                <div>
                    <HideEntityButton
                        entity={props.entity}
                        onHide={props.onSave}
                        toast={props.toast}
                        disabled={formIsDirty && !formIsValid}
                        hasChanges={formIsDirty}
                        saveChanges={save(true)}
                    />
                </div>
                <div>
                    <CancelButton onAccept={props.onHide} autoAccept={!formIsDirty} />
                </div>
            </div>}
        >
            <form onSubmit={onSubmit} className="modal-form">

                <FormGroup name='name'
                    value={name.value} onChange={setName}
                    label='NAME' fieldName='Entity name'
                    required maxLength={255}
                    showCharacterCount />

                <CheckboxFormGroup name='isDefault'
                    value={isDefault.value} onChange={setIsDefault}
                    label={(isDefault.value ? 'Site will default to- ' : 'Not default- ') + props.entity.name.toString()}
                    fieldName='Default' />
            </form>

        </Dialog>
    );
}
