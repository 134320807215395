
export interface SuggestedResource {
    id: string;
    title: string;
    entity: {
        name: string;
        id: string;
    };
    role: {
        name: string;
        id: string;
    };
    keywords?: string;
    link?: string;
    description?: string;
    parent?: SuggestedResource;
    featured?: string;
    wordpressTopicId?: string;
    children: SuggestedResource[];
    active: boolean;
    internalIdentifier?: string;
}

export interface SuggestedResourceFormModel {
    id?: string;
    title?: string;
    entity?: {
        name: string;
        id: string;
    };
    role?: {
        name: string;
        id: string;
    };
    keywords?: string;
    link?: string;
    description?: string;
    parent?: SuggestedResource;
    featured?: string;
    wordpressTopicId?: string;
    canChangeParent: boolean;
    activeChildCount: number;
    internalIdentifier?: string;
}

export function formModel(resource: SuggestedResource, canChangeParent: boolean): SuggestedResourceFormModel {
    return {
        activeChildCount: resource.children.filter(c => c.active).length,
        canChangeParent: canChangeParent,
        description: resource.description,
        entity: resource.entity,
        featured: resource.featured,
        id: resource.id,
        keywords: resource.keywords,
        link: resource.link,
        parent: resource.parent,
        role: resource.role,
        title: resource.title,
        wordpressTopicId: resource.wordpressTopicId,
        internalIdentifier: resource.internalIdentifier
    }
}