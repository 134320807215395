import React, { useCallback, useState } from 'react';
import { PropsWithChildren } from 'react';
import { ModalProps } from '../../_models/modal-props';
import { EntityFilter } from '../../_models/taxonomy-filter/entity-filter';
import { RoleFilter } from '../../_models/taxonomy-filter/role-filter';
import { api } from '../../_services/api/api';
import { defaultFormValue } from '../__form/default-form-value';
import { DropdownFormGroup, DropdownFormGroupModel, DropdownItem } from '../__form/DropdownFormGroup';
import { FormGroup, FormGroupModel } from '../__form/FormGroup';
import { LinksInput, LinksModel } from './LinksInput';
import { SubmitError } from './SubmitError';
import { SubmitSuccess } from './SubmitSuccess';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

export function mapEntityToDropdown(entity: EntityFilter): DropdownItem {
    return {
        label: entity.label,
        value: entity.value
    }
}

export function mapRoleToDropdown(entity: RoleFilter): DropdownItem {
    return {
        label: entity.label,
        value: entity.value
    }
}

export function ContributeResourceForm(props: PropsWithChildren<ModalProps>) {
    const [hasFiles, setHasFiles] = useState(false);
    const [saved, setSaved] = useState({
        saved: false,
        hadError: false
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [entities, setEntities] = useState<EntityFilter[]>();
    const [name, setName] = useState<FormGroupModel>(defaultFormValue);
    const [email, setEmail] = useState<FormGroupModel>(defaultFormValue);
    const [description, setDescription] = useState<FormGroupModel>(defaultFormValue);
    const [entity, setEntity] = useState<DropdownFormGroupModel>(defaultFormValue);
    const [role, setRole] = useState<DropdownFormGroupModel>(defaultFormValue);
    const [links, setLinks] = useState<LinksModel>({ values: [], isValid: false, isDirty: false });

    const loadEntities = useCallback(async () => {
        const result = await api.get.topicFilter();
        if (result) {
            setEntities(result);
        }

        return result?.filter(e => e.value !== 'all') || [];
    }, []);

    const loadRoles = useCallback(() => {
        return new Promise<RoleFilter[]>(accept => {
            const selected = entity.selected?.value;
            if (!selected) {
                accept([]);
            } else {
                const selectedEntity = entities?.filter(e => e.value === selected)[0];
                accept(selectedEntity?.roles.filter(r => r.value !== 'all') || []);
            }
        });
    }, [entities, entity.selected?.value]);

    if (saved.saved) {
        return saved.hadError ? <SubmitError {...props} /> : <SubmitSuccess {...props} />
    }

    const formValid = name.isValid &&
        email.isValid &&
        description.isValid &&
        entity.isValid &&
        role.isValid &&
        links.isValid;

    const save = () => {
        if (formValid) {
            setIsSubmitting(true);
            api.post.contributeResource({
                email: email.value,
                name: name.value,
                description: description.value,
                entity: entity.selected?.label || 'All',
                entityId: entity.selected?.value || 'all',
                role: role.selected?.label || 'All',
                roleId: role.selected?.value || 'all',
                links: links.values.map(l => l.value),
                hasFiles: hasFiles
            }).then(result => {
                if (result) {
                    setSaved({
                        saved: true,
                        hadError: !result.success
                    });
                    setIsSubmitting(false);
                } else {
                    setSaved({
                        saved: true,
                        hadError: true
                    });
                    setIsSubmitting(false);
                }
            });
        }
    }

    const onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        save();
    };

    return (
        <Dialog blockScroll={true} visible draggable={false} style={{ width: '50vw' }} onHide={props.onHide}
            header={
                <div className="text-uppercase">
                    <i className="fas fa-file-plus h4 mb-0 mr-2 px-1"></i> Contribute Content
                </div>
            }
            footer={
                <div className="d-flex justify-content-between text-uppercase align-items-end">
                    <div>
                        <div>
                            <button aria-label="Contribute Content" className="btn btn-primary"
                                type="button" onClick={save}
                                disabled={!formValid || isSubmitting}>
                                {isSubmitting ? <i className="fal fa-spinner-third fa-spin"></i> : 'Contribute Content'}
                            </button>
                        </div>
                    </div>
                    <div>
                        <button aria-label="Close dialog" className="btn btn-secondary" type="button"
                            onClick={props.onHide}>
                            Close
                    </button>
                    </div>

                </div>
            }
        >
            <form onSubmit={onSubmit} className="modal-form">
                <FormGroup
                    value={name.value} onChange={setName}
                    label="YOUR NAME"
                    fieldName="Your name" name="name"
                    required showCharacterCount maxLength={255} />

                <FormGroup
                    value={email.value} onChange={setEmail}
                    label="EMAIL ADDRESS"
                    fieldName="Email address" name="email"
                    required showCharacterCount maxLength={80}
                    validation={(value) => (/^.+@.+\..+$/.test(value))}
                    validationMessage={'Please enter a valid email address'} />

                <FormGroup
                    value={description.value} onChange={setDescription}
                    label="WHAT ARE YOU CONTRIBUTING" multiLine
                    subLabel="Provide a little detail on what you are sharing..."
                    fieldName="Request" name="request"
                    showCharacterCount maxLength={255} />

                <LinksInput links={links.values} onChange={setLinks} />

                <div className="form-group">
                    <label>FILES
                <div><small>If you have files you would like to share with us please let us know and we will connect by email.</small></div>
                    </label>
                    <div className="p-field-checkbox">
                        <Checkbox inputId="binary" checked={hasFiles} onChange={e => setHasFiles(e.checked)} />
                        <label htmlFor="binary" className={(hasFiles ? 'bold-label' : '')}>I have files to share, please contact me by email</label>
                    </div>
                </div>

                <DropdownFormGroup
                    value={entity.selected} onChange={(e) => setEntity(e)}
                    label="ENTITY"
                    subLabel="Please choose the group that best describes your resource"
                    fieldName="Group" name="group"
                    load={loadEntities}
                    map={mapEntityToDropdown} />

                <DropdownFormGroup
                    value={role.selected} onChange={setRole}
                    label="ROLE"
                    subLabel="Please choose the role that best describes your resource"
                    fieldName="Role" name="role"
                    load={loadRoles}
                    map={mapRoleToDropdown} />
            </form>
        </Dialog>
    );
};
