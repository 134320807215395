import { SuggestedResourceFormModel } from '../../../../../_models/taxonomy/suggested-resource';
import { Tooltip } from 'primereact/tooltip';
import { EditButton } from './EditButton';
import { AddChildButton } from './AddChildButton';
import { NodeData } from '../SuggestedTopicsTable';
import { RestoreButton } from './RestoreButton';

export function Controls(showModal: (resource: SuggestedResourceFormModel) => void, onRestore: () => void) {
    return (props: { data: NodeData }) => (
        <div className={'d-flex justify-content-' + (props.data.isRoot ? 'start' : 'end')}>
            <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
            {props.data.active ? <>
                <EditButton resource={props.data.resource} showModal={showModal} />
                <AddChildButton resource={props.data.resource} showModal={showModal} />
            </> : <>
                <RestoreButton id={props.data.resource.id} onRestore={onRestore} />
            </>}

        </div>
    );
}

