import React from 'react';
import { SearchResult as SearchResultModel } from '../../../_models/search/search-result';
import { EmptyResultSet } from '../../EmptyResultSet/EmptyResultSet';
import { SearchResult } from '../SearchResult';

export function GoogleResults(props: { results: SearchResultModel[]; }) {
    const { results } = props;

    if (!results?.length) {
        return (
            <EmptyResultSet />
        );
    }

    return (
        <div>
            {results?.map(result => (
                <SearchResult key={result.url.actual} result={result} />
            ))}
        </div>
    );
}


