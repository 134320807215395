import { TaxonomyFilter } from '../../../_models/taxonomy-filter/taxonomy-filter';
import { SuggestedResource } from '../../../_models/taxonomy/suggested-resource';
import { Action } from '../../../_models/types/action';

export interface BreadcrumbsProps {
    visibleTopics?: SuggestedResource[];
    filter: TaxonomyFilter;
    filterNames: TaxonomyFilter;
    viewSubResources: Action<SuggestedResource | undefined>;
    setFilter: Action<TaxonomyFilter>;
}

export function Breadcrumbs(props: BreadcrumbsProps) {
    if (!props?.visibleTopics?.[0]) { return null; }

    const crumbs: SuggestedResource[] = [];
    let parent = props.visibleTopics[0].parent;
    while (parent) {
        crumbs.unshift(parent);
        parent = parent.parent;
    }

    const navigate = (resource?: SuggestedResource) => {
        props.viewSubResources(resource);
    };

    return (
        <nav aria-label="breadcrumb">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item" onClick={() => props.setFilter({
                            entity: props.filter.entity,
                            role: 'all',
                            term: props.filter.term
                        })}>
                            {props.filterNames.entity || 'All'}
                        </li>
                        {props.filterNames.role !== props.filterNames.entity ? <li className="breadcrumb-item" onClick={() => navigate()}>
                            {props.filterNames.role || 'All'}
                        </li> : null}
                        {crumbs.map(topic => <li className="breadcrumb-item" onClick={() => navigate(topic)}>{topic.title}</li>
                        )}
                    </ol>
                </div>
                <div className="btn btn-sm btn-outline-warning cursor-pointer" onClick={() => navigate()}>Top Level<i className="fas fa-level-up ml-2"></i></div>
            </div>
        </nav>
    );
}
