import endpoints from './api-endpoint-definition.json';
import { WordPressResult } from '../../_models/search/wordpress-result';
import { ApiCall } from '@lcms/api-definition';

export type RecentResourcesResponse = WordPressResult[] | null;

const definition = new ApiCall<RecentResourcesResponse>().get('recentlyAdded', {
    route: endpoints.recentResources,
    process: results => {
        results?.map(r => {
            r.modifiedGmt = new Date(r.modifiedGmt);
            return r;
        })
    }
})
export default definition;