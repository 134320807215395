import React, { PropsWithChildren, useState } from 'react';
import { ToggleButton } from 'primereact/togglebutton';
import { SortType } from '../../_models/admin/sort-type';
import { SortByDropdown } from './SortByDropdown/SortByDropdown';

export interface HeaderProps {
    noFilter?: boolean;
    filterProps?: HeaderFilterProps;
    onFilter?: (filter: string) => void;
    noButtons?: boolean;
    pageTitle?: JSX.Element;
    additionalButtons?: JSX.Element;
    sort: SortType;
    onSortChange: (v: SortType) => void;
    visibleFilter?: boolean;
    visibleFilterChange?: (v?: boolean) => void;
}

export interface HeaderFilterProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> { }

export function SectionHeader(props: PropsWithChildren<HeaderProps>) {
    const [value, setValue] = useState((props.filterProps?.defaultValue?.toString()) || '')

    const onValueChange = (value: string, search: boolean, e?: React.ChangeEvent<HTMLInputElement>) => {
        setValue(value);
        if (search) {
            props.onFilter?.(value)
        }
        if (e) {
            props.filterProps?.onChange?.(e);
        }
    }



    return (
        <>
            <div id="anchor-top"></div>
            <div className="mb-4">
                <div className="bg-white rounded shadow-sm p-3">
                    {props.pageTitle ?
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="sb-bold-title"><i className="fad fa-chart-line mr-2"></i> {props.pageTitle}</h3>
                                <small className="text-date ml-3 text-dark">Last 30 days</small>
                            </div>
                        </div>
                        : null}
                    <div className="d-flex justify-content-md-between mb-2 filter-container">
                        {props.noButtons ? null :
                            (
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <ToggleButton checked={props.visibleFilter === true}
                                            onChange={(e) => props.visibleFilterChange?.(e.value ? true : undefined)}
                                            onLabel="Active / Visible"
                                            offLabel="Active / Visible"
                                            iconPos="right"
                                            className="rounded-pill tb-primary"
                                            onIcon="fal fa-times" offIcon="fal fa-circle"
                                            tooltip={props.visibleFilter === true ? 'remove filter' : 'apply filter'} />
                                        <ToggleButton checked={props.visibleFilter === false}
                                            onChange={(e) => props.visibleFilterChange?.(e.value ? false : undefined)}
                                            onLabel="Inactive / Hidden"
                                            offLabel="Inactive / Hidden"
                                            iconPos="right"
                                            className="rounded-pill tb-primary"
                                            onIcon="fal fa-times" offIcon="fal fa-circle"
                                            tooltip={props.visibleFilter === false ? 'remove filter' : 'apply filter'} />
                                    </div>
                                </div>
                            )}
                        {props.additionalButtons}
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap">

                        {props.noFilter ? null : (
                            <form onSubmit={e => {
                                e.preventDefault();
                                props.onFilter?.(value)
                            }} className="w-50 topic-filter-search my-3">

                                <div className="topic-filter form-group mb-0">
                                    <div className="input-group input-group-alternative">

                                        <input type="text" className="form-control" {...props.filterProps}
                                            value={value}
                                            onChange={e => onValueChange(e.target.value, false, e)}
                                        />
                                        {
                                            value ?
                                                <div className="input-group-append" >
                                                    <span className="input-group-text selectable"
                                                        onClick={() => onValueChange('', true)}>
                                                        <i className="fal fa-times"></i>
                                                    </span>
                                                </div>
                                                : null
                                        }

                                        <button aria-label="Apply search term for list narrowing" className="btn btn-outline-warning" data-pr-position="top" data-pr-tooltip="Click to narrow">Go</button>
                                    </div>
                                </div>
                            </form>
                        )}
                        <div>Sort by: <SortByDropdown value={props.sort} onChange={props.onSortChange} /> </div>
                    </div>

                </div>
            </div >
        </>
    );
}