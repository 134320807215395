import { Tooltip } from 'primereact/tooltip';
import { EditButton } from './EditButton';
import { AddRoleButton } from './AddRoleButton';
import { RestoreButton } from './RestoreButton';
import { NodeData } from '../TaxonomyTable';
import { DeleteButton } from './DeleteButton';

export function Controls(showModal: (resource: NodeData) => void, onUpdate: () => void) {
    return (props: { data: NodeData }) => (
        <div className={'d-flex justify-content-' + (props.data.isRoot ? 'start' : 'end')}>
            <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
            {props.data.type === 'entity' ? <>
                {props.data.active ? <>
                    <EditButton node={props.data} showModal={showModal} />
                    <AddRoleButton node={props.data} showModal={showModal} />
                </> : <>
                    <RestoreButton node={props.data} onRestore={onUpdate} />
                </>}
            </> : <>
                {props.data.active ? <>
                    <EditButton node={props.data} showModal={showModal} />
                    <DeleteButton node={props.data} onDelete={onUpdate} />
                </> : <>
                    <RestoreButton node={props.data} onRestore={onUpdate} />
                </>}
            </>}


        </div>
    );
}

