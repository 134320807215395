import endpoints from '../api-endpoint-definition.json';
import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { ApiResponse } from './api-response';

const definition = new ApiCall<ApiResponse>().post('saveSuggestedTopic', {
    route: endpoints.adminSuggestedTopicsSave,
    params: {
        id: {
            type: ParameterTypes.String,
            optional: true
        },
        title: {
            type: ParameterTypes.String
        },
        description: {
            type: ParameterTypes.String,
            optional: true
        },
        link: {
            type: ParameterTypes.String,
            optional: true
        },
        roleId: {
            type: ParameterTypes.String
        },
        entityId: {
            type: ParameterTypes.String
        },
        parentId: {
            type: ParameterTypes.String,
            optional: true
        },
        keywords: {
            type: ParameterTypes.String,
            optional: true
        },
        featured: {
            type: ParameterTypes.String,
            optional: true
        },
        wordpressId: {
            type: ParameterTypes.String,
            optional: true
        },
        internalIdentifier: {
            type: ParameterTypes.String,
            optional: true
        }
    }
})
export default definition;

