import React, { useCallback, useEffect, useState } from 'react';
import { SuggestedResource, SuggestedResourceFormModel } from '../../../../_models/taxonomy/suggested-resource';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import TreeNode from 'primereact/components/treenode/TreeNode';
import { FeaturedTag } from './FeaturedTag';
import { Title } from './Title';
import { Controls } from './Controls/Controls';
import { EditModal } from '../Edit/EditModal';
import { Toast } from 'primereact/toast';
import { compare, Dictionary } from '@lcms/helpers';

export interface NodeData {
    id: string;
    active: boolean;
    displayTitle: string;
    title: string;
    featured?: string;
    isRoot: boolean;
    resource: SuggestedResource;
}

function mapToTreeNode(resource: SuggestedResource): TreeNode {
    return {
        key: (resource.parent?.id || '') + resource.id,
        data: {
            id: resource.id,
            active: resource.active,
            title: resource.title,
            featured: resource.featured,
            isRoot: !resource.parent,
            resource: resource,
            displayTitle: `${resource.internalIdentifier || ''} ${resource.title}`.trim()
        } as NodeData,
        children: sortTopics(resource.children).map(mapToTreeNode)
    }
}

function sortTopics(topics: SuggestedResource[]): SuggestedResource[] {
    return topics.sort((a, b) =>
        compare((x: SuggestedResource) => x.internalIdentifier?.split('.').map(i => i.padStart(4, '0')).join('.') || '')(a, b) ||
        compare((x: SuggestedResource) => x.title)(a, b)
    )
}

export interface SuggestedTopicsTableProps {
    topics: SuggestedResource[];
    reload: () => void;
    toast: React.RefObject<Toast>;
    expandedKeys: Dictionary<boolean>;
    onExpandToggle: (values: Dictionary<boolean>) => void;
}

export function SuggestedTopicsTable(props: SuggestedTopicsTableProps) {

    const [data, setData] = useState<TreeNode[]>([]);
    const [resourceToEdit, setResourceToEdit] = useState<SuggestedResourceFormModel | null>(null);
    const [filter, setFilter] = useState<string | null>(null);

    const { topics } = props;
    useEffect(() => {
        setData(sortTopics(topics).map(mapToTreeNode));
    }, [topics]);

    const openModal = useCallback(resource => setResourceToEdit(resource), []);

    const rowClassName = (row: TreeNode) => {
        return {
            'top-row': (row.data.isRoot),
            'row-deleted': !row.data.active,
            'leaf': !row.children.length,
            'leaf-with-subs': row.children.length && (!row.data.isRoot)
        };
    };

    return (
        <div className="respond">
            <TreeTable value={data} globalFilter={filter}
                expandedKeys={props.expandedKeys}
                onToggle={e => props.onExpandToggle(e.value)}
                sortField='displayTitle'
                paginator rows={10}
                rowClassName={rowClassName}
                header={<div className="d-flex justify-content-between align-items-end">
                    <div>
                        <button type="button" className="btn btn-sm btn-warning"
                            onClick={() => setResourceToEdit({
                                activeChildCount: 0,
                                canChangeParent: false
                            })}
                        ><i className="fas fa-plus mr-2"></i>Add Root Topic</button>
                    </div>
                    <div className="input-group w-50">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fad fa-search"></i>
                            </span>
                        </div>
                        <input type="search" className="form-control" onChange={(e) => setFilter(e.target.value)} placeholder="Search" />
                    </div>
                </div>}>
                <Column body={Controls(openModal, props.reload)} style={{ textAlign: 'center', width: '10em' }} headerClassName="sm-invisible" bodyClassName="sm-invisible d-flex justify-content-start action-div" expander />
                <Column body={Title(openModal, props.reload)} header="Topic" field="displayTitle" sortable expander className="row-title"></Column>
                <Column body={FeaturedTag(openModal)} style={{ width: '14em' }} header="Featured" field="featured" sortable headerClassName="md-invisible" bodyClassName="md-invisible"></Column>
            </TreeTable>
            {resourceToEdit ? <EditModal
                onHide={() => setResourceToEdit(null)}
                resource={resourceToEdit}
                toast={props.toast}
                onSave={() => {
                    props.toast.current?.show({
                        severity: 'success',
                        detail: 'Saved!'
                    });
                    props.reload();
                }}
            /> : null}
        </div>
    );
}
