import { Cookie } from '../cookies';
import { TopicFilter } from '../../_models/topic-filter';

const TopicFilterHistoryCookie = new Cookie<TopicFilter[]>('topics', 90);

export function removeTopicFilter(index: number) {
    const filters = getRecentTopicFilters();
    TopicFilterHistoryCookie.set(filters.slice(0, index).concat(filters.slice(index + 1)));
}

export function saveTopicFilter(term: string, entity?: { name: string; id: string; }, role?: { name: string; id: string; }) {
    if (!term) { return; }

    const filters = getRecentTopicFilters()
        .filter(t =>
            t.term.toLocaleLowerCase() !== term.toLocaleLowerCase().trim()
            || t.entity?.id !== entity?.id
            || t.role?.id !== role?.id
        );

    filters.unshift({
        term,
        entity,
        role,
        dateSearched: new Date()
    });

    TopicFilterHistoryCookie.set(filters.slice(0, 15));
}

export function getRecentTopicFilters(): TopicFilter[] {
    const visitedResources = TopicFilterHistoryCookie.get();
    return visitedResources?.map(r => { r.dateSearched = new Date(r.dateSearched); return r }) || [];
}