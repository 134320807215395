import React from 'react';
import { Toast } from 'primereact/toast';
import { useModal } from '../../../_services/use-modal';
import { HeaderProps, SectionHeader } from '../SectionHeader';
import { AddResourceDialog } from './AddResourceDialog';
import endpoints from '../../../_services/api/api-endpoint-definition.json';

export function Header(props: HeaderProps & { toast: React.RefObject<Toast>; onSave: () => void; }) {
    const { visible, hideModal, showModal } = useModal();

    return (
        <>
            <SectionHeader
                {...props}
                filterProps={{
                    placeholder: 'Narrow trending resources...'
                }}
                pageTitle={
                    <span>Trending Resources</span>
                }
                additionalButtons={<>
                    <a href={endpoints.reportBlockedResources}
                        className="btn btn-primary btn-sm btn-normal py-2 px-3"
                        target="_blank"
                        rel="noreferrer"
                    ><i className="fad fa-file-download mr-2"></i>Download Blocked Report</a>

                    <button type="button" aria-label="Add" className="btn btn-outline-success btn-sm"
                        onClick={showModal}
                    ><i className="fas fa-plus mr-2"></i> Add Trending Resource</button>
                </>}
            />
            {visible ? <AddResourceDialog
                onHide={hideModal}
                onSave={() => {
                    props.onSave();
                    hideModal();
                }}
                toast={props.toast}
            /> : null}
        </>
    );
}