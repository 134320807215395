import { Dialog } from 'primereact/dialog';
import { ModalProps } from '../../_models/modal-props';

export function SubmitError(props: ModalProps) {
    return (
        <Dialog blockScroll={true} draggable={false} visible style={{ width: '50vw' }} onHide={props.onHide}
            header={
                <div className="text-uppercase">
                    <span className="fa-stack">
                        <i className="fas fa-file fa-stack-2x text-primary"></i>
                        <i className="fas fa-comment-alt fa-stack-1x ml-0 mt-1 text-white"></i>
                    </span>
                     Request Content
                </div>
            }
            footer={
                <button aria-label="Close dialog" className="btn btn-primary text-uppercase" type="button"
                    onClick={props.onHide}>
                    Close
                </button>
            }
        >
            <div className="d-flex justify-content-start align-items-center">
                <i className="fad fa-exclamation-circle text-danger fa-3x mr-4"></i>
                <span className="text-danger"><b>Something is not right</b><br /> We were unable to process your request.  Please try again later.</span>
            </div>
        </Dialog>

    );
}
