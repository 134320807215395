import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SuggestedTopicsPageUrl } from '../../views/Landing';
import { RecentSearchesButton } from './RecentSearchesButton';
import { ToggleButton } from 'primereact/togglebutton';
import { useSearchParams } from '../../_services/search-params';
import { SearchLevels } from '../../_models/search/search-levels';
import { useResourceTypes } from '../../_services/api-resources/resource-types';
import { AsyncResource } from '../AsyncResource/AsyncResource';
import search from '../../_services/search';
import { Skeleton } from 'primereact/skeleton';
export function Header() {
    return (
        <div className="bg-white shadow-sm">
            <div className="d-flex justify-content-between">
                <div>
                    <Link to={SuggestedTopicsPageUrl}>
                        <div className="bg-purple text-uppercase text-white p-3 h-100">
                            <div className="d-flex align-items-center h-100">
                                <i className="fas fa-arrow-left mr-2 fs-115"></i>
                                <div className="text-center flex-fill  return-link">
                                    Return to Suggested Topics
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <NarrowResults />

                <div className="ml-auto align-self-center">
                    <RecentSearchesButton />
                </div>
            </div>
        </div>
    )
}

export function NarrowResults() {
    const searchParams = useSearchParams();
    const router = useHistory();
    const resourceTypes = useResourceTypes();


    return (<>
        {searchParams.level === SearchLevels.recommendedResources && searchParams.term && searchParams.level ?
            <div className="ml-3 py-1">
                <h6 className="filter-header">Narrow Results:</h6>
                <AsyncResource resource={resourceTypes}
                    loading={
                        <div className="d-flex">
                            {Array.from({ length: 4 }).map((r, i) =>
                                <Skeleton key={i} borderRadius="50rem" height="1.7rem" width="6rem" className="mr-2" />
                            )}
                        </div>
                    }
                    render={types =>
                        <div className="filter-pills">
                            {types?.map(resource => {
                                const tags = (searchParams.tags || []);
                                const checked = tags.indexOf(resource.value) >= 0;
                                return <ToggleButton key={resource.value}
                                    checked={checked}
                                    onChange={(e) => {
                                        if (e.value && !checked) {
                                            search(searchParams.term || '', searchParams.level || '', router, tags.concat(resource.value))
                                        } else if (checked) {
                                            search(searchParams.term || '', searchParams.level || '', router, tags.filter(t => t !== resource.value))
                                        }
                                    }}
                                    onLabel={resource.label}
                                    offLabel={resource.label}
                                    iconPos="right"
                                    className="btn-sm rounded-pill tb-primary"
                                    onIcon="fal fa-times"
                                    offIcon="fal fa-circle"
                                    tooltip={checked ? 'remove filter' : 'apply filter'} />

                            })}

                        </div>

                    } />

            </div>
            : null}
    </>)

}