import { useModal } from '../../_services/use-modal';
import { RecentSearchesModal } from './RecentSearchesModal';


export function RecentSearchesButton() {

    const { visible: modalIsVisible, showModal, hideModal } = useModal();

    return (
        <>
            <button aria-label="Recent Searches" type="button" className="btn btn-white text-uppercase no-shadow"
                onClick={showModal}>
                <i className="fal fa-history"></i> Recent Searches
            </button>
            { modalIsVisible ? <RecentSearchesModal onHide={hideModal} /> : null}
        </>
    )
}

