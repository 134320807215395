import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import endpoints from './api-endpoint-definition.json';

export type TrackResourceResponse = { ok: string; } | null;

const definition = new ApiCall<TrackResourceResponse>().post('trackResource', {
    route: endpoints.trendingResourcesClick,
    params: {
        link: {
            type: ParameterTypes.String
        },
        title: {
            type: ParameterTypes.String
        }
    }
})

export default definition;