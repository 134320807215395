import { RadioButton } from 'primereact/radiobutton';
import { LabelValue } from '../../../_models/label-value';
import { scrollToTop } from '../../../_services/scroll-to-top';
import { useTaxonomyFilter } from '../../../_services/taxonomy-filter-context';


export function EntitySelect(props: LabelValue) {
    const taxonmyFilter = useTaxonomyFilter();
    const select = (e: { target: { value: string } }) => {
        if (e.target.value === props.value) {
            taxonmyFilter.setFilter({
                featured: taxonmyFilter.filter.featured,
                entity: props.value
            });
            scrollToTop();
        }

    };

    const checked = taxonmyFilter.filter.entity === props.value;

    return (
        <div className="d-flex align-items-center mb-2">
            <RadioButton inputId={props.value} ariaLabelledBy={'Toggle Entity- ' + props.label} value={props.value} name="filter-entity" onChange={select} checked={checked} />
            <label htmlFor={props.value} className="mb-0 ml-2">{props.label}</label>
        </div>
    );
}
