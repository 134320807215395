import React, { useState } from 'react';
import { Header } from './Header';
import { TrendingSearches } from './TrendingSearches';

export function TrendingSearchesPanel() {
    const [filter, setFilter] = useState('');
    return (
        <>
            <Header onFilter={setFilter} />
            <TrendingSearches filter={filter} />
        </>
    );
}
