import { SSOVendor } from '@lcms/identity-api';
import { ApiKey } from '../api/api';

export interface ApiResult<T> {
    authorized?: boolean;
    payload?: T;
    error?: any
    sso?: SSOVendor
}

export class ApiWithCredentials {
    static async get<T>(url: string, options?: RequestInit): Promise<ApiResult<T>> {
        return await this.fetch(url, {
            method: 'GET',
            ...options
        });
    }

    static async post<T>(url: string, data: any, options?: RequestInit): Promise<ApiResult<T>> {
        return await this.fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            ...options
        });
    }

    private static async fetch<T>(url: string, options?: RequestInit): Promise<ApiResult<T>> {
        return await fetch(url, {
            mode: 'cors',
            cache: 'no-store',
            credentials: 'include',
            headers: {
                'Access-Control-Allow-Credentials': 'true',
                'Content-Type': 'application/json',
                'x-api-key': ApiKey
            },
            ...options
        }).then(async response => {
            const unauthorized = response.headers.get('X-unauthorized');

            return {
                authorized: !unauthorized,
                payload: await response.json(),
                sso:  unauthorized && unauthorized !== 'true' ? JSON.parse(unauthorized) : undefined
            }
        })
            .catch(er => {
                return {
                    error: er
                }
            });;
    }
}