import { CheckboxFormGroupModel } from './CheckboxFormGroup';
import { DropdownFormGroupModel } from './DropdownFormGroup';
import { FormGroupModel } from './FormGroup';

export const defaultFormValue: FormGroupModel = {
    value: '',
    isValid: false,
    isDirty: false
}

export function defaultFormValueOf(value: string): FormGroupModel {
    return {
        value: value,
        isValid: false,
        isDirty: false
    }
}

export function defaultDropdownValueOf(value: string): DropdownFormGroupModel {
    return {
        selected: {
            label: value,
            value: value
        },
        isValid: false,
        isDirty: false
    }
}

export function defaultCheckboxValueOf(value: boolean): CheckboxFormGroupModel {
    return {
        value: value,
        isValid: false,
        isDirty: false
    }
}