import React, { useContext } from 'react';
import { EntityFilter } from './../_models/taxonomy-filter/entity-filter';
import { RoleFilter } from '../_models/taxonomy-filter/role-filter';
import { TaxonomyFilter } from '../_models/taxonomy-filter/taxonomy-filter';
import { SuggestedResource } from '../_models/taxonomy/suggested-resource';

export interface TaxonomyFilterContextType {
    filter: TaxonomyFilter;
    setFilter: (filter: TaxonomyFilter) => void;
    getFilterNames: () => TaxonomyFilter
    suggested: {
        loading: boolean;
        resources: SuggestedResource[] | null;
        subResource?: SuggestedResource[];
        viewSubResources: (resource?: SuggestedResource) => void;
    }
    filterSchema: {
        loading: boolean;
        entities: EntityFilter[];
        roles: (entity: string) => RoleFilter[];
    }
}

const TaxonomyFilterContext = React.createContext<TaxonomyFilterContextType>({
    filter: {},
    setFilter: (filter: TaxonomyFilter) => { },
    getFilterNames: () => { return {} },
    suggested: {
        loading: false,
        resources: null,
        viewSubResources: (resource?: SuggestedResource) => { }
    },
    filterSchema: {
        loading: false,
        entities: [],
        roles: (entity: string) => [],
    }
});

export default TaxonomyFilterContext;

export function useTaxonomyFilter() {
    return useContext(TaxonomyFilterContext);
}