import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


export function useLocationSearch(): string {
    const [search, setSearch] = useState<string>('');

    const routerLocation = useLocation();
    useEffect(() => {
        const queryString = routerLocation.search.substring(1);

        if (queryString !== search) {
            setSearch(queryString);
        }
    }, [routerLocation, search]);

    return search;
}
