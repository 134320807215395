import { useEffect } from 'react';
import { ErrorLog } from './error-log';

export interface ScriptProps {
    isAsync: boolean;
    defer: boolean;
}

export function useScript(url: string, props: ScriptProps = {
    isAsync: true,
    defer: false
}, callbacks?: { init?: () => void; cleanup?: () => void }) {

    const {
        isAsync,
        defer
    } = props;

    useEffect(() => {
        callbacks?.init?.();

        const script = document.createElement('script');
        script.src = url;
        script.async = isAsync;
        script.defer = defer
        document.body.appendChild(script);

        return () => {
            try {
                document.body.removeChild(script);
            } catch (error: any) {
                ErrorLog.log('Error cleaning up script:', error);
            }
            finally {
                callbacks?.cleanup?.();
            }
        }
    }, [url, isAsync, defer, callbacks]);
};