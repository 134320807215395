import { SuggestedResource } from '../../../_models/taxonomy/suggested-resource';
import { useTaxonomyFilter } from '../../../_services/taxonomy-filter-context';
import { Tooltip } from 'primereact/tooltip';
import { ResourcesDialog } from '../../Resources/ResourcesDialog';
import { useModal } from '../../../_services/use-modal';
import search from '../../../_services/search';
import { SearchLevels } from '../../../_models/search/search-levels';
import { useHistory } from 'react-router-dom';

export function SuggestedTopic(props: SuggestedResource) {
    const { visible: modalIsVisible, showModal, hideModal } = useModal();
    const router = useHistory();

    const filter = useTaxonomyFilter();

    const showEntity = filter.filter.entity === 'all';
    const showRole = filter.filter.role === 'all';

    return (<>
        <div className="bg-white rounded shadow-sm shadow-dark pt-2 pb-4 pr-4 pl-4 mb-4 mt-4 suggested-topic col-12"
            data-sort={props.title}
            id={props.id}
        >
            <div className="d-flex justify-content-between align-items-center flex-custom-wrap">
                <div>
                    <h3 className="topic-title text-uppercase">
                        {props.title}
                    </h3>
                    <div className="topic-description">{props.description}</div>
                </div>
                {props.parent ? props.link
                    ? <a aria-label={'View Resources for- ' + props.title} className="btn btn-sm btn-normal mb-1" target='_blank' rel='noreferrer'
                        href={props.link}>
                        <span className="d-flex justify-content-between align-items-center">
                            <i className="fad fa-window fa-flip-horizontal text-purple h3 m-0"></i>
                            <small className="ml-2 text-purple font-weight-bold text-left text-left">View Resources</small>
                        </span>
                    </a>
                    : <button aria-label={'View Resources for- ' + props.title} className="btn btn-sm btn-normal mb-1" type="button"
                        onClick={showModal}>
                        <span className="d-flex justify-content-between align-items-center">
                            <i className="fad fa-window fa-flip-horizontal text-purple h3 m-0"></i>
                            <small className="ml-2 text-purple font-weight-bold text-left text-left">View Resources</small>
                        </span>
                    </button>
                    : null}
            </div>
            {showEntity || showRole ?
                <div className="bread-bg">
                    <i className="fad fa-sticky-note mr-2"></i>
                    {showEntity || showRole ? <small className="text-muted">{props.entity.name}</small> : null}
                    {showRole && (props.entity.name !== props.role.name) ? <small className="text-muted"> - {props.role.name}</small> : null}
                </div> : null}
            {props.children?.length
                ? <NestedTopics taxonomies={props.children} />
                : null
            }
            {props.featured ?
                <>
                    <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                    <div className="clearfix"></div>
                    <span className="featured-tag mt-1 ml-1">
                        <small data-pr-tooltip="Featured topic" className="pr-3">
                            <i className="fad fa-sparkles mr-2" ></i>{props.featured}
                        </small>
                    </span>
                </>

                : null}

            <div className="d-flex justify-content-start align-items-center pt-2 m-0 border-top">
                
                <button data-pr-tooltip="Search Synod and Partner Websites"  aria-label="Search Synod and Partner Websites" type="button" className="btn btn-sm btn-link text-primary px-3 py-2" 
                onClick={() => search(props.keywords || props.title, SearchLevels.partnerResources, router, [])}>
                    <i className="far fa-search-plus mr-2" style={{fontSize: '18px'}}></i></button>
            </div>
        </div>
        {modalIsVisible ?
            <ResourcesDialog onHide={hideModal} resource={props} />
            : null}
    </>)
}

function NestedTopics(props: { taxonomies: SuggestedResource[] }) {
    return (
        <div className="row border-top">
            {props.taxonomies?.map((taxonomy, index) => <NestedTopic key={index} taxonomy={taxonomy} />)}
        </div>
    )
}

function NestedTopic(props: { taxonomy: SuggestedResource }) {

    const { visible: modalIsVisible, showModal, hideModal } = useModal();

    const { suggested } = useTaxonomyFilter();
    const baseClass = 'col-sm-6 col-md-4 mt-2 mb-1 ';

    return (
        <>
            <div className={baseClass + (props.taxonomy.featured ? 'featured' : '')}>
                <span className="subtopic-title medium">
                    {props.taxonomy.title}
                </span>
                <div>
                    {props.taxonomy.link
                        ?
                        <a aria-label={'View Resources for- ' + props.taxonomy.title} className="btn btn-sm btn-normal d-flex align-items-center" type="button"
                            target='_blank' rel='noreferrer' href={props.taxonomy.link}>
                            <i className="fad fa-window fa-flip-horizontal text-purple h4 m-0"></i>
                            <small className="ml-2 text-purple text-left">View Resources</small>
                        </a>
                        : <button aria-label={'View Resources for- ' + props.taxonomy.title} className="btn btn-sm btn-normal d-flex align-items-center" type="button"
                            onClick={showModal}>
                            <i className="fad fa-window fa-flip-horizontal text-purple h4 m-0"></i>
                            <small className="ml-2 text-purple text-left">View Resources</small>
                        </button>}

                    {props.taxonomy.children?.length ? (
                        <button aria-label={'View Resources for- ' + props.taxonomy.title} type="button" className="btn btn-sm btn-normal d-flex align-items-center"
                            onClick={() => { suggested.viewSubResources(props.taxonomy) }}>
                            <i className="far fa-plus-circle text-warning h5 m-0"></i>
                            <small className="ml-2 text-warning pl-1 text-left">More Like This</small>
                        </button>
                    ) : null}
                    {props.taxonomy.featured ?
                        <>
                            <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                            <div className="clearfix"></div>
                            <span className="featured-tag mt-1 ml-1">
                                <small data-pr-tooltip="Featured topic" className="pr-3">
                                    <i className="fad fa-sparkles mr-2" ></i>{props.taxonomy.featured}</small>
                            </span>
                        </>
                        : null}
                </div>
            </div>
            {modalIsVisible ? <ResourcesDialog onHide={hideModal} resource={props.taxonomy} /> : null}
        </>
    )
}