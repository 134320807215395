import { api } from '../api/api';
import { ResourceTypesResponse } from '../api/resource-types';
import { useApiResource } from '../use-api-resource';
import { ApiResource } from './api-resource';

const resourceTypesResource = new ApiResource<ResourceTypesResponse>(() => api.get.resourceTypes());

export async function getResourceTypes() {
    return await resourceTypesResource.get();
}

export function useResourceTypes() {
    return useApiResource(getResourceTypes);
}