import { Cookie } from '../cookies';

const ResourceHistoryCookie = new Cookie<VisitedResource[]>('resources', 90);

export interface VisitedResource {
    title: string;
    link: string;
    dateVisited: Date;
}

export function removeResource(index: number) {
    const resources = getRecentResources();
    ResourceHistoryCookie.set(resources.slice(0, index).concat(resources.slice(index + 1)));
}

export function saveResource(title: string, link: string) {
    if (!title || !link) { return; }

    const resources = getRecentResources().filter(t => t.link.toLocaleLowerCase() !== link.toLocaleLowerCase().trim());
    resources.unshift({
        link: link,
        title: title,
        dateVisited: new Date()
    });

    ResourceHistoryCookie.set(resources.slice(0, 15));
}

export function getRecentResources(): VisitedResource[] {
    const visitedResources = ResourceHistoryCookie.get();
    if (!visitedResources) return [];
    return visitedResources.map(r => { r.dateVisited = new Date(r.dateVisited); return r });
}