import { FeaturedTopics } from '../../FeaturedTopics/FeaturedTopics';

export function FeaturedSection() {
    return (
        <div className="mb-5">
            <h3 className="sb-bold-title"><i className="fad fa-sparkles mr-2"></i> Featured</h3> 
            <div className="container">
                <FeaturedTopics />
            </div>
        </div>
    );
}