import { useCallback, useState } from 'react';
import { api } from '../../../../_services/api/api';
import { DropdownFormGroup, DropdownFormGroupModel, DropdownItem } from '../../../__form/DropdownFormGroup';
import { EntityFilter } from '../../../../_models/taxonomy-filter/entity-filter';
import { RoleFilter } from '../../../../_models/taxonomy-filter/role-filter';

export function mapEntityToDropdown(entity: EntityFilter): DropdownItem {
    return {
        label: entity.label,
        value: entity.value
    }
}

export function mapRoleToDropdown(entity: RoleFilter): DropdownItem {
    return {
        label: entity.label,
        value: entity.value
    }
}

export function Taxonomy(props: { role: DropdownFormGroupModel; onRoleChange: (v: DropdownFormGroupModel) => void; entity: DropdownFormGroupModel; onEntityChange: (v: DropdownFormGroupModel) => void; }) {
    const [entities, setEntities] = useState<EntityFilter[]>();

    const { role, entity, onRoleChange, onEntityChange } = props;

    const loadEntities = useCallback(async () => {
        const result = await api.get.topicFilter();
        if (result) {
            setEntities(result);
        }

        return result?.filter(e => e.value !== 'all') || [];
    }, []);

    const loadRoles = useCallback(() => {
        return new Promise<RoleFilter[]>(accept => {
            const selected = entity.selected?.value;
            if (!selected) {
                accept([]);
            } else {
                const selectedEntity = entities?.filter(e => e.value === selected)[0];
                accept(selectedEntity?.roles.filter(r => r.value !== 'all') || []);
            }
        });
    }, [entities, entity.selected?.value]);

    return <>
        <DropdownFormGroup
            value={entity.selected} onChange={onEntityChange}
            label="ENTITY"
            fieldName="Group" name="group"
            required showClear
            load={loadEntities}
            map={mapEntityToDropdown}
            className="w-100" />

        <DropdownFormGroup
            value={role.selected} onChange={onRoleChange}
            label="ROLE"
            fieldName="Role" name="role"
            required showClear
            load={loadRoles}
            map={mapRoleToDropdown}
            className="w-100" />
    </>;
}
