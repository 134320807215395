import { Tooltip } from 'primereact/tooltip';
import { Skeleton } from 'primereact/skeleton';
import { AsyncResource } from '../AsyncResource/AsyncResource';
import { saveResource } from '../../_services/user-activity/resource-history';
import { api } from '../../_services/api/api';
import { useRecentResources } from '../../_services/api-resources/recent-resources';
import { EmptyResultSet } from './EmptyResultSet';

export function RecentlyAddedResources(props: { filter: string; }) {

    const trendingResources = useRecentResources();

    const onClick = async (title: string, link: string) => {
        saveResource(title, link);
        await api.post.trackResource({
            link,
            title
        });
    };

    return (
        <div className="bg-white rounded shadow-sm p-3">
            <h4 className="page-heading"><i className="fal fa-star-shooting px-2"></i> Recently Added Resources</h4>
            <hr />
            <AsyncResource resource={trendingResources}
                errorText="Unable to retrieve trending resources"

                render={(resources) =>
                    <>
                        <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                        {resources?.filter(r => !props.filter || r.title.toLocaleLowerCase().indexOf(props.filter.toLocaleLowerCase()) >= 0)
                            .map((s) =>
                                <div key={s.link} className="mb-3 d-flex justify-content-between align-items-center flex-wrap">
                                    <a className="btn btn-secondary text-dark btn-block rounded-0 text-left normalize"
                                        href={s.link} target="_blank" rel="noreferrer"
                                        onClick={() => onClick(s.title, s.link)}
                                    > 
                                        <span className="float-left"><i className="text-muted far fa-external-link-alt mr-2"></i>{s.title}</span>
                                        <span className="float-right text-date">{s.modifiedGmt?.toLocaleDateString()}</span>
                                        <div className="clearfix"></div>
                                        <small className="s-url ml-0">{s.link}</small>
                                    </a>
                                </div>
                            ) || <EmptyResultSet />}
                            {resources?.length === 0 ? <EmptyResultSet /> : null}
                    </>
                } 
                loading={
                    <div>
                        <div className="bg-purple p-3 text-white w-50 rounded shadow-sm">
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            <span className="pl-2">Loading...</span>
                        </div>
                        {Array.from({ length: 3 }).map((_x, i) =>
                            <Skeleton key={i} height="6em" width="100%" className="mb-4" />
                        )}
                    </div>
                }
                />
                
        </div>

    );
}
