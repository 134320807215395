import { useState, useEffect, useCallback } from 'react';
import { ErrorLog } from './error-log';

export interface ApiResource<T> {
    loading: boolean;
    hadError: boolean;
    resource?: T;
    reload: () => void
}

export function useApiResource<T>(apiCall: () => Promise<T>) {
    const [resource, setResource] = useState<{
        loading: boolean;
        hadError: boolean;
        resource?: T;
    }>({
        loading: true,
        hadError: false,
        resource: undefined,
    });

    const reload = useCallback(() => {
        setResource({
            loading: true,
            hadError: false,
            resource: undefined
        });
        apiCall()
            .then(result => {
                setResource({
                    loading: false,
                    hadError: false,
                    resource: result
                });
            }, (error) => {
                ErrorLog.log(error)
                setResource({
                    loading: false,
                    hadError: true,
                    resource: undefined
                });
            })

    }, [apiCall]);

    const [result, setResult] = useState<ApiResource<T>>({
        ...resource,
        reload: reload
    });


    useEffect(() => {
        reload()
    }, [reload]);

    useEffect(() => {
        setResult({
            ...resource,
            reload: reload
        })
    }, [resource, reload]);


    return result
}