import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { AdminTrendingTopicFilter } from '../../../_models/admin/admin-trending-topic-filter';
import { SortType } from '../../../_models/admin/sort-type';
import endpoints from '../api-endpoint-definition.json';

export type TrendingTopicFiltersResponse = AdminTrendingTopicFilter[]

const definition = new ApiCall<TrendingTopicFiltersResponse>().post('trendingTopicFiltersAdmin', { 
    route: endpoints.adminTrendingTopicFilters,
    params: {
        sort: {
            type: '' as SortType
        },
        filter: {
            type: ParameterTypes.String,
            optional: true
        },
        active: {
            type: ParameterTypes.Boolean,
            optional: true
        }
    }
})
export default definition;