export class ApiResource<T> {
    constructor(private getResource: () => Promise<T | null>) { }

    private cachedResponse: T | null = null;
    private currentPromise?: Promise<T | null>;

    public clearCache(): ApiResource<T> {
        this.cachedResponse = null;
        return this;
    }
    
    public async get(): Promise<T | null> {
        if (this.cachedResponse) { return this.cachedResponse; }

        if (this.currentPromise) {
            return this.currentPromise;
        }

        this.currentPromise = this.getResource();
        this.cachedResponse = await this.currentPromise;
        this.currentPromise = undefined;
        return this.cachedResponse;
    }
}
