import { Redirect } from 'react-router-dom';
import MainLayout from '../_layouts/MainLayout';
import { useOnComponentMount } from '../_services/use-on-component-mount';
import { useAuthenticatedUser } from '../_services/user-context';
import { LandingPageUrl } from './Landing';

export const LogoutPageUrl = '/logout'

export default function LogoutPage() {

    const userContext = useAuthenticatedUser();

    useOnComponentMount(() => {
        userContext.logout();
    })

    if (!userContext.user) {
        return (
            <Redirect to={LandingPageUrl} />
        )
    }

    return (
        <MainLayout>
            <div className="shadow-sm rounded p-5 bg-white text-center h6">
                Logging Out...
            </div>
        </MainLayout>
    )
}
