import { api } from '../api/api';
import { TrendingResourcesResponse } from '../api/trending-resources';
import { useApiResource } from '../use-api-resource';
import { ApiResource } from './api-resource';

const trendingResources = new ApiResource<TrendingResourcesResponse>(() => api.get.trendingResources());

export function clearTrendingResource() {
    trendingResources.clearCache();
}

export async function getTrendingResources() {
    return await trendingResources.get();
}

export function useTrendingResource() {
    return useApiResource(getTrendingResources)
}