import { Link } from 'react-router-dom';
import { AboutPageUrl } from '../views/About';


export default function Welcome() {

    return (
        <div className="bg-white px-4 py-3 shadow-sm">
            <h3 className="text-uppercase title">Welcome</h3>
            <p>Making Disciples for Life is the triennial emphasis of The Lutheran Church—Missouri Synod and is a partnership between individuals, congregations, districts, and the Synod to evangelize the lost, retain the faithful, strengthen congregations, and plant churches and schools. </p>
            <p>Conferences, grants, and a resource center comprise the means by which resources are shared and utilized to benefit the work of the church.</p>
            <div className="d-flex justify-content-between align-items-start flex-wrap">
                <a className="btn btn-default-lighten mb-2" href="/overview.pdf" rel="noreferrer" target = "_blank"><i className="mr-2 fal fa-file-pdf text-primary text-increase"></i> How To Use</a>
                <Link to={AboutPageUrl} className="btn btn-default-lighten">
                    Learn more about the MDFL initiative
                </Link>
            </div>

        </div>

    );
}

