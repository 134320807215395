import React, { PropsWithChildren } from 'react';
import SearchPanelCondensed from '../_components/SearchPanelCondensed/SearchPanelCondensed';
import Welcome from '../_components/Welcome';
import MainLayout from './MainLayout';

export default function LandingLayout(props: PropsWithChildren<any>) {
    return (
        <MainLayout>
            <div className="row mb-4">
                <div className="col-sm-12 col-md-5 col-lg-7 d-flex">
                    <Welcome />
                </div>
                <div className="col-sm-12 col-md-7 col-lg-5 d-flex mt-5">
                    <SearchPanelCondensed />
                </div>
            </div>
            {props.children}
        </MainLayout>
    )
}