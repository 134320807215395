import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { SubPageSidebarResponsive } from '../_components/Sidebar/SubPageSidebarResponsive';
import { AboutPanel } from '../_components/_About/AboutPanel';
import { Sidebar } from '../_components/_About/Sidebar';
import MainLayout from '../_layouts/MainLayout';
import { scrollToTop } from '../_services/scroll-to-top';
import { useOnComponentMount } from '../_services/use-on-component-mount';

export const AboutPageUrl = '/about'
export const AboutPageUrlRoute = '/about/:tab?'
export interface AboutPageUrlParams { tab?: string }

export default function AboutPage(props: PropsWithChildren<{ location: { pathname: string } }>) {    
    useOnComponentMount(() => {
        scrollToTop(true);
    })
    
    return (
        <MainLayout>
            <div className="row mt-5">
                <div className="col-sm-12 col-md-3 sb-main">
                    <Sidebar />
                </div>
                <SubPageSidebarResponsive pageTitle={props.location.pathname.indexOf('how-to-use') ? 'How to Use' : 'About'} 
                    hasSearch={false}
                    prependMenuItems={(<>
                        <div className="mb-5">
                            <h3 className="sb-bold-title"><i className="fad fa-info-square mr-2"></i> About</h3>
                            <Link to={AboutPageUrl}>
                                <div className="btn btn-sm btn-block rounded-0 sb-btn btn-secondary cursor-pointer">About</div>
                            </Link>
                            <Link to={AboutPageUrl + '/how-to-use'}>
                                <div className="btn btn-sm btn-block rounded-0 sb-btn btn-secondary cursor-pointer">How to use</div>
                            </Link>
                        </div>
                    </>)}
                />
                <div className="col-sm-12 col-md-9 topics-main">
                    <AboutPanel />
                </div>
            </div>
        </MainLayout>

    )
}
