import { Dictionary } from '@lcms/helpers';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import React, { useState } from 'react';
import { useModal } from '../../../_services/use-modal';
import { TaxonomyTable } from './Table/TaxonomyTable';
import { useAdminTaxonomy } from './use-admin-taxonomy';

export function Taxonomy(props: { toast: React.RefObject<Toast> }) {

    const { visible, showModal, hideModal } = useModal();

    return <>
        <button className="btn btn-primary mb-2" type="button" onClick={showModal}>
            <i className="fal fa-sitemap mr-2"></i>
            Manage Taxonomy
        </button>
        {visible ? <Modal toast={props.toast} onHide={hideModal} /> : null}
    </>;
}


function Loading() {
    return (
        <Skeleton width='100%' height='20em' />
    )
}

function Error() {
    return <span className="text-danger">ERROR</span>
}

function Modal(props: { toast: React.RefObject<Toast>; onHide: () => void; }) {
    const topicRequest = useAdminTaxonomy();
    const [expandedKeys, setExpandedKeys] = useState<Dictionary<boolean>>({});

    const body = () => {
        if (topicRequest.state === 'error') {
            return <Error />
        }

        if (topicRequest.state !== 'done') {
            return <Loading />
        }

        return <TaxonomyTable
            toast={props.toast}
            entities={topicRequest.data.entities}
            roles={topicRequest.data.roles}
            reload={topicRequest.reload}
            expandedKeys={expandedKeys}
            onExpandToggle={setExpandedKeys}
        />
    }
    return (
        <Dialog visible
            onHide={props.onHide}
            header={<>Manage Entities and Roles</>}
            style={{width: '85vw'}}
            blockScroll={true}
            draggable={false}
        >
            {body()}
        </Dialog>
    )
}