import { Cookie } from '../cookies';

const SearchHistoryCookie = new Cookie<string[]>('searches', 90);

export function removeSearch(index: number) {
    const searches = getRecentSearches();
    SearchHistoryCookie.set(searches.slice(0, index).concat(searches.slice(index + 1)));
}

export function saveSearch(term?: string) {
    if (!term) { return; }

    const searches = getRecentSearches().filter(t => t.toLocaleLowerCase() !== term.toLocaleLowerCase().trim());
    searches.unshift(term.trim());
    SearchHistoryCookie.set(searches.slice(0, 15));
}

export function getRecentSearches(): string[] {
    const previousSearches = SearchHistoryCookie.get();
    if (!previousSearches) return [];
    return previousSearches as string[];
}