import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import endpoints from '../api-endpoint-definition.json';
import { ApiResponse } from './api-response';

const definition = new ApiCall<ApiResponse>().post('blockTrendingResource', { 
    route: endpoints.adminTrendingResourcesBlock,
    params: {
        id: {
            type: ParameterTypes.String
        }
    }
})
export default definition;