import { useCallback, useEffect, useState } from 'react';
import { ToggleButton } from 'primereact/togglebutton';
import { useHistory, useLocation } from 'react-router-dom';
import { RecentlyAddedPageUrl } from '../../views/RecentlyAdded';
import { LandingPageUrl } from '../../views/Landing';
import { useTaxonomyFilter } from '../../_services/taxonomy-filter-context';
import { useOnComponentMount } from '../../_services/use-on-component-mount';
import { getRecentlyAdded } from '../../_services/api-resources/recent-resources';

const baseClass = 'btn-sm rounded-pill tb-yellow';
export function RecentlyAdded(props: { hideOnMobile?: boolean; }) {
    const location = useLocation();
    const router = useHistory();
    const { setFilter, filter } = useTaxonomyFilter();
    const [checked, setChecked] = useState(false);
    const [hasRecentlyAdded, setHasRecentlyAdded] = useState(false);

    useOnComponentMount(() => {
        getRecentlyAdded()
        .then(results => {
            if (results?.length) {
                setHasRecentlyAdded(true);
            }
        })
    })


    useEffect(() => {
        setChecked(location.pathname?.toLowerCase() === RecentlyAddedPageUrl);
    }, [location.pathname])

    const toggle = useCallback((e: { value: boolean }) => {
        if (e.value) {
            router.push(RecentlyAddedPageUrl);
            setFilter({
                entity: filter.entity,
                role: filter.role,
                term: filter.term,
                featured: undefined
            })
        } else {
            router.push(LandingPageUrl);
        }
    }, [router, filter, setFilter])

    if (!hasRecentlyAdded) {
        return null;
    }

    return (
        <ToggleButton checked={checked}
            onChange={toggle}
            onLabel={'Recently Added'}
            offLabel={'Recently Added'}
            iconPos="right"
            className={baseClass + (props.hideOnMobile ? ' d-sp-none' : '')}
            onIcon="fal fa-times" offIcon="fal fa-star-shooting"
            tooltip={checked ? 'remove filter' : 'apply filter'} />
    )
}