import { TaxonomyFilterResponse } from '../api/admin/taxonomy-filters';
import { api } from '../api/api';
import { useApiResource } from '../use-api-resource';
import { ApiResource } from './api-resource';

const adminTaxonomy = new ApiResource<TaxonomyFilterResponse>(() => api.get.resourceFilter());

export function clearAdminTaxonomy() {
    adminTaxonomy.clearCache();
}

export async function getAdminTaxonomy() {
    return await adminTaxonomy.get();
}

export function useAdminTaxonomy() {
    return useApiResource(getAdminTaxonomy);
}