import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { LandingPageUrl } from '../views/Landing';
import NamePlate from '../_components/NamePlate';
import { Footer } from '../_components/Footer/Footer';
import Page from '../_components/Page';
import RandomBackground from '../_components/RandomBackground';

export default function MainLayout(props: PropsWithChildren<any>) {
    return (
        <Page>
            <div id="anchor-page-top"></div>
            <section className="section section-lg pt-5" style={{ minHeight: '100vh' }}>
                <RandomBackground />

                <div className="container main">
                    <div className="row mb-5">
                        <div className="col">
                            <Link to={LandingPageUrl}>
                                <NamePlate />
                            </Link>
                        </div>
                    </div>

                    {props.children}

                </div>
            </section>
            <Footer />
        </Page>
    )
}

// export function Footer() {
//     const userContext = useAuthenticatedUser();

//     if (!userContext.user) {
//         return null;
//     }

//     return (
//         <button className="btn btn-link" onClick={() => userContext.logout()}>Logout - {userContext.user.username}</button>
//     )
// }