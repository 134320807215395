import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import endpoints from '../api-endpoint-definition.json';
import { ApiResponse } from './api-response';

const definition = new ApiCall<ApiResponse>().post('setTrendingTopicFilterVisibility', { 
    route: endpoints.adminTrendingTopicFiltersVisibility,
    params: {
        id: {
            type: ParameterTypes.String
        },
        visibility: {
            type: ParameterTypes.Boolean
        }
    }
})
export default definition;