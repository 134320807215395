import { Dialog } from 'primereact/dialog';
import { ModalProps } from '../../_models/modal-props';

export function HowIsMyDataUsedDialog(props: ModalProps) {
    return (
        <Dialog draggable={false} blockScroll={true} visible onHide={props.onHide} style={{ width: '50vw' }}
            header={
                <h4><i className="fab fa-connectdevelop mr-2 fa-2x text-purple"></i>Building a better experience</h4>
            }
            footer={
                <button aria-label="Close dialog" className="btn btn-secondary" type="button" onClick={props.onHide}>Close</button>
            }
        >
            <p className="mb-0"><b>We use cookies... but we don't share</b></p>
            <p className="mb-0">We use cookies and other tracking technologies to improve your browsing experience on our website. Your interactions help us to build a better experience for all users.</p>

            <div className="safe-secure d-flex justify-content-start align-items-center">
                <i className="fal fa-user-lock mr-2"></i>
                <p className="mb-0 mt-2">The data collected pertains to this website only. It is never sold or distributed.</p>
            </div>

            <h4 className="border-bottom data-map-title"><i className="far fa-shapes mr-3 wobble animated"></i>As you interact</h4>
            <div className="d-flex justify-content-around text-center data-map">
                <div className="m-2 p-2 bg-secondary rounded flex-fill">
                    <h5>Suggested Topics</h5>
                </div>
                <div className="m-2 p-2 bg-secondary rounded flex-fill">
                    <h5>Search</h5>
                </div>
            </div>
            <h4 className="border-bottom data-map-title">The system reacts<i className="fas fa-shapes ml-3 wobble animated animated-delay"></i></h4>
            <div className="d-flex justify-content-around text-center data-map">
                <div className="m-2 p-2 bg-white rounded flex-fill">
                     <small>Helps us to identify, create and distribute resources that are trending or missing</small>
                </div>
            </div>
            <div className="d-flex justify-content-around text-center data-map">
                <div className="m-2 p-2 bg-secondary rounded flex-fill">
                    <h5>Trending Resources</h5>
                </div>
                <div className="m-2 p-2 bg-secondary rounded flex-fill">
                    <h5>Trending Searches</h5>
                </div>
                <div className="m-2 p-2 bg-secondary rounded flex-fill">
                    <h5>Recommend Search Terms</h5>
                </div> 
                <div className="m-2 p-2 bg-secondary rounded flex-fill">
                    <h5>Better Content</h5>
                </div>
            </div>
        </Dialog>
    );
}