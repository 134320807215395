import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import MainLayout from '../_layouts/MainLayout';
import { adminAuthorization } from '../_services/auth/azure-service';
import { useQueryString } from '../_services/query-string';
import { scrollToTop } from '../_services/scroll-to-top';
import { useOnComponentMount } from '../_services/use-on-component-mount';
import { useAuthenticatedUser } from '../_services/user-context';
import { LandingPageUrl } from './Landing';

export const LoginPageUrl = '/login'

export default function LoginPage() {

    useOnComponentMount(() => {
        scrollToTop(true);
        const userSubscription = adminAuthorization.user.subscribe(user => {
            if (user) {
                onLogin(user);
            }
        })
        const setSsoSubscription = adminAuthorization.loggingIn.subscribe(loggingIn => {
            setSso(loggingIn);
        })

        return () => {
            userSubscription.unsubscribe();
            setSsoSubscription.unsubscribe();
        }
    })

    const { login: onLogin, user } = useAuthenticatedUser();
    const [sso, setSso] = useState(false);
    const [username, setUsername] = useState('');
    const redirect = useQueryString().redirect?.single;

    const usernameChange = async (newValue: string) => {
        setUsername(newValue);
        if (newValue.endsWith('@lcms.org')) {
            setSso(true);

            const user = await adminAuthorization.login(newValue);
            if (user) {
                onLogin(user);
            } else {
                setSso(false);
            }
        }
    }

    const login = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }

    if (user) {
        return (
            <Redirect to={redirect ? decodeURIComponent(redirect) : LandingPageUrl} />
        )
    }

    return (
        <MainLayout>
            {(sso) ? (
                <div className="shadow-sm rounded p-5 bg-white text-center h6">
                    Logging in with SSO...
                </div>
            ) : (
                    <div className="col-12 bg-white p-4 m-3 rounded shadow-sm">
                        <h3>Login to your account</h3>
                        <div className="col-5  p-4 m-3">
                            <form onSubmit={ev => login(ev)}>
                                <div className="form-group">
                                    <label htmlFor="username">Username</label>
                                    <div className="input-group">
                                        <input value={username} className="form-control" type="text" onChange={ev => usernameChange(ev.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <div className="input-group">
                                        <input className="form-control" type="password" />
                                    </div>
                                </div>

                                <button className="btn btn-primary" aria-label="Login">Login</button>
                            </form>
                        </div>
                    </div>
                )}
        </MainLayout>

    )
}
