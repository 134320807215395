import React from 'react';
import { UserActivitySection } from './Sections/UserActivitySection';
import { FeaturedSection } from './Sections/FeaturedSection';
import { TrendingSection } from './Sections/TrendingSection';

export function SidebarSections() {
    return (
        <>
            <FeaturedSection />
            <TrendingSection />
            <UserActivitySection />
        </>
    );
}