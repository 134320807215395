import React, { useState } from 'react';
import { compare } from '@lcms/helpers';
import { SearchLevels } from '../../../_models/search/search-levels';
import { WordPressSearchResult } from '../../../_models/search/search-result';
import { useSearchParams } from '../../../_services/search-params';
import { SortMethod, WordpressResultSort } from './WordpressResultSort';
import { SearchResult } from '../SearchResult';
import { EmptyResultSet } from './EmptyResultSet';

export function WordpressResults(props: { results: WordPressSearchResult[] }) {

    const searchParams = useSearchParams();
    const [sort, setSort] = useState<SortMethod>('relevance')

    if (searchParams.level !== SearchLevels.recommendedResources) {
        return null;
    }

    if (!props.results?.length) {
        return (
            <EmptyResultSet />
        );
    }

    return (
        <>
            <div className="bg-white px-3 py-1 border-top border-bottom">
                <div className="float-right"> <WordpressResultSort setSort={setSort} sort={sort} /> </div>
                <div className="clearfix"></div>
            </div>
            {props?.results.sort(
                sort === 'date' ? compare(r => r.date || new Date(1900, 1, 1), 'desc') : compare(r => r.relevance, 'desc')
            ).map(r =>
                <SearchResult key={r.url.actual} result={r} />
            )}
        </>
    )
}