import { useState, useEffect } from 'react';
import { Dictionary } from '@lcms/helpers';
import { useLocationSearch } from './use-location-search';


export interface QueryValue {
    single: string;
    array: string[];
}

export function useQueryString(): Dictionary<QueryValue> {

    const [queryParams, setQueryParams] = useState<Dictionary<QueryValue>>({});
    const routerLocation = useLocationSearch()

    useEffect(() => {
        setQueryParams(parseQueryString(routerLocation));
    }, [routerLocation])

    return queryParams;
}

export function parseQueryString(queryString: string): Dictionary<QueryValue> {
    const entries = queryString.split('&');
    const params: Dictionary<QueryValue> = {};

    for (let i = 0; i < entries.length; i++) {
        const pair = entries[i].split('=');
        const current = params[pair[0]];
        if (current) {
            current.array.push(decodeURIComponent(pair[1]))
        } else {
            params[pair[0]] = {
                single: decodeURIComponent(pair[1]),
                array: [decodeURIComponent(pair[1])]
            }
        }
    }

    return params;
}

export function queryString(values: Dictionary<string | string[]>): string {
    const queries: string[] = [];
    for (const property in values) {
        if (values.hasOwnProperty(property)) {
            const value = values[property];
            if (typeof value === 'string') {
                queries.push(`${property}=${encodeURIComponent(value)}`);
            } else {
                value.map(subValue =>
                    queries.push(`${property}=${encodeURIComponent(subValue)}`)
                )
            }

        }
    }

    return '?' + queries.join('&');
}