import { useOnComponentMount } from '../../_services/use-on-component-mount';
import { FormGroup, FormGroupModel } from '../__form/FormGroup';

export interface LinksModel {
    values: FormGroupModel[];
    isValid: boolean;
    isDirty: boolean;
}

export function LinksInput(props: { links: FormGroupModel[]; onChange: (links: LinksModel) => void; }) {

    const onChange = (newValues: FormGroupModel[]) => {
        props.onChange({
            values: newValues,
            isValid: newValues.filter(e => !e.isValid).length === 0,
            isDirty: newValues.filter(e => e.isDirty).length > 0
        });
    };

    const newLink = () => onChange(props.links.concat([{
        value: '',
        isValid: false,
        isDirty: true
    }]));

    useOnComponentMount(() => {
        props.onChange({
            values: props.links,
            isValid: props.links.filter(e => !e.isValid).length === 0,
            isDirty: props.links.filter(e => e.isDirty).length > 0
        });
    });


    return (
        <div className="form-group has-subs">
            <label htmlFor="links">LINKS
                <div>
                    <small>Please enter the url for the resource you'd like to share.</small>
                </div>
            </label>
            {props.links.map((link, i) => <div key={i} className="d-flex w-100">
                <div className="flex-fill">
                    <FormGroup
                        key={`link-${i}`}
                        name={`link-${i}`}
                        value={link.value}
                        onChange={(e) => {
                            const newValues = props.links.slice(0, i)
                                .concat([e])
                                .concat(props.links.slice(i + 1));

                            onChange(newValues);
                        }}
                        maxLength={500}
                        required showCharacterCount />
                </div>

                <button aria-label="Remove Link" className="btn btn-link text-danger" onClick={() => {
                    const newValues = props.links.slice(0, i)
                        .concat(props.links.slice(i + 1));

                    onChange(newValues);
                }}>
                    <i className="fal fa-minus-circle"></i>
                </button>
            </div>
            )}
            <div className="d-flex">
                <button aria-label="Add Link" className="btn btn-link" onClick={newLink}>
                    <i className="fal fa-plus-circle"></i> Add Link
                </button>
            </div>
        </div>
    );
}
