import { NodeData } from '../TaxonomyTable';

export function AddRoleButton(props: { node: NodeData; showModal: (node: NodeData) => void; }) {
    return <button type="button" className='btn btn-sm btn-icon-only btn-warning rounded-circle'
        data-pr-tooltip="Add role"
        onClick={() => props.showModal({
            type: 'role',
            active: true,
            default: false,
            effectivelyActive: true,
            entity: props.node.entity,
            id: '',
            isRoot: false,
            name: '',
            relationship: {
                id: '',
                isActive: true,
                isDefault: false,
                role: {
                    id: '',
                    isActive: true,
                    name: ''
                },
                topicCount: 0
            },
            role: {
                entities: [],
                id: '',
                isActive: true,
                name: ''
            }
        })}
    ><i className="fas fa-plus"></i></button>;
}
