import endpoints from '../api-endpoint-definition.json';
import { ApiCall } from '@lcms/api-definition';
import { LabelValue } from '../../../_models/label-value';

export type WordPressTopicsResponse = LabelValue[];

const definition = new ApiCall<WordPressTopicsResponse>().get('wordPressTopics', {
    route: endpoints.adminWordpressTopics
})
export default definition;

