import { useCallback, useState } from 'react';
import { clearAdminTaxonomy } from '../../../../../_services/api-resources/admin-taxonomy';
import { api } from '../../../../../_services/api/api';
import { NodeData } from '../TaxonomyTable';

export function RestoreButton(props: { node: NodeData; onRestore: () => void; }) {

    const [restoring, setRestoring] = useState(false);

    const { node, onRestore } = props;
    const onClick = useCallback(() => {
        setRestoring(true);
        const call = node.type === 'entity' ?
            api.post.setEntityVisibility({
                id: node.entity.id,
                visibility: true
            }) :
            api.post.setRoleVisibility({
                id: node.relationship.id,
                visibility: true
            })

        call.then(r => {
            if (r?.success) {
                clearAdminTaxonomy()
                onRestore();
            }
            setRestoring(false);
        });
    }, [node, onRestore ]);

    return <button type="button" className='btn btn-sm btn-icon-only'
        data-pr-tooltip="Restore Topic"
        onClick={onClick} disabled={restoring}
    >
        {restoring ? <i className="fad fa-spin fa-spinner"></i> : <i className="fad fa-trash-restore text-primary fa-2x"></i>}
    </button>;
}
