import { useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useSearchParams } from '../../_services/search-params';
import { SearchLevels } from '../../_models/search/search-levels';

const tabs = [
    SearchLevels.recommendedResources,
    SearchLevels.partnerResources
].map(t => t.toString());

export function SearchLevel(props: { level: string; setLevel: (level: string) => void; }) {
    const { level, setLevel } = props;

    const { level: searchLevel } = useSearchParams();

    useEffect(() => {
        if (searchLevel) {
            setLevel(searchLevel);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchLevel]);

    return (
        <TabView activeIndex={tabs.indexOf(level)} onTabChange={(e: { index: number; }) => setLevel(tabs[e.index])}>
            <TabPanel header="MDFL Library Catalog">
            </TabPanel>
            <TabPanel header="Synod and Partner Websites">
            </TabPanel>
        </TabView>
    );
}
