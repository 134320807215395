import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { AsyncResource } from '../AsyncResource/AsyncResource';
import { useTrendingResource } from '../../_services/api-resources/trending-resources';
import { saveResource } from '../../_services/user-activity/resource-history';
import { api } from '../../_services/api/api';

export function TrendingResources(props: { filter: string; }) {

    const trendingResources = useTrendingResource();

    const onClick = async (title: string, link: string) => {
        saveResource(title, link);
        await api.post.trackResource({
            link,
            title
        });
    };

    return (
        <div className="bg-white rounded shadow-sm p-3">
            <h4 className="page-heading"><i className="fal fa-chart-line px-2"></i> Trending Resources</h4>
            <hr />
            <AsyncResource resource={trendingResources}
                errorText="Unable to retrieve trending resources"

                render={(resources) =>
                    <>
                        <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                        {resources?.filter(r => !props.filter || r.title.toLocaleLowerCase().indexOf(props.filter.toLocaleLowerCase()) >= 0)
                            .map((s) =>
                                <div key={s.link} className="mb-3 d-flex justify-content-between align-items-center flex-wrap">
                                    <a className="btn btn-secondary text-dark btn-block rounded-0 text-left normalize"
                                        href={s.link} target="_blank" rel="noreferrer"
                                        onClick={() => onClick(s.title, s.link)}
                                    >
                                        <span className="float-left"><i className="text-muted far fa-external-link-alt mr-2"></i>{s.title}</span>
                                        <div className="clearfix"></div>
                                        <small className="s-url ml-0">{s.link}</small>
                                    </a>
                                    <a className="btn btn-sm btn-text text-warning"
                                        href={`mailto:mdfl@lcms.org?body=You are reporting an issue with the following external resource ${s.link}.%0D%0A%0D%0APlease provide more detail below to allow us to contact the owner of the external resource and resolve the issue.%0D%0A----Example reasons... the resource link is broken, the resource appears compromised or suspicious.----&subject=MDFL Issue with External Resource`}
                                    ><small><i className="fal fa-comment-exclamation fa-flip-horizontal fa-2x mr-2"></i>Report an Issue</small></a>
                                </div>
                            )}
                    </>
                } />
        </div>

    );
}
