import endpoints from './api-endpoint-definition.json';
import { WordPressResult } from '../../_models/search/wordpress-result';
import { ApiCall, ParameterTypes } from '@lcms/api-definition';

export type TopicResourcesResponse = WordPressResult[] | null;

const definition = new ApiCall<TopicResourcesResponse>().post('getTopicResources', {
    route: endpoints.topicsResources,
    params: {
        ids: {
            type: ParameterTypes.StringArray
        },
        rootId: {
            type: ParameterTypes.String
        }
    },
    process: results => {
        results?.map(r => {
            r.modifiedGmt = new Date(r.modifiedGmt);
            return r;
        })
    }
})
export default definition;