import { PropsWithChildren, useEffect, useState } from 'react';
import { User } from '../../_models/user';
import { adminAuthorization } from '../../_services/auth/azure-service';
import { UserService } from '../../_services/auth/user-service';
import UserContext, { UserContextPayload } from '../../_services/user-context';

export default function UserProvider(props: PropsWithChildren<any>) {
    const [user, setUser] = useState<User>();
    const [sso, setSso] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [context, setContext] = useState<UserContextPayload>({
        login: () => { },
        logout: () => { },
    });


    useEffect(() => {
        const checkLogin = async () => {

            let ssoUser: User | null = null;
            const user = await UserService.whoAmI(async sso => {
                setSso(true);
                if (sso.vendor === 'azure') {
                    ssoUser = await adminAuthorization.login(sso.email);
                }
            });
            if (user) {
                setUser(user);
            } else if (ssoUser) {
                setUser(ssoUser);
            } else {
                setSso(false);
            }

            setLoaded(true);
        }

        checkLogin();
    }, []);

    useEffect(() => {
        async function logout() {
            if (!adminAuthorization.logout()) {
                await UserService.logout();
                const user = await UserService.whoAmI();
                if (!user) {
                    setUser(undefined);
                }
            }
        }

        setContext({
            logout: logout,
            login: (user: User) => setUser(user),
            user: user
        });

    }, [user]);




    return (
        <UserContext.Provider value={context}>
            {(sso) ? (
                <div className="text-center">
                    Logging in with SSO...
                </div>
            ) : null}

            {loaded ? props.children : null}
        </UserContext.Provider>
    )
}