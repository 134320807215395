import React from 'react';
import { ContributeButton } from '../__buttons/ContributeButton';
import { RequestResourceButton } from '../__buttons/RequestResourceButton';

export function HeaderButtons() {
    return (
        <>
            <ContributeButton />
            <RequestResourceButton />
        </>
    );
}