import { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export type SortMethod = 'relevance' | 'date';

export function WordpressResultSort(props: { sort: SortMethod; setSort: (method: SortMethod) => void }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <>
            <small>Sort by: </small>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="result-sort">
                <DropdownToggle caret>
                    {props.sort === 'date' ? 'Date' : 'Relevance'}
                </DropdownToggle>
                <DropdownMenu>
                    {props.sort !== 'relevance' ?
                        <DropdownItem onClick={() => props.setSort('relevance')}>Relevance</DropdownItem>
                        : null}
                    {props.sort !== 'date' ?
                        <DropdownItem onClick={() => props.setSort('date')}>Date</DropdownItem>
                        : null}
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

