export class Cookie<T> {
    constructor(private cookieName: string, private lifetimeDays?: number) { }

    get(defaultValue?: T): T | null {
        const cookie = Cookies.get(this.cookieName);
        if (!cookie) {
            if (typeof defaultValue === 'undefined'){
                return null;
            }
            return defaultValue;
        }
        return JSON.parse(cookie) as T;
    }

    set(value: T, lifetimeDays?: number): void {
        Cookies.set(this.cookieName, JSON.stringify(value), this.lifetimeDays || lifetimeDays);
    }
}

export default class Cookies {
    static set(name: string, value: string, lifetimeDays?: number): void {
        let expires = '';
        if (lifetimeDays) {
            var d = new Date();
            d.setTime(d.getTime() + (lifetimeDays * 24 * 60 * 60 * 1000));
            expires = 'expires=' + d.toUTCString();
        }

        document.cookie = name + '=' + value + ';' + expires + ';path=/;SameSite=Strict';
    }

    static get(name: string): string {
        const key = name + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = decodedCookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(key) === 0) {
                return cookie.substring(key.length, cookie.length);
            }
        }
        return '';
    }
}