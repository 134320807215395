import { useTaxonomyFilter } from '../../_services/taxonomy-filter-context';
import { SectionHeader } from '../Header/SectionHeader';

export function Header(props: { showTableOfContents: boolean; tableOfContentsToggle: (show: boolean) => void; }) {

    const taxonomy = useTaxonomyFilter();
    const names = taxonomy.getFilterNames();

    const onFilter = (term: string) => {
        taxonomy.setFilter({
            entity: taxonomy.filter.entity,
            role: taxonomy.filter.role,
            featured: taxonomy.filter.featured,
            term: term,
        });
    }

    return (
        <>
            <SectionHeader
                filterProps={{
                    placeholder: 'Narrow suggested topics...',
                    defaultValue: taxonomy.filter.term
                }}
                onFilter={onFilter}
                pageTitle={
                    <div className="mb-2">
                        <p className="text-uppercase mb-0">
                            Suggested Topics
                        </p>
                        {names.entity ?
                            <>
                                <b>{names.entity}</b>{names.entity !==  names.role ? ': ' + names.role : null}
                            </>
                            : null}
                    </div>
                }
                additionalContent={
                    <button type="button" className="btn cta-interact btn-sm toggle-toc mt-1 p-2"
                        onClick={() => props.tableOfContentsToggle(!props.showTableOfContents)}
                    >
                        <i className={`far ${props.showTableOfContents ? 'fa-times' : 'fa-bars'} h6 mb-0 mr-1 px-1 text-primary`}></i><b>
                            {props.showTableOfContents ? 'Hide Top Level Topics' : 'View Top Level Topics'}
                        </b>
                    </button>
                }
            />
        </>
    );
}