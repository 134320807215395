import { compare } from '@lcms/helpers';
import endpoints from './api-endpoint-definition.json';
import { SuggestedResource } from './../../_models/taxonomy/suggested-resource';
import { ApiCall, ParameterTypes } from '@lcms/api-definition';

export type TopicsResponse = SuggestedResource[] | null;

function addChildren(resource: SuggestedResource) {
    resource.children?.sort(compare(x => x.title));
    resource.children.map(child => {
        child.parent = resource;
        addChildren(child);
        return child;
    });
}

const definition = new ApiCall<TopicsResponse>().post('getTopics', {
    route: endpoints.topics,
    params: {
        entity: {
            type: ParameterTypes.String
        },
        role: {
            type: ParameterTypes.String
        },
        term: {
            type: ParameterTypes.String,
            optional: true
        },
        featured: {
            type: ParameterTypes.StringArray,
            optional: true
        }
    },
    process: topics => {
        for (const topic of topics || []) {
            addChildren(topic);
        }
    }
})
export default definition;

