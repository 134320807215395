import { HeaderProps, SectionHeader } from '../SectionHeader';
import endpoints from '../../../_services/api/api-endpoint-definition.json';

export function Header(props: HeaderProps) {

    return (
        <>
            <SectionHeader
                {...props}
                filterProps={{
                    placeholder: 'Narrow trending topics...'
                }}
                pageTitle={
                    <span>Trending Topics</span>
                }
                additionalButtons={<>
                    <a href={endpoints.reportBlockedTopicFilters}
                        className="btn btn-primary btn-sm btn-normal py-2 px-3"
                        target="_blank"
                        rel="noreferrer"
                    ><i className="fad fa-file-download mr-2"></i>Download Blocked Report</a>
                </>}
            />
        </>
    );
}