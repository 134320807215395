import React from 'react';
import { useHistory } from 'react-router-dom';
import { SearchLevels } from '../../../_models/search/search-levels';
import search from '../../../_services/search';
import { useSearchParams } from '../../../_services/search-params';
import { EmptyResultSetContent, EmptyResultSetContentProps } from '../../EmptyResultSet/EmptyResultSetContent';

export function EmptyResultSet(props: EmptyResultSetContentProps) {

    const params = useSearchParams();
    const router = useHistory();

    return (
        <>
            <div className="bg-white rounded shadow-sm  shadow-dark pt-2 pb-4 pr-4 pl-4 mb-4 mt-4 suggested-topic no-results col-12">
                <h4 className="text-uppercase title mb-0"><i className="fad fa-empty-set mr-2 mt-2 fa-1x text-warning"></i> No Results Found</h4>
                <hr />
                <div className="d-flex justify-content-start align-items-top flex-wrap">
                    <button aria-label="Search Synod and Partner Websites" className="btn btn-primary reset-search mr-5" type="button"
                        onClick={() => params.term ? search(params.term, SearchLevels.partnerResources, router, []) : undefined}
                    >
                        <i className="fad fa-search mr-2"></i>Search Synod and Partner Websites
                    </button>
                    <EmptyResultSetContent {...props} />
                </div>
            </div>
        </>
    );
}