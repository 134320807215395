import { useCallback, useState } from 'react';
import { SuggestedResource } from '../../../_models/taxonomy/suggested-resource';
import { getAdminSuggestedTopics } from '../../../_services/api-resources/suggested-topics';
import { ErrorLog } from '../../../_services/error-log';
import { useOnComponentMount } from '../../../_services/use-on-component-mount';

type State = 'initial' | 'loading' | 'error';
interface PendingSuggestedTopics {
    state: State;
}
interface LoadedSuggestedTopics {
    state: 'done';
    topics: SuggestedResource[];
    reload: () => void
}
type SuggestedTopicsType = PendingSuggestedTopics | LoadedSuggestedTopics;

export function useSuggestedTopics() {
    const [topics, setTopics] = useState<SuggestedTopicsType>({
        state: 'initial'
    });

    const reload = useCallback(() => {
        setTopics({
            state: 'loading'
        })
        getAdminSuggestedTopics()
            .then(topics => {
                if (topics) {
                    setTopics({
                        state: 'done',
                        topics: topics,
                        reload: reload
                    })
                } else {
                    setTopics({
                        state: 'error'
                    })
                }

            }, error => {
                ErrorLog.log(error);
                setTopics({
                    state: 'error'
                })
            })
    }, [])

    useOnComponentMount(() => {
        reload();
    });

    return topics;
}