import React, { useState } from 'react';
import { Sidebar as PrimeSidebar } from 'primereact/sidebar';
import { SidebarResponsive as SidebarResponsiveBase } from '../Sidebar/SidebarResponsive';
import { SuggestedTopicsFilterControls } from './FilterControls/SuggestedTopicsFilterControls';
import { FeaturedSection } from '../Sidebar/Sections/FeaturedSection';

let optionsClicked = false;

export function SidebarResponsive() {
    
    const [showOptions, setShowOptions] = useState(false);

    return (
        <>
            <PrimeSidebar baseZIndex={999999} showCloseIcon={false} blockScroll={true} visible={showOptions} onHide={() => setShowOptions(false)}>
                <div className="sb-header sticky-top">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="text-uppercase mb-0 pt-1">Suggested Topics</h6>
                        <button aria-label="Close sidebar menu" type="button" className="btn btn-white text-dark border-dark" onClick={() => setShowOptions(false)}> <i className={'mr-2 fas fa-chevron-left'}></i> DONE</button>
                    </div>
                </div>
                <div className="mt-5 py-2 px-3">
                    <div className="mt-1 mb-5">
                        <SuggestedTopicsFilterControls />
                    </div>
                    <FeaturedSection />
                </div>
            </PrimeSidebar>
            <SidebarResponsiveBase
                pageTitle="Suggested Topics"
                showBack={false}
                prependOptions={
                        <li className="nav-item mb-3" >
                            <div className={'rounded-circle text-white cursor-pointer nav-link options-btn' + (!optionsClicked ? ' pulse' : '')} onClick={() => { setShowOptions(true); optionsClicked = true; }}>
                                <span className="nav-link-icon d-block">
                                    <i className="fas fa-sliders-v lg"></i>
                                </span>
                            </div>
                            <small className="pt-2">Options</small>
                        </li>
                }
            />
        </>

    )
}