import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TrendingResourcesPageUrl } from '../../../views/TrendingResources';
import { TrendingSearchesPageUrl } from '../../../views/TrendingSearches';

export function TrendingSection() {

    const location = useLocation();

    const baseClass = 'btn btn-sm btn-block rounded-0 sb-btn ';
    return (
        <div className="mb-5">
            <h3 className="sb-bold-title"><i className="fad fa-chart-line mr-2"></i>Trending</h3>
            <Link to={TrendingSearchesPageUrl} className={baseClass + (location.pathname.toLowerCase() === '/trending-searches' ? 'btn-outline-warning current' : 'btn-secondary')}>
                Trending Searches
            </Link>
            <Link to={TrendingResourcesPageUrl} className={baseClass + (location.pathname.toLowerCase() === '/trending-resources' ? 'btn-outline-warning current' : 'btn-secondary')}>
                Trending Resources
            </Link>
        </div >
    );
}