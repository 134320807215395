import { formModel, SuggestedResourceFormModel } from '../../../../_models/taxonomy/suggested-resource';
import { Tooltip } from 'primereact/tooltip';
import { EditButton } from './Controls/EditButton';
import { AddChildButton } from './Controls/AddChildButton';
import { NodeData } from './SuggestedTopicsTable';
import { RestoreButton } from './Controls/RestoreButton';

export function Title(showModal: (resource: SuggestedResourceFormModel) => void, onRestore: () => void) {
    return (props: { data: NodeData }) =>
        props.data.active ?
            <>
                <div className="sm-visible controls-div">
                    <Tooltip target="[data-pr-tooltip]" autoZIndex={false} className="force-tt" />
                    <EditButton resource={props.data.resource} showModal={showModal} />
                    <AddChildButton resource={props.data.resource} showModal={showModal} />
                </div>
                <button type="button"
                    onClick={() => showModal(formModel(props.data.resource, true))}
                    className='btn btn-sm btn-link m-0 p-0 btn-normal text-left'>
                    {props.data.displayTitle}
                    {props.data.resource.parent ? null : <><br /><small>{props.data.resource.entity?.name || 'All'} / {props.data.resource.role?.name || 'All'}</small></>}
                </button>
                {props.data.featured ?
                    <button type="button" className='btn btn-sm btn-link sm-visible'><i className="fad fa-sparkles mr-2"></i> {props.data.featured}</button>
                    : null}
            </> :
            <>
                <div className="sm-visible controls-div">
                    <RestoreButton id={props.data.resource.id} onRestore={onRestore} />
                </div>
                <span className="topic-cell">
                    <span className="line-through topic-admin-title">
                        {props.data.displayTitle}
                    </span>
                    {props.data.resource.parent ? null : <><br /><small className="line-through">{props.data.resource.entity?.name || 'All'} / {props.data.resource.role?.name || 'All'}</small></>}
                </span>
                <span className="md-visible ml-2">
                    <i className="fad fa-sparkles mr-2"></i> <span className="line-through">{props.data.featured}</span>
                </span>
            </>
        ;
}
